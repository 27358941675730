.rtc{
    background-color: var(--main-background-color);;
    height: 112px;
    min-width: 318px;
    border-radius: 16px;
    box-shadow: 0px 0px 2px 0px #1154AC;
    display: flex;
    flex-direction: column;
    .rtc_upper{
       border-bottom: 1px solid #EAF0F8;
    }
    .rtc_lower{
        color: #A4A8AE;
    }
    div{
        flex: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 16px;
        font-size: 14px;
        font-weight: 500;
    }   
}
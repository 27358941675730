.button_wrapper {
  display: flex;
  background-color: var(--primary-color);
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0vh;
  width: calc(100% - 128px);
  background: linear-gradient(180deg, var(--primary-color) 0%, white 40%)
    no-repeat;
  height: 8em;

  @media (max-width: 746px) {
    width: calc(100% - 94px);
  }

  @media (max-width: 746px) {
    width: calc(100% - 20px);
  }

  button {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    align-items: flex-start;
    gap: 10px;
    color: #fff !important;
    border-radius: 16px;
    background: #36d66b;
    box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
  }
}
.container_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

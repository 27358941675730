.transactionhistory {
  padding: 16px;
  background-color: var(--main-background-color);
  border-radius: 16px;

  .transactionhistory_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
  }
  table {
    width: 100%;
    height: 100%;
    // margin-top: 1.5rem;
    border-collapse: collapse;
    color: black;

    th {
      color: #909195;
      font-weight: 500;
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;

      span {
        padding: 0.5em 1em;
        border-radius: 4px;
      }
    }

    td {
      font-weight: 700;
      border-bottom: 1px solid #eaf0f8;
    }
    .column_data {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .progress_tag {
    background-color: #fdeeb4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 500;
    color: #b18e0a;
    border-radius: 4px;
  }

  .column_one_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .sno_wrapper {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4cb944;
      color: #fff;
    }
  }
  .column_header {
    display: flex;
    align-items: center;
  }
  .pagination {
    display: flex;
    justify-content: center;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }

    .button-active {
      color: black;
    }

    .button-inactive {
      color: #909195;
    }

    button:disabled {
      color: #909195;
      cursor: default;
    }
  }

  .status-pending {
    color: #b18e0a;
    background: #fdeeb4;
    padding: 4px 10px;
  }

  .status-error {
    color: #9e1d26;
    background: #f5bdc0;
    padding: 4px 10px;
  }
  .status-declined {
    color: #ffffff;
    background: #c27175;
    padding: 4px 10px;
  }
  .status-filtered {
    color: #635c00;
    background: #fed7b4;
    padding: 4px 10px;
  }

  .status-successful {
    color: #4cb944;
    background: #c8e9c5;
    padding: 4px 10px;
  }
}

.verification{
    padding: 22px 20px;
    .grid_container {
        display: grid;
        grid-template-columns: 30% 70%;
        gap: 20px;
        box-sizing: border-box;
        padding-right: 20px;
        .col2{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
      }
      
}

@media (max-width: 1024px) {
    .verification{
    .grid_container {
      grid-template-columns: 1fr  !important;
      padding-right: 0;
    }
}
  
}

.preview{
   
    h4{
        margin: 22px 0;
    }
}
.preview_wrapper{
    background-color: var(--main-background-color);
    border-radius: 16px;
    height: fit-content;
    padding-bottom: 10px;
    &:hover{
        box-shadow: 0px 0px 2px 0px #1154AC;

    }
    .preview_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        border-bottom: 1px solid var(--primary-color);
        .preview_header_left{
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .preview_email{
        padding: 16px;
    }
    .preview_email_container{
        background-color: var(--primary-color);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        .preview_email_header{
            border-bottom: 1px solid var(--main-background-color);
            padding: 16px;
            span{
                color: #000;
                font-weight: 700;
            }
        }
        .preview_email_message{
            padding: 16px;
        }
    }
 
}

.save_btn {
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    border-radius: 16px;
    padding: 20px 40px;
    box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.40);
    background-color: var(--secondary-color);
    color: white;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}
    
      .preview_competition {
        height: 100%;
        width: 100%;
        background: url('../../../assets/images/card-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        margin: 20px 0;
      
      .main_img {
        position: relative;
        top: 0rem;
        left: 0rem;
        max-width: 230px;
      }
      .heading {
        position: relative;
        top: -1rem;
        font-size: 20px;
        color: white;
        text-wrap: wrap;
        max-width: 202px;
        left: 2rem;
        line-height: 1.3;
       
       
      }
  
    
    }

    .ent_btn{
        position: relative;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 100%;
        height: 48px;
        margin: 10px auto;
        justify-content: center;
        align-items: center;
        color: var(--secondary-color);
        border-radius: 8px;
        background: var(--foundation-neutral-white-l-50-white, #fff);
      }
.invoicedetails {
    width: 100%;
    // height: 425px;
    background-color: var(--main-background-color);
    border-radius: 16px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    @media (max-width: 600px) {
      height: fit-content;
    }
  
    .invoicedetails_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .add_address_btn {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  
  .address_container {
    border: 1px solid #eaf0f8;
    border-radius: 8px;
    min-height: 157px;
  
    input[type='radio'] {
      height: 20px;
      width: 20px;
      appearance: none;
      border-radius: 50%;
      outline: none;
      transition: border-color 0.3s;
      background-color: var(--main-background-color);
      border: 4px solid #eee;
  
      &:checked {
        background-color: #fff;
        border: 4px solid #36d66b;
      }
    }
  
    .address_container_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eaf0f8;
      padding: 20px;
  
      .address_container_header_left {
        display: flex;
        align-items: center;
        gap: 20px;
  
        p {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  
    .address_container_details {
      display: flex;
      justify-content: space-between;
      align-items: start;
      width: 560px;
      padding-left: 58px;
      padding-top: 23px;
      padding-bottom: 20px;
      box-sizing: border-box;
  
      @media (max-width: 600px) {
        display: block;
        width: 100%;
        padding-left: 0;
        align-items: center;
        padding: 10px;
      }
  
      p {
        color: #0e0f19;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  
  .billing_model {
    .modal_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10000;
    }
  
    .modal_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
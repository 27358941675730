.account_details_upper {
  justify-content: space-between !important;
  width: auto !important;
  display: flex ;
  &_left {
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: center;
    // padding-top: 20px;
    position: relative;
    padding: 10px 20px 0 20px;
    
  }
  
  .save-btn {
    color: white;
    align-self: center;
    /* height: 75%; */
    background: var(--secondary-color);
    width: 160px;
    border-radius: 24px;
    height: 60px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
   

  .account_details_upper_content {
    h4 {
      font-size: 20px;
      font-weight: 700;
      font-family: "DM Sans", sans-serif;

    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 36px;
      padding-left: 2px;
      color: #3E3F47;
    }

  }


}

.account_img_wrapper img {
  width: 80px;
}

.account_edit_img_wrapper {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  img{
    scale: 1.2; 
  }
}


.account_details_lower {
  padding: 10px 20px;
   
  label{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #8B8E93;
  }
  input:focus{
    border: 1px solid var(--secondary-color);
}

  .account_input_container_upper , .account_input_container_lower{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .account_input_container_lower{
    margin-top: 20px;
  }
  .account_details_input {
  
    width: 100%;
    height: 48px;
    padding: 20px;
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid var(--primary-color);
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
  }
  }  


hr {
  border: 0.5px solid var(--primary-color);
}

.account_input{
  width: 100%;
}

.input_error{
  border: 1px solid red !important;
  box-shadow: none;
}

@media (max-width: 768px) {
 .account_input_container_upper, .account_input_container_lower{
   flex-wrap: wrap ;
  }

  }
 
  
@media (max-width: 500px) {
  .account_details_upper{
    width: 80%;
  }
   
   }
 
 
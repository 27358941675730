.stagestats{
    background-color: var(--background-color);
    min-height: 365px;
    box-sizing: content-box;
    @media (max-width: 500px) {
       min-height: 410px;
    }

    .chart_wrapper{
        max-height: 350px;
        padding-bottom: 20px;
    }
    .chart_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
        >div{
            display: flex;
            gap: 20px;
            margin:  0px;
            flex-wrap: nowrap;
        }
    }
}
.stagestats_wrapper{
    margin: 16px;
}
.chart_title{
    font-size: 18px;
    font-weight: 500;
    padding-left: 8px;
}

body {
  // margin: 1.5em;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: var(--background-color);
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

button {
  padding: 1em 0;
  border: none;
  outline: none;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background-color: var(--secondary-color);
  cursor: pointer;
}

ul {
  list-style: none;
  padding-left: 0;
  cursor: default;
}

.input_date {
  background: transparent;
}

/* Hide Arrows for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html * {
  &::-webkit-scrollbar {
    width: 2px;
    padding-left: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #36d66b;
    border-radius: 50px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ebebeb91;
    border-radius: 50px;
  }
}
/* WebKit (Chrome, Safari, Opera) */
*::-webkit-scrollbar {
  width: 2px;
  padding-left: 2px;
  height: 2px;
}

*::-webkit-scrollbar-thumb {
  background-color: #36d66b;
  border-radius: 50px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #ebebeb91;
  border-radius: 50px;
}

/* Firefox */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: #36d66b #ebebeb91;
// }

*::-moz-scrollbar {
  width: 2px;
  padding-left: 2px;
  height: 2px;
}

*::-moz-scrollbar-thumb {
  background-color: #36d66b;
  border-radius: 50px;
  height: 10px;
}

*::-moz-scrollbar-track {
  background-color: #ebebeb91;
  border-radius: 50px;
}

input {
  color: black;
}

.loss {
  color: #f25f5c;
}
.profit {
  color: var(--secondary-color);
}

//Mui tooltip
// .MuiTooltip-tooltip,.MuiTooltip-popper {
//   color: #FFF;
//   background-color: red;
//   background: red;
//   label {
//     background-color: red;
//     background: red;
//   }
// }
// Table
table {
  tr {
    height: auto;
    &:hover {
      background-color: #eaf0f8;
    }
  }
}

// STATUS TAGS
.status_wrapper {
  max-width: 100px;
}
.Active_tag {
  background-color: rgba(200, 233, 197, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #4cb944;
  border-radius: 4px;
  text-transform: capitalize;
  text-wrap: nowrap;
  max-width: 100px;
}

.Inactive_tag {
  background-color: #f5bdc0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #9e1d26;
  border-radius: 4px;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.Expired_tag {
  background-color: #ff000080;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.Draft_tag {
  background-color: #eaf0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #a4a8ae;
  border-radius: 4px;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.Pending_tag {
  background-color: rgb(253, 238, 180);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: rgb(255, 196, 61);
  border-radius: 4px;
  text-transform: capitalize;
  max-width: 100px;
  text-wrap: nowrap;
}
button,
.view_details_btn {
  cursor: pointer;
}

.column_one_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  .sno_wrapper {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4cb944;
    color: #fff;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000 !important ;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff !important;
}

// Sort buttons
.sort_buttons {
  .line {
    height: 37px;
    width: 1px;
    background-color: var(--secondary-color);
  }
  .normalbtn {
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    border-radius: 10px;
    padding: 10px;
    flex: 1 1;
    margin: 0px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    text-wrap: nowrap;
  }
  .active {
    background-color: #36d66b !important;
    color: #fff;
    text-wrap: nowrap;
    border-radius: 10px;
    padding: 10px;
    flex: 1 1;
    margin: 0px;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
}

.desktop_pills {
  display: flex !important;
}

.mobile_dropDowns {
  display: none !important;
}
.mobile_dropDowns > div {
  width: 100% !important;
  // border: 1px solid black;
}
@media (max-width: 900px) {
  .mobile_dropDowns {
    display: flex !important;
    width: 100% !important;
  }

  .desktop_pills {
    display: none !important;
  }
  .line {
    display: none !important;
  }
  .search {
    width: 100% !important;
    .search_bar {
      width: 100% !important;
    }
  }
}
.search {
  width: 300px !important;
  height: 38px !important;
  .search_bar {
    width: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .rs-picker-daterange-panel {
    // min-width: 100vw !important;
    box-sizing: border-box;
    .rs-picker-daterange-calendar-group {
      flex-direction: column !important;
      height: 100% !important;
      min-width: min-content !important;
      box-sizing: border-box;

      .rs-calendar {
        min-width: min-content !important;
        box-sizing: border-box;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .rs-picker-popup-daterange {
    width: fit-content;
    box-sizing: border-box;
    max-width: 70vw !important;
  }
}

@media only screen and (max-width: 768px) {
  .rs-stack {
    display: block;
    .rs-stack-item {
      width: 70vw;
    }
  }
  .rs-picker-toolbar.rs-stack {
    flex-direction: column;
  }
}

.pagination_wrapper {
  @media only screen and (max-width: 800px) {
    flex-direction: column !important;
  }
}
// .table_scroll_wrapper {
//   overflow-x: hidden !important;
// }

// @media (max-width: 768px) {
//   .table_scroll {
//     overflow: auto !important;
//   }
// }

.create_template {
    .grid_container {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 20px;
        box-sizing: border-box;


        .col1_input {
            width: 100%;
            height: 48px;
            padding: 20px;
            box-sizing: border-box;

            border-radius: 8px;
            border: 1px solid #EAF0F8;
            margin-top: 12px;
            font-size: 14px;
            font-weight: 500;
        }

        .textarea_wrapper {
            width: 100%;
            position: relative;
            height: 300px;
            border: 1px solid var(--primary-color);
            border-radius: 8px;
        }

  

        .col1 {
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: fit-content;

            &_header {
                font-size: 16px;
                font-weight: 700;
                color: var(--light-gray-color);
                margin: 20px 0;
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    transform: rotate(90deg);
                }
            }

            &_lower_select_container {
                padding-top: 20px;

                p {
                    font-size: 14px;
                    font-weight: 500;

                }
            }
        }

        .col1_wrapper {
            border-radius: 16px;
            padding: 16px;
            background-color: var(--main-background-color);
        }

        .col1_content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 20px;

            h4 {
                font-size: 20px;
                font-weight: 700;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                color: var(--light-gray-color);
            }
        }
    }



.notification {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1024px) {
        flex-direction: row;
    }

    .radiobox {
        min-height: 200px;
        border: 1px solid var(--primary-color);

        &_header {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--primary-color);
            height: 81px;
            box-sizing: border-box;

            input[type='radio'] {
                height: 20px;
                width: 20px;
                appearance: none;
                border-radius: 50%;
                outline: none;
                transition: border-color 0.3s;
                background-color: var(--background-color);
                border: 4px solid #eee;

                &:checked {
                    background-color: var(--background-color);
                    border: 4px solid var(--secondary-color);
                }
            }

            p {
                font-size: 18px;
                font-weight: 700;
            }

            div {
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }

        &_details {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
                font-size: 16px;
                font-weight: 500;
            }

            div {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #368330;
            }
        }
    }
}


}


.ql-editor {
    min-height: 248px;
    border-bottom: 1px solid var(--primary-color);
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
}



.ql-toolbar.ql-snow + .ql-container.ql-snow{
    border: 1px solid var(--primary-color);
   
}
.ql-toolbar.ql-snow {
    position: absolute;
    bottom: 0;
    border: none !important;
    outline: none !important;
    overflow-x: auto; 
    white-space: nowrap; 
    max-width: 100%;
}


  
.all_plans {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 800px) {
  .plan_details_box_wrapper{
    width: 100%;
  }  
  }

}

.bankdetails_modal_overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10000;
    
        .bankdetails_modal_container{
            background-color: var(--main-background-color);
            width: 484px;
            height: fit-content;
            padding:20px;
            border-radius: 16px;
            padding-top: 26px;
            .modal_header{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            p{
                font-size: 14px;
                font-weight: 500;
            }
            .general_info_form , .recipient_info_form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 12px;
            }
            
    .general_info_form_input_container , .recipient_info_form_input_container {
        background-color: var(--main-background-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 56px;
        border: 1px solid #A4A8AE;
        border-radius: 8px;
      }
      
      .input_icon{
        height: 24px;
        width: 24px;
        padding: 0 20px;
    }
      
     
      label{
        color: #A4A8AE;
        font-size: 12px;
        font-weight: 500;
      }
    
        }

        .recipient_info_input , .general_info_input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            gap: 5px;
            border-left: 1px solid #A4A8AE;
            padding: 0 10px;
            background-color: var(--main-background-color);
            input{
                outline: none;
                border: none;
                width: 90%;
                font-size: 16px;
                font-weight: 500;
            }
            .react_select_container {
                outline: none;
                border: none;
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                .css-1fdsijx-ValueContainer{
                    padding: 0 !important;
                  }
            }
         
          } 
      }

      .form_container{
        background-color: var(--primary-color);
        padding: 20px;
        border-radius: 16px;

        .account_type{
            padding: 20px 0 0 0;
            p{
                font-size: 12px;
                font-weight: 500;
                color: #A4A8AE;
            }
            .radio_container{
                padding: 10px 20px;
                input[type="radio"] {
                    height: 20px;
                    width: 20px;  
                    appearance: none;
                    border-radius: 50%;
                    outline: none;
                    transition: border-color 0.3s;
                    background-color: var(--main-background-color);;
                    border: 4px solid #A4A8AE;
                    &:checked {
                        background-color: var(--main-background-color);;
                        border: 4px solid #36d66b;
                    }
                }
                label{
                    color: #0A3266;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "DM Sans", sans-serif;
                }
                div{
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
        }
    }
    .btn_container{
        padding: 10px 0;
    .save_info_btn{
        width: 100%;
        border-radius: 62px;
    }
    }
      
.recipient_info_form_container{
    padding: 20px 0;
}
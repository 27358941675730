.accountcard-container {
  margin: 0 0.25em;
  padding: 1.25em;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  height: 300px !important;
  background: var(--background-color);
  border-radius: 16px;
}

.accountcard-subcontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--white-color);
  height: 100%;
  padding: 1.25em;
  box-sizing: border-box;
  background: url("../../../../assets/images/card-background.jpg");
  background-size: cover;
  border-radius: 24px;
}

h5 {
  margin: 0.5em 0;
  font-size: 14px;
  font-weight: 500;
}

.accountcard-subcontainer_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .accountcard-number {
    font-family: "Space Grotesk", sans-serif;
    font-size: 40px;
    font-weight: 700;
  }

  img {
    width: 52px;
    height: 52px;
    cursor: pointer;
  }
}

.accountcard-container_balance {
  align-content: flex-end;

  p {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .accountcard-container {
    max-width: 100%;
  }
  .accountcard-number {
    font-size: 20px !important;
  }
}

.accountsettings_upper {
  .accountsettings_input_container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 20px 0 20px;

    label {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      color: #8B8E93;
    }

    input:focus {
      border: 1px solid #36d66b;
    }

    .account_settings_input {
      width: 100%;
      height: 48px;
      padding: 20px;
      box-sizing: border-box;

      border-radius: 8px;
      border: 1px solid #EAF0F8;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
    }

  }
}

.accountsettings_lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .accountsettings_lower_left {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #0E0F19;
    font-size: 14px;
    font-weight: 500;
  }

  .accountsettings_lower_right {
    p {
      color: #DF2935;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}




//togglebutton

.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;

  .slider {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 5px;
    transition: transform 0.3s ease;
  }
}

.toggle-switch.checked {
  background-color: #36d66b;

  .slider {
    transform: translateX(20px);
  }
}


@media (max-width: 768px) {

  .accountsettings_input_container{
    flex-wrap: wrap;
  }
}
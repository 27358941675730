.generalinfo{
    color: #000000;
    border-radius: 24px;
    padding: 22px 20px; 
    margin-top: 1rem;
    font-family: "DM Sans", sans-serif;
    input{
        outline: none;
    }
      .generalinfo_top{
        height: fit-content;
        background-color: var(--main-background-color);;
        border-radius: 16px;
        margin: 20px 0 ;
        padding-bottom: 20px;
      }
      .generalinfo_middle{
        height: fit-content;
        background-color: var(--main-background-color);;
        border-radius: 16px;
        margin: 20px 0;
      }
      .generalinfo_bottom{
        height: fit-content;
        background-color: var(--main-background-color);;
        border-radius: 16px;
        margin: 20px 0;
      }
}

.label_black{
    color: #3E3F47 !important;
}

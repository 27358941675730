.revenuegrowth-container {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 7;
  // height: 596px;
  background: var(--background-color);
  border-radius: 16px;
  // border: 1px solid #242527;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .revenuegrowth-subcontainer {
    display: flex;
    flex-direction: column;

    .revenuegrowth-innercontainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      gap: 5px;
      flex-wrap: wrap;
      .status_wrapper {
        margin-left: auto;
      }
      // border-top: 1px solid var(--primary-color);
      h4 {
        font-size: 14px;
        font-weight: 700;
        font-family: "DM Sans", sans-serif;
      }
      div {
        display: flex;
        flex-direction: row;

        p {
          font-size: 14px;
          font-weight: 500;
        }

        div {
          display: flex;
          flex-direction: column;
        }

        img {
          margin-right: 1em;
        }
      }
    }

    .revenuegrowth-header {
      padding: 1em 1em 0 1em;

      .tabination-container.variant_3 {
        .tabination_subcontainer_wrapper {
          // background-color: red !important;
          // border: 1px solid;
          ul {
            display: flex !important;
            // flex-direction: column;
            // border: 1px solid red !important;
          }
        }
      }
    }

    .revenuegrowth-trade {
      display: grid;
      grid-template-columns: repeat(2, auto);
      background: var(--primary-bg-color);

      img {
        grid-row: 1 / span 2;
        margin-right: 1em;
      }

      div {
        flex-direction: column;
      }

      .revenuegrowth-container_status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 1em 0 0 0;

        p {
          color: var(--light-gray-color);
          font-size: 12px;
        }
      }
    }

    .revenuegrowth-showall {
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 0;

      p {
        color: var(--light-gray-color);
        cursor: pointer;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    // background-color: blue;
    border-radius: 50%;
  }
  // .status-passed {
  //   color: var(--green-success-color);
  //   background-color: var(--green-success-bg-color);
  // }
  // .status-progress {
  //   color: var(--yellow-progress-color);
  //   background-color: var(--yellow-progress-bg-color);
  // }
  // .status-error {
  //   color: var(--red-error-color);
  //   background-color: var(--red-error-bg-color);
  // }
}

.leaderboard {
  padding: 22px 20px;
  background: var(--primary-color);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: calc(100vh - 160px);
  .grid_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .leaderboard_winner_wrapper {
    background-color: var(--main-background-color);
    padding: 20px;
    max-height: 274px;
    border-radius: 16px;
    box-sizing: border-box;
    grid-column: span 3;
  }

  .leaderboard_infobox_wrapper {
    height: 274px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .leaderboard_symbol_data {
    background-color: var(--main-background-color);
    max-height: 274px;
    border-radius: 16px;
  }

  .leaderboard_lower {
    background-color: var(--main-background-color);
    border-radius: 16px;
  }

  @media (max-width: 1024px) {
    .grid_container {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .leaderboard_winner_wrapper,
    .leaderboard_news_wrapper {
      max-height: none;
      height: auto;
    }
    .leaderboard_symbol_data {
      grid-column: span 2;
    }
  }

  @media (max-width: 768px) {
    .grid_container {
      grid-template-columns: 1fr;
      gap: 20px;
    }
    .leaderboard_symbol_data {
      grid-column: span 1;
    }
  }
}

@media (max-width: 1024px) {
  td,
  th {
    font-size: 12px !important;
  }
}

@media (max-width: 500px) {
  td,
  th {
    font-size: 12px !important;
  }
  .table_wrapper {
    // max-width: 300px;
    overflow-x: auto;
  }
  .leaderboard_table {
    margin-top: 20px;
  }
}


.increment_decrement_input_container {

    width: 100%;
    height: 48px;
    padding: 20px;
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid #EAF0F8;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    display: flex;
    align-items: center;
    .icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 10px;
      }
        
  input{
    outline: none;
    border: none;
  }
}

  
  
  
  .value_display {
    flex: 1;
    text-align: center;
    font-size: 18px;
  }

.create_ad {
  padding: 20px 20px;
  background: var(--primary-color);
  border-radius: 24px;
  @media (max-width: 400px) {
    .col2{
      max-width: 300px;
    }
  }
  input {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .grid_container {
    display: grid;
    grid-template-columns: 65% 35%;
    gap: 20px;
    padding-right: 20px;
      @media (max-width: 1200px) {
        grid-template-columns: 50% 50%;
      }
      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        padding-right: 0;
      }
  }

  .preview_wrapper:hover{
     box-shadow: none !important;

  }

  .preview_wrapper{
    padding: 12px;
    @media (max-width: 400px) {
      width: 100%; 
      margin: 0 -10px;
    }
  }
  .col2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_top {
      .ad_details_box_wrapper {
        margin: 14px 0px;
      }
    }
    &_bottom {
      margin-bottom: 30px;
      button {
        display: flex;
        width: 100%;
        height: 67px;
        margin-top: 20px;
        padding: 20px 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 16px;
        background: #36d66b;
        box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
      }
    }
  }

  .col1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_top {
      align-items: center;
      gap: 20px;
      width: 100%;
    }

    .ad_input_wrapper {
      display: flex;
      padding: 20px;
      border-radius: 16px;
      font-size: 14px;
      // height: 547px;
      flex-direction: column;
      margin: 14px 0px;

      background-color: var(--main-background-color);

      .event_selector {
        input:focus {
          border: none !important;
        }
      }

      label {
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 10px;
      }
      button {
        width: 100%;
        height: 48px;

        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #eaf0f8;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 500;
        color: inherit;
        background-color: var(--main-background-color);
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 20px;
          width: 100%;
        }
      }
      input,
      textarea {
        width: 100%;
        height: 48px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #eaf0f8;
        margin-top: 0px;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: black;
        background-color: var(--main-background-color);
        resize: none;
        &:focus {
          outline: var(--foundation-blue-blue-150-main);
        }
      }
      textarea {
        height: 100%;
      }

      .date_wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .ad_scheduler_wrapper {
        position: relative;
        label {
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 10px;
        }
        input {
          padding-left: 60px;
        }
        img {
          position: absolute;
          top: 15px;
          left: 20px;
        }
      }
    }

    &_bottom {
      display: flex;
      align-items: center;
      gap: 20px;
      .image_upload_wrapper {
        width: 100%;
      }
      .image_selector_wrapper {
        display: flex;
        padding: 20px;
        border-radius: 16px;
        font-size: 14px;
        max-height: 200px;
        gap: 20px;
        margin: 14px 0px;
        /* margin-bottom: 20px; */
        background-color: var(--main-background-color);

        @media (max-width: 500px) {
          flex-wrap: wrap;
          max-height: fit-content;
        }

        .image_input_wrapper {
          input {
            display: none;
          }

          h4 {
            span {
              color: #36d66b;
              text-decoration: underline;
              text-underline-offset: 3px;
            }
          }

          font-size: 16px;
          text-align: center;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 2px dashed #a4a8ae;
          cursor: pointer;
          border-radius: 10px;
        }

        .previous_images {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          gap: 17px;
          width: 300px;
          max-height: 170px;
          flex-wrap: wrap;

          @media (max-width: 768px) {
           width:100%;
           max-height: fit-content;
          }
          
          @media (max-width: 500px) {
            flex-direction: row;
           }

          :first-child {
            height: 100%;
            max-width: 190px;
            border-radius: 10px;
            object-fit: cover;
            @media (max-width: 500px) {
              height: 50px;
              width: 50px;
               }
          }

          :nth-child(2) {
            height: 40%;
            @media (max-width: 500px) {
            height: 50px;
            width: 50px;
             }
   
          }

          :nth-child(3) {
            height: 50%;
            @media (max-width: 500px) {
              height: 50px;
              width: 50px;
               }
          }
        }
      }
    }

    .winners_wrapper {
      width: 100%;
    }

    .winners_input_wrapper {
      display: flex;
      padding: 20px;
      border-radius: 16px;
      font-size: 14px;
      flex-direction: column;
      margin: 14px 0px;

      background-color: var(--main-background-color);

      .prize_details_wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input_wrapper {
          input {
            width: inherit;
            padding: 20px;
            box-sizing: none;
            border-radius: 0px;
            border-top: 1px solid #eaf0f8;
            border-bottom: 1px solid #eaf0f8;
            border-left: none;
            border-right: none;
          }
          .prize_header1 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              color: #4cb944;
            }
          }
          .prize_header2 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              color: #36d66b;
            }
          }
          .prize_header3 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              color: #f75c03;
            }
          }
          .prize_header4 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              color: #f9c80e;
            }
          }
        }

        img {
          margin-right: 12px;
          height: 24px;
          width: 24px;
        }
        .input_wrapper {
          height: 48px;
          align-items: center;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-size: 14px;
          font-weight: 500;
          color: inherit;
          background-color: var(--main-background-color);
          display: flex;
          width: 100%;
          position: relative;
          gap: 10px;
        }
      }

      input {
        width: 100%;
        height: 48px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #eaf0f8;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 500;
        color: inherit;
        background-color: var(--main-background-color);
      }

      label {
        margin: 10px 0px;
        padding: 50p;
        text-wrap: nowrap;
        font-weight: 700;
      }
    }
  }
}

.input_date{
  outline: none;
  border: none;
  height: 40px;
}
.email_table {
  border-radius: 16px;
  background-color: var(--main-background-color);

  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    @media (max-width: 768px) {
      max-width: 500px;
    }
  }

  .table_wrapper {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
  }

  .table_header {
    display: flex;
    align-items: center;
    gap: 100px;
    width: 100%;
    margin: 4px 2px;
    @media (max-width: 1100px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }

    .search {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      max-width: 500px;

      @media (max-width: 768px) {
        gap: 10px;
        width: 90%;
      }
    }

    .search_bar {
      display: flex;
      align-items: center;
      height: 40px;
      background-color: #eaf0f8;
      border-radius: 63px;
      width: 100%;

      .search_input {
        flex: 1;
        height: 90%;
        border: none;
        outline: none;
        padding-left: 8px;
        border-radius: 63px;
        font-size: 14px;
        background-color: #eaf0f8;
        &::placeholder {
          color: #a4a8ae;
        }

        &:focus {
          outline: none !important;
          border: none !important;
        }
      }

      .search_icon {
        margin-left: 10px;
      }
    }

    .filter_buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 500px;
      gap: 10px;

      @media (max-width: 1400px) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      button {
        background-color: #eaf0f8;
        padding: 10px 20px;
        border-radius: 10px;
        color: #a4a8ae;
      }

      .active_btn {
        background-color: #36d66b;
        color: white;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  table {
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;

    th {
      color: #909195;
      font-weight: 500;
      border-bottom: 1px solid #eaf0f8;
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
    }

    td {
      font-weight: 700;
      border-bottom: 1px solid #eaf0f8;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }

      .status_wrapper {
        display: flex;
        gap: 20px;
      }
    }

    .column_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .code {
    border-radius: 4px;
    background: #eaf0f8;
    display: inline-flex;

    padding: 8px 16px;
    justify-content: center;
    align-items: center;
  }

  .created {
    background: rgba(183, 213, 251, 0.4);
    color: #36d66b;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0 10px;
    border-radius: 30px;
  }

  @media (max-width: 1024px) {
    td,
    th {
      font-size: 12px !important;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }
}

.Success_tag {
  background-color: rgba(200, 233, 197, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #4cb944;
  border-radius: 30px;
  text-transform: capitalize;
}

.Error_tag {
  background-color: #f5bdc0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #9e1d26;
  border-radius: 30px;
  text-transform: capitalize;
}

.Email_tag {
  background-color: #c5c6e980;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #5961e1;
  border-radius: 30px;
  text-transform: capitalize;
}

.Draft_tag {
  background-color: #eaf0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #a4a8ae;
  border-radius: 4px;
  text-transform: capitalize;
}

.Notification_tag {
  background-color: #fdeeb4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #b18e0a;
  border-radius: 30px;
  text-transform: capitalize;
}

.email_wrapper {
  .create_btn {
    margin-top: -50px;
  }
  & ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  // height: 80%;
  .email {
    height: 72vh;
    overflow: auto;
  }
}

.sidebar {
  // margin: 0 1.5em;
  height: 80vh;
  position: fixed;
  top: 5rem;
  overflow-y: scroll;

  // z-index: 99;
  .icons_list {
    list-style: none;
    margin: 2em 0;
    padding: 0.5em 0;
    // padding-bottom: 100px;
    .padding_apply {
      padding: 10px;
    }

    li {
      margin: 0.5em 0.2em;
      cursor: pointer;
      border-radius: 12px;
      width: 92%;
    }
    .icon {
      &.active {
        background: rgb(232 237 244 / 88%);
        opacity: 1 !important;
      }
      img {
        scale: 0.8;
      }
      a {
        position: relative;
        display: flex;
        align-items: center;
        // gap: 8px;
        font-size: 12px;
        font-weight: 500;
        width: min-content;
        min-width: 150px;
        text-wrap: nowrap;
        border-radius: 16px;
        &.active {
          background: #36d66b;
          color: #fff;
          opacity: 1 !important;
        }
        span {
          // height: 40px;
          // width: 40px;
          // top: -110%;
        }
      }
    }
  }

  span {
    position: absolute;
    top: 77%;
    left: 0.5rem;
    // display: inline-block;
    // margin: 12em 0.5em 0 0.5em;

    img {
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
  }
}

.sidemenu_icon {
  position: relative;
}

.sidemenu {
  width: 174px !important;
  height: 103px !important;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 6px#b0c6e3;
  border: 1px solid #ccc;
  padding: 10px !important;
  z-index: 9999;
  position: absolute;
  left: 5rem;
  margin-top: -70px;

  .sidemenu_list_container {
    font-size: 14px;
    font-weight: 500;

    h4 {
      color: #909195;
    }

    .sidemenu_list {
      padding-left: 20px;

      h4 {
        color: #000;
        padding-top: 20px;

        &.active {
          color: #36d66b;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 30%;
    right: 100%;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff;
  }
}

@media (max-width: 600px) {
  .sidebar {
    display: none;
  }

  .mobile-menu {
    display: flex !important;
  }
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.menu_box {
  background: var(--main-background-color);
  width: 90%;
  height: 90%;
  border-radius: 16px;
  padding: 20px 0;
  overflow-x: auto;
}

.menu_list {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  list-style: none;
  padding: 20px 0 10px 0;
  background: var(--main-background-color);
  border-radius: 24px;
  box-shadow: 0px 0px 16px 0px var(--primary-color);
  max-width: 80%;
  margin: 40px auto;
}

.menu_list li {
  font-size: 18px;
}

.close_button {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  color: #909195;
}

.sidebar_list {
  flex: 1;
  overflow-y: auto;
  margin-top: -20px;
}

.menu_item {
  list-style: none;
  padding: 0;
}

.menu_item li {
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  font-family: Space Grotesk;
  border-bottom: 1px solid var(--primary-color);
  padding: 20px 0 20px 40px;
}

.submenu {
  list-style: none;
  padding: 0px;

  max-height: 0px; /* Change height to max-height for smoother transition */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; /* Adjust transition duration */

  &.show {
    max-height: 500px; /* Set a maximum height for the submenu */
    transition: max-height 0.5s ease-in-out; /* Use the same duration as above */
    margin-bottom: 5px;
  }
}

.nested-menu {
  position: relative;
  padding-left: 10px;
}

.nested-menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  border-left: 5px solid transparent;
  border-image: url("../../assets/icons/border-left.svg") 1 stretch;
}

.submenu li {
  font-size: 12px;
  font-weight: 500;

  color: #636363;
  text-align: left;
  margin: 0.7em 0.2em !important;
  margin-left: 10px !important;
  // margin-left: auto !important;
  cursor: pointer;
  padding: 0px !important;
  border-bottom: none;
  &.active {
    color: #36d66b;
    opacity: 1;
  }
}

.mobile_bell_dropdown {
  // height: 450px;
  background-color: #fff;
  width: 90%;
  font-family: "DM Sans", sans-serif;
  color: #0e0f19;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .date,
  .time {
    font-size: 12px;
  }

  .dropdown_header {
    padding: 1rem 1.5rem;
    width: 90%;
  }

  .notifications_tabs {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid var(--primary-color);
    margin-left: -20px;
    height: 60px;

    div {
      color: #a4a8ae;
      display: inline-block;
      height: 40px;
      padding: 0 2px;
      margin-left: 1.5rem;
    }

    .active {
      font-weight: 700;
      color: var(--text-color);
      border-bottom: 2px solid #36d66b;
    }
  }

  .notifications_wrapper {
    padding: 1.5rem;
  }

  .notification {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 1rem 0;

    .notification_icon {
      margin-top: 6px;
    }

    .notification_content_upper {
      padding-bottom: 10px;
      max-width: 80%;

      span {
        font-weight: 800;
      }
    }

    .notification_content_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 90%;
      color: #a4a8ae;
    }
  }

  .dropdown_footer {
    // position: absolute;
    //  bottom: 0;
    padding: 0 1.25rem 1.25rem 1.25rem;
    width: 90%;

    .dropdown_footer_bell {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dropdown_footer_left {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 12px;
        font-weight: 700;
        color: #36d66b;
      }

      .dropdown_footer_right {
        .view_all_btn {
          height: 28px;
          padding: 16px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

.mobile_profile_dropdown {
  // height: 450px;
  background-color: #fff;
  font-family: "DM Sans", sans-serif;
  color: #0e0f19;
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .info_box_container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 10px;
  }

  .dropdown_profile_content {
    padding: 10px 0;
    width: 100%;
  }

  .profile_details {
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: start;
    width: 100%;
    padding-top: 20px;

    .profile_details_content {
      h4 {
        padding: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
        padding-left: 1em;
        color: #3e3f47;
      }
    }

    .profile_img_wrapper img {
      width: 60px;
    }

    .edit_img_wrapper {
      width: 20px;
      height: 20px;
    }
  }

  .accounts_type_wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;

    ul {
      font-weight: 600;
      font-size: 12px;
      padding-top: 20px;

      :first-child {
        font-weight: 700;
        font-size: 14px;
      }

      li {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        color: #0e0f19;
      }

      .blue_tick_icon {
        margin-left: 10px;
      }
    }
  }

  .dropdown_footer {
    //  position: absolute;
    //  bottom: 0;
    //  padding-bottom: 1.25rem;
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      color: #a4a8ae;
      font-size: 14px;
      font-weight: 500;
    }

    .value {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.text_gray {
  color: #909195;
}

.slide-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.mobile_bell_dropdown.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.sidebar::-webkit-scrollbar {
  display: none;
}


.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  .menu_box {
    padding: 20px;
    // color: white;

    .close_button {
      cursor: pointer;
      margin-bottom: 20px;
    }

    .mobile-icons_list {
      list-style: none;
      padding: 0;

      li {
        margin: 15px 0;
        &.activeMobileMenu {
          background-color:#36d66b !important; // Highlight active tab
          border-radius: 5px; // Optional: round edges

          a {
            font-weight: bold; // Optional: bold text for active tab
          }
        }

        a {
          // color: white;
          text-decoration: none;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
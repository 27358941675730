.withdraw_table {
  padding: 16px !important;
  .search_input {
    height: 98%;
  }
  table {
    th {
      white-space: normal !important;
    }
  }
}
.sort_buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  flex: 1;
  align-items: flex-end;
  .react-select__control {
    height: 100%;
    min-width: 147px;
    border-radius: 10px;
    flex: 1;
  }
  button {
    height: 100% !important;
  }
}
.payoutModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

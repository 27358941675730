.banks{
    height: fit-content;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
}
.banks_container {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    grid-gap: 20px; /* Adjust the gap between grid items as desired */
  }
  
  .radiobox {
   min-height: 200px;
   border: 1px solid #EAF0F8;   
   &_header{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EAF0F8;
    height: 81px;
    box-sizing: border-box;
    input[type="radio"] {
        height: 20px;
        width: 20px;  
        appearance: none;
        border-radius: 50%;
        outline: none;
        transition: border-color 0.3s;
        background-color: var(--main-background-color);;
        border: 4px solid #eee;
        &:checked {
            background-color: var(--main-background-color);;
            border: 4px solid #36d66b;
        }
    }
     p{
        font-size: 18px;
        font-weight: 700;
     }
    div{
        display: flex;
        align-items: center;
        gap: 20px;
    }
   }
   &_details{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        p{
        font-size: 16px;
        font-weight: 500;
        max-width: 85%;
        }
        div{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #368330;
            
        }
   }
}
  
.error_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  
    .error_modal_container {
      background-color: #ffffff;
      width: 300px;
      height: auto;
      border-radius: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      
    }
  
    .error_modal_container_top {
      height: 50%;
      width: 100%;
      background-image: url('../../assets/images/error-modal.png');
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .tick_icon {
        // position: absolute;
        bottom: 0;
        // left: 40%;
        margin-top: 65px;

        display: flex;
    align-items: center;
    justify-content: center;
      }
    }
  
    .error_modal_container_bottom {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      gap: 10px;
      padding: 10px;
      justify-content: flex-end;
  
      p {
        color: var(--light-gray-color);
        font-size: 16px;
        font-weight: 500;
      }
  
      .error_modal_close_btn {
        margin-top: calc(auto - 10px);
        background-color: #DF2935 !important;
        width: 80% !important;
        border-radius: 8px;
      }
    }
  }
  
$header-search-bar-bg-color: var(--primary-color);

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: fixed;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 90px;
  background: var(--background-color);

  .header_left {
    img {
      scale: 0.9;
    }
    padding-left: 0.75em;
    h2 {
      color: var(--text-color);
    }
  }

  // .dark {
  //   color: #fff;
  // }

  // .light {
  //   color: #000;
  // }

  // search bar 
  .search_main {
    background-color: #eaf0f8;
    // height: 400px;
    width: 28.9vw;
    position: relative;
    border-radius: 17px;
    .search-bar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      padding-left: 20px;
      @media (max-width: 1024px) {
        width: auto;
        margin-right: 0px;
      }
      .search_input {
        flex: 1;
        height: 90%;
        border: none;
        outline: none;
        width: 100%;
        padding-left: 8px;
        font-size: 14px;
        border-radius: 66px;
        background: #eaf0f8;
        &::placeholder {
          color: #a4a8ae;
        }
      }
    }
  
    .search_value {
      filter: drop-shadow(0.2rem 1.5rem 0.68rem rgba(0, 0, 0, 0.1));
      border-top: none;
      position: absolute;
      top: 39px;
      width: calc(100%);
      padding: 10px 0;
      left: 0;
      background-color: #eaf0f8;
      z-index: 999;
      border-bottom-left-radius: 17px;
      border-bottom-right-radius: 17px;
      overflow: hidden;
      p {
        padding: 0px 10px 10px 10px;
        font-size: 12px;
        margin: 0;
        color: #a5b5bb;
      }
      .content_list {
        display: flex !important;
        align-items: center;
        gap: 20px;
        padding: 5px 0;
        cursor: pointer;
        &:hover {
          background-color: var(--secondary-color);
          span {
            color: #fff;
          }
        }
        img {
          width: 15px;
          height: 15px;
        }
        span {
          font-size: 18px;
          padding-left: 10px;
          color: var(--secondary-color);

        }
        &:hover {
          background-color: var(--secondary-color);
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.mobile_header {
  display: none;
}

.header_left {
  display: flex;
  align-items: center;
  
  gap: 4em;
  color: var(--text-color);
  color: white;
  img {
    margin-top: 5px;
  }
}

.header_right {
  display: flex;
  cursor: pointer;
  padding-right: 55px;
  position: relative;

  .icons_list {
    display: flex;
    list-style: none;
    gap: 20px;
    cursor: pointer;

    .profile_section {
      margin-left: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.search_bar {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 40px;
  width: 330px;
  background-color: $header-search-bar-bg-color;
  border-radius: 63px;
  // margin-right: 20px;
  box-shadow: 0px 0px 2px 0px #1154ac;
  @media (max-width: 1024px) {
    width: auto;
    margin-right: 0px;
  }
  .search_input {
    flex: 1;
    height: 90%;
    border: none;
    outline: none;
    padding-left: 8px;
    border-radius: 63px;
    font-size: 14px;
    background-color: $header-search-bar-bg-color;

    &::placeholder {
      color: var(--text-color);
    }
  }

  .search_icon {
    margin-left: 10px;
  }
}

.search_bar_mobile {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 40px;
  width: 50px;
  background-color: $header-search-bar-bg-color;
  border-radius: 63px;

  .search_input_mobile {
    flex: 1;
    height: 90%;
    border: none;
    outline: none;
    padding-left: 8px;
    border-radius: 63px;
    font-size: 14px;
    background-color: $header-search-bar-bg-color;

    &::placeholder {
      color: var(--text-color);
    }

    .search_icon_mobile {
      margin-left: 20px;
    }
  }

  .search_icon {
    margin-left: 10px;
  }
}

.dropdown {
  position: absolute;
  // height: 450px;
  transform: translate(0, 10px);
  background-color: #fff;
  border-radius: 8px;
  // box-shadow: 0px 0px 2px #b0c6e3;
  width: auto;
  font-family: "DM Sans", sans-serif;
  color: #0e0f19;

  .dropdown_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    img {
      cursor: pointer;
      scale: 1.3;
    }
  }
}

.dropdown_profile::before {
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 1px solid #b0c6e3;
  border-right: 0px solid #b0c6e3;
  border-bottom: 0px solid #b0c6e3;
  border-left: 1px solid #b0c6e3;
  top: 19px;
  right: 38px;
  margin-left: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: var(--background-color);
}

.dropdown_profile {
  min-width: 430px;
  right: 60px;
  border: 1px solid #b0c6e3;

  .dropdown_upper_wrapper {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .info_box_container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 0px;
  }

  .dropdown_profile_content {
    padding: 10px 0;
    width: 100%;
  }

  .profile_details {
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: start;
    width: 100%;
    padding-top: 20px;

    .profile_details_content {
      h4 {
        padding: 0;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 24px;
        font-family: "DM Sans", sans-serif;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
        padding-left: 0px;
        color: #3e3f47;
      }
    }

    .profile_img_wrapper img {
      width: 60px;
    }

    .edit_img_wrapper {
      width: 30px;
      height: 30px;
    }
  }

  .accounts_type_wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;

    ul {
      font-weight: 600;
      font-size: 12px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-weight: 500;
      :first-child {
        font-weight: 700;
        font-size: 14px;
      }

      li {
        // margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        color: #0e0f19;
      }

      .blue_tick_icon {
        margin-left: 10px;
      }
    }
  }

  .dropdown_footer {
    //  position: absolute;
    //  bottom: 0;
    //  padding-bottom: 1.25rem;
    border-top: 1px solid var(--primary-color);
    padding: 20px 0;
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: auto;
    }

    .title {
      color: #a4a8ae;
      font-size: 14px;
      font-weight: 500;
    }

    .value {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.active_profile {
  border: 1px solid var(--secondary-color);
  border-radius: 100%;
  width: calc(36px + 2px); /* Adjust width to account for border */
  height: calc(36px + 2px); /* Adjust height to account for border */
}
.dropdown_bell::before {
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 1px solid #b0c6e3;
  border-right: 0px solid #b0c6e3;
  border-bottom: 0px solid #b0c6e3;
  border-left: 1px solid #b0c6e3;
  top: 19px;
  right: calc(18.5%);
  margin-left: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: var(--background-color);
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown_bell {
  width: 509px;
  right: 135px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: 1px solid #b0c6e3;

  .dropdown_header {
    padding: 1rem 1.5rem;
    width: 90%;
  }

  .notifications_tabs {
    width: 100%;

    border-bottom: 1px solid var(--primary-color);
    height: 50px;
    margin-left: -20px;

    div {
      color: #a4a8ae;
      display: inline-block;
      height: 40px;
      padding: 0 2px;
      margin-left: 1.5rem;
    }

    .active {
      font-weight: 700;
      color: var(--text-color);
      border-bottom: 2px solid #36d66b;
    }
  }

  .notifications_wrapper {
    padding: 1.5rem;
    max-height: 60vh;
    overflow-y: auto;
  }

  .notification {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 1rem 0;

    .notification_icon {
      margin-top: 6px;
    }

    .notification_content_upper {
      padding-bottom: 10px;
      max-width: 80%;

      span {
        font-weight: 800;
      }
    }
    .date {
      font-size: 12px;
      font-weight: 500;
    }
    .time {
      font-size: 12px;
      font-weight: 500;
    }
    .notification_content_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 90%;
      color: #a4a8ae;
    }
  }

  .dropdown_footer {
    // position: absolute;
    //  bottom: 0;
    padding: 0 1.25rem 1.25rem 1.25rem;
    width: 90%;

    .dropdown_footer_bell {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dropdown_footer_left {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 12px;
        font-weight: 700;
        color: #36d66b;
      }

      .dropdown_footer_right {
        .view_all_btn {
          height: 28px;
          padding: 16px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    display: none;
  }

  .mobile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    
    
    
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100 !important;
    height: 90px;
    background-color: var(--background-color);

    .header_left {
      display: flex;
      align-items: center;
      gap: 8px !important;
      color: var(--text-color);
      font-size: 14px;
      padding-left: 10px;
      img {
        scale: 0.7;
      }
      h2 {
        font-size: 14px;
      }
    }

    .header_right {
      gap: 10px !important;
      padding-right: 10px;
    }
  }
}

.certificate {
  .header_filter {
    padding: 1px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    .sort_buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 10px;
      flex: 1;
      align-items: flex-end;
      .react-select__control {
        height: 100%;
        min-width: 147px;
        border-radius: 10px;
        flex: 1;
      }
      div {
        button {
          order: 1px solid var(--secondary-color);
          color: var(--secondary-color);
          border-radius: 10px;
          padding: 10px;
          flex: 1 1;
          margin: 0px;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: row !important;
      align-items: center !important;
    }

    @media (max-width: 600px) {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
  .table_wrapper {
    .table-head {
      display: none;
      font-size: 14px;
      padding: 0px 0px 16px 0px;
    }
  }

  .table_header {
    .search_bar {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      width: 330px;
      background-color: var(--primary-color);
      border-radius: 63px;
      margin-right: 20px;
      box-shadow: 0px 0px 2px 0px #1154ac;
    }
    .search_input {
      height: 90% !important;
    }
  }
}

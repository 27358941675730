.competition_list {
  color: #000;
  border-radius: 24px;
  background-color: var(--primary-color);
  // padding: 20px;
  // height: 80%;
  min-height: calc(100vh - 240px);
  overflow: auto;

  &_wrapper {
    // a {
    //   width: min-content;
    //   text-wrap: nowrap;
    //   margin-left: auto;
    //   display: flex;
    //   .create_wrapper {
    //     display: flex;
    //     align-items: center;
    //     gap: 10px;
    //     margin-bottom: 10px;
    //     border-radius: 6px;
    //     padding: 6px;
    //     border: 1px solid #FFF;
    //     background: #FFF;
    //     &:hover {
    //       border: 1px solid var(--secondary-color);
    //     }
    //   }
    // }
    & ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
}

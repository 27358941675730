.overview {
  // color: #fff;
  border-radius: 24px;
  padding: 22px 20px;
  background-color: var(--primary-color);
  min-height: calc(100vh - 280px);
  .papercon.multi {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    // justify-content: space-between;
    padding: 20px;
    background-color: var(--main-background-color);
    border-radius: 16px;
    // flex-wrap: wrap;
    gap: 30px;

    @media only screen and (max-width: 1115px) {
      grid-template-columns: 100%;
      gap: 20px;
    }
  }
  .initial_heading {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }
  .papercon .HeadT {
    margin-bottom: 8px;
  }
  .grid_container {
    display: grid;
    // grid-template-columns: 70% 30%  !important;
    padding-right: 20px;
    grid-gap: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr !important;
      padding-right: 0px;
    }
  }

  .box {
    border-radius: 16px;
    background-color: var(--main-background-color);
    padding: 16px;
  }

  .column-70 .row-1 {
    grid-row: 1;
    min-height: 127px;
    display: grid;
  }

  .column-30 .row-1 {
    grid-row: 1;
    min-height: 127px;
  }

  .row-2 {
    grid-row: 2;
    margin-top: 20px;
    box-sizing: border-box;
    height: 342px;
    @media (max-width: 1000px) {
      height: fit-content;
    }
  }

  .table_container {
    margin-top: 20px;
  }

  .infobox_col1_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 20px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }

  .infobox_col2_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 20px;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-start: 5;
      grid-column-end: 7;
    }
  }
}

.apc_box {
  // width: 188px;
  width: 100%;
  height: 127px;
  background-color: var(--main-background-color);
  border-radius: 16px;
  border: 1px solid #eaf0f8;
  .apc_box_wrapper {
    padding: 1rem 0.8rem;
  }
  img {
    height: auto;
    width: 90%;
  }
  .apc_box_upper {
    .upper_title {
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 0.1rem;
    }
    .upper_value {
      font-size: 26px;
      font-weight: 500;
    }
  }
}
.papercon .HeadT svg {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  position: relative;
  top: 10px;
  position: absolute;
  z-index: 1000;
  left: 7px;
}
.papercon .TextB {
  margin-top: 4px;
  font-size: 28px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

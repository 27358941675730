.error_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .error_modal_container {
    background-color: #ffffff;
    width: 300px;
    min-height: 300px;
    border-radius: 16px;
  }

  .error_modal_container_top {
    height: 50%;
    background-image: url("../../../assets/images/error-modal.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;

    .tick_icon {
      position: relative;
      bottom: 0;
      // left: 40%;
    }
  }

  .error_modal_container_bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;

    p {
      color: var(--light-gray-color);
      font-size: 16px;
      font-weight: 500;
    }

    .error_modal_close_btn {
      background-color: #df2935 !important;
      width: 80% !important;
      border-radius: 8px;
      color: #fff;
    }
  }
}

.previous_details_box {
  width: 294px;
  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 0px 2px 0px #1154ac;
    .previous_details_box_lower button {
      background-color: #36d66b;
      color: #fff;
    }
  }

  &_upper {
    border-bottom: 1px solid #eaf0f8;
    &_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }
    &_wrapper {
      padding: 20px;
      height: 100%;

      .bg_img_container {
        background-image: url("../../../assets/images/competition-bg-img.png");
        background-size: cover;
        border-radius: 8px;
        height: 190px;
        background-repeat: no-repeat;
        position: relative;

        &_header {
          position: absolute;
          top: 5px;
          right: 5px;
          display: flex;
          width: 30px;
          height: 30px;
          /* padding: 3px 4px 4px 3px; */
          justify-content: center;
          align-items: center;
          border-radius: 91px;
          background: var(--foundation-blue-blue-150-main, #36d66b);
          flex-shrink: 0;
          img {
            width: 23px;
            height: 23px;
          }
        }

        &_btn {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }
      }
    }

    .previous_details_container {
      padding-top: 10px;

      h2 {
        font-size: 18px;
        font-weight: 700;
      }

      div {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding-top: 20px;
        span {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &_middle {
    border-bottom: 1px solid #eaf0f8;
    &_wrapper {
      padding: 10px 20px 0 20px;
      .winner_details_container {
        p {
          color: #a4a8ae;
          font-size: 12px;
          font-weight: 700;
        }
        ul {
          font-size: 14px !important;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          gap: 20px;
          li {
            display: flex;
            align-items: center;
            img {
              margin-right: 12px;
              height: 20px;
              width: 20px;
            }
            &:first-child {
              span {
                color: #4cb944;
              }
            }
            &:nth-child(2) {
              span {
                color: #36d66b;
              }
            }
            &:last-child {
              span {
                color: #f75c03;
              }
            }
          }
        }
      }
    }
  }

  &_lower {
    flex-grow: 1;
    padding: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    &_wrapper {
      padding: 16px 20px;
    }
    .time_container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      h4 {
        font-size: 18px;
        font-weight: 700;
      }
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .progress_bar {
      width: 100%;
      background-color: #eaf0f8;
      height: 6px;
      border-radius: 10px;
      overflow: hidden;
    }

    .progress {
      width: 50%;
      height: 100%;
      background-color: #f9c80e;
    }

    .contestants_details_container {
      padding-top: 7px;
      font-size: 10px;
      font-weight: 500;
      .contestants_previous {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    button {
      padding: 0;
      height: 34px;
      width: 100%;
      border-radius: 8px;
      font-size: 14px;
      font-family: 700;
      background-color: #eaf0f8;
      color: #a4a8ae;
    }
  }

  @media (max-width: 1100px) {
    width: 400px;
  }

  @media (max-width: 1024px) {
    width: 45%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

.completed_tag {
  background-color: rgba(200, 233, 197, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #4cb944;
  border-radius: 4px;
}

.previous_tag {
  background-color: #fdeeb4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #b18e0a;
  border-radius: 4px;
}
.option_containerPrevious{
  display: flex;
  gap: 10px;
  align-items: center;
}
.sidemenu_listPrevious{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0px 0px 20px;
}

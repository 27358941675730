.authframe-container {
  overflow: hidden;
  max-width: 800px;
  width: 800px;
  height: 800px;
  background: url("../../../assets/images/signin-background.jpg");
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 36px;

  img:first-child {
    position: absolute;
    // width: 706px;
    // height: 460.66px;
    top: 64px;
    right: 64px;
  }

  img:last-child {
    position: relative;
    width: 706px;
    height: 460.66px;
    top: 216px;
  }
}

@media screen and (max-width: 1200px) {
  .authframe-container {
    overflow: hidden;
    width: 540px;
    // background: url("../../../assets/images/signin-background.jpg");
    background-repeat: no-repeat;
    border-radius: 36px;
    padding-right: 20px;

    .laptop {
      position: relative;
      width: 473px;
      height: 400.66px;
      top: 216px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .authframe-container {
    overflow: hidden;
    width: 500px;
    // background: url("../../../assets/images/signin-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 36px;
    margin-right: 20px;

    .laptop {
      position: relative;
      width: 503px;
      height: 400.66px;
      top: 216px;
    }
  }
}

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Slider {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    opacity: 1;
    transition: opacity 0.2s;

    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #3a7bec;
      cursor: pointer;
      border: none;
    }
  
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #3a7bec;
      cursor: pointer;
      border: none;
    }
  }
  
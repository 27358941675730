.dailydatabanner-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: var(--main-background-color);
  border-radius: 16px;
  // padding: 0.65em;
  padding: 1em;

  .dailydatabanner-subcontainer {
    display: flex;
    flex-direction: column;
    margin: auto;

    h5 {
      color: #a4a8ae;
      font-size: 12px;
    }

    p {
      margin: 0.5em 0;
      font-size: 16px;
      font-weight: 700;
      color: var(--secondary-color);
      &.loss {
        color: var(--red-error-color);
      }
    }

    img {
      margin: 0.5em 0 0.5em 0.75em;
      height: 32px;
    }
  }

  .dailydatabanner-container_chartrep {
    flex-direction: row;
    align-items: end;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0 0 0 10px;

    .dailydatabanner-subcontainer {
      flex-basis: 50%;
    }
  }
}

.dailydatabanner-gain {
  color: #4cb944;
}
.dailydatabanner-loss {
  color: #df2935;
}

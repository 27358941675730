.traderslist {
  .alltable {
    padding: 16px;
    overflow: auto;
  }
  padding: 22px 20px;
  background: var(--primary-color);
  border-radius: 24px;
  min-height: calc(100vh - 240px);
}

.card-container {
  width: 100%;

  border-radius: 16px;

  // color: white;
}

.tier-container {
  // width: 360px;

  height: fit-content;
  max-height: 660px;
  text-align: center;
  color: white;
  margin: none;

  .tier-type {
    padding: 1.5em 0;

    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      padding-top: 0.25em;
      color: #a4a8ae;
    }
  }

  .tier-price {
    position: relative;
    padding: 0.5em 0;

    h3 {
      font-family: "Space Grotesk", sans-serif;
      font-size: 42px;
      font-weight: 700;
      line-height: 58.8px;
    }
  }

  .tier-price::before {
    content: "";
    width: 36px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 45%;
    background-color: #36d66b;
  }

  button {
    margin: 1em auto 1.5em auto;
  }

  span {
    display: block;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
  }

  button {
    color: #1154ac;
    width: 200px;
    background: white;
    border: 1px solid #1154ac;
    border-radius: 62px;
    font-size: 12px;
    font-weight: 500;
  }

  .tier-num {
    p {
      padding: 1em 0;
      border-top: 1px solid #eaf0f8;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }
  }
}

.tier-highlight {
  color: white;
  background-image: url("../../assets/images/plan-tier-frame.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  h1,
  h2,
  h3,
  h4,
  p,
  span {
    color: white;
    font-weight: 400;
  }

  .tier-type {
    p {
      color: white;
      font-weight: 400;
    }
  }

  .tier-num {
    p {
      border-top: 1px solid #4692f5;
      font-weight: 400;
    }
  }

  .tier-price::before {
    background-color: #b7d5fb;
  }
}

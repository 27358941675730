.billing {
    padding: 22px 20px;
  
    .grid_container {
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 20px;
      box-sizing: border-box;
      padding-right: 20px;
  
      .col2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  
    .ach_details {
      background-color: var(--main-background-color);
      box-sizing: border-box;
      padding: 16px;
      border-radius: 16px;
  
      .ach_details_header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
  
        span {
          color: var(--secondary-color);
          text-decoration: underline;
        }
      }
  
      .ach_details_input_container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
  
        label {
          font-size: 14px;
          font-weight: 500;
          color: #3e3f47;
        }
  
        .ach_details_input {
          width: 100%;
          height: 48px;
          padding: 10px;
          box-sizing: border-box;
  
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 12px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  
    .account_type {
      background-color: var(--main-background-color);
      padding: 16px;
      border-radius: 16px;
  
      p {
        font-size: 14px;
        font-weight: 500;
      }
  
      .radio_container {
        padding: 10px 20px 0 20px;
  
        input[type='radio'] {
          height: 15px;
          width: 15px;
          appearance: none;
          border-radius: 50%;
          outline: none;
          transition: border-color 0.3s;
          background-color: var(--main-background-color);
          border: 2px solid #a4a8ae;
  
          &:checked {
            background-color: var(--main-background-color);
            border: 4px solid #36d66b;
          }
        }
  
        label {
          color: #0a3266;
          font-size: 16px;
          font-weight: 500;
          font-family: 'DM Sans', sans-serif;
        }
  
        div {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    @media (max-width: 768px) {
   
      .ach_details_input_container {
       flex-wrap: wrap;
      }
  
    }
    @media (max-width: 1024px) {
      .ach_details_input {
        padding: 8px;
        min-width: 0;
      }
      .ach_details_input_container {
       flex-wrap: wrap;
      }
  
    .grid_container {
        grid-template-columns: 1fr  !important;
        padding-right: 0;
      }
    }
  }
  
.overview_table {
  // background-color: var(--main-background-color);
  // border-radius: 16px;
  min-height: calc(100vh - 240px);
  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 0px;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    @media (max-width: 768px) {
      max-width: 800px;
    }
  }
  .table_wrapper {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: var(--main-background-color);
    padding: 16px;
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    width: 100%;
    a {
      margin-left: auto;
    }

    .search {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 60%;
    }
    .search_bar {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      background-color: #eaf0f8;
      border-radius: 63px;
      // margin-right: 20px;
      width: 90%;

      .search_input {
        flex: 1;
        height: 90%;
        border: none;
        outline: none;
        padding-left: 8px;
        border-radius: 63px;
        font-size: 14px;
        background-color: #eaf0f8;

        &::placeholder {
          color: #a4a8ae;
        }
        &:focus {
          outline: none !important;
          border: none !important;
        }
      }

      .search_icon {
        margin-left: 10px;
      }
    }

    .create_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 0;

      .search {
        width: 100%;
        margin-bottom: 10px;
      }

      .create_wrapper {
        margin-top: 10px;
        align-self: flex-end;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  table {
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: #bdbec0ad;
          color: black;
        }
      }
    }
    th {
      color: #909195;
      font-weight: 500;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
    }

    td {
      font-weight: 700;
      border-bottom: none;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
  }

  .view_details_btn {
    background: #36d66b;
    height: 28px;
    width: 104px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    border-radius: 4px;
    color: #fff;
  }
  .tracking_btn {
    background-color: #b7d5fb;
    color: #36d66b;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0 10px;
    border-radius: 24px;
  }
  .name_initial {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9c80e;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
  }

  .column_one_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .sno_wrapper {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4cb944;
      color: #fff;
    }
  }

  .column_header {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    img {
      margin-top: 2px;
    }
  }

  .challenge_dropdown {
    height: fit-content;
    width: 215px;
    background: #fff;
    position: absolute;
    border-radius: 16px;
    box-shadow: 0 0px 6px #b0c6e3;
    padding: 0;

    ul {
      height: 100%;
      padding: 0;
      margin: 0;
    }

    li {
      height: 40px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #0e0f19;

      &:not(:last-child) {
        border-bottom: 1px solid #eaf0f8;
      }
    }
  }

  .challenge_dropdown::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 65px;
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }

  @media (max-width: 1024px) {
    td,
    th {
      font-size: 12px !important;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11111111;
  .reffered_list_loaders {
    justify-content: center;
    height: 100%;
    margin-top: 10%;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

.modal-content {
  background-color: var(--main-background-color);
  color: var(--text-color) !important;
  border-radius: 8px;
  .selectInput {
    margin-bottom: 20px;
  }
  img {
    filter: invert(1);
  }
  padding: 16px;
  position: relative;
  min-height: 200px;
  min-width: 300px;
}

.close {
  position: absolute;
  top: -10px;
  right: 8px;
  cursor: pointer;
  font-size: 20px;
}

.overview_table {
  position: relative;
  // color: #fff;
  border-radius: 24px;
  padding: 16px;
  min-height: calc(100vh - 240px);
  // background-color: var(--main-background-color);

  .grid_container {
    display: grid;
    // grid-template-columns: 70% 30%  !important;
    padding-right: 20px;
    grid-gap: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr !important;
      padding-right: 0px;
    }
  }

  .box {
    border-radius: 16px;
    background-color: var(--main-background-color);
    padding: 16px;
  }

  .column-70 .row-1 {
    grid-row: 1;
    min-height: 127px;
    display: grid;
  }

  .column-30 .row-1 {
    grid-row: 1;
    min-height: 127px;
  }

  .row-2 {
    grid-row: 2;
    margin-top: 20px;
    box-sizing: border-box;
    height: 342px;
    @media (max-width: 1000px) {
      height: fit-content;
    }
  }

  .table_container {
    margin-top: 20px;
  }

  .infobox_col1_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 20px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }

  .infobox_col2_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 20px;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-start: 5;
      grid-column-end: 7;
    }
  }
}

.apc_box {
  // width: 188px;
  width: 100%;
  height: 127px;
  background-color: var(--main-background-color);
  border-radius: 16px;
  border: 1px solid #eaf0f8;
  .apc_box_wrapper {
    padding: 1rem 0.8rem;
  }
  img {
    height: auto;
    width: 90%;
  }
  .apc_box_upper {
    .upper_title {
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 0.1rem;
    }
    .upper_value {
      font-size: 26px;
      font-weight: 500;
    }
  }
}

.traderinfo {
    color: #000000;
    border-radius: 24px;
    font-family: "DM Sans", sans-serif;
    background: var(--primary-color);
  
    input {
      outline: none;
      background: transparent;
      color: #000000;
    }
  
    .traderinfo_top {
      height: fit-content;
      background-color: var(--background-color);
      ;
      border-radius: 16px;
      margin: 20px 0;
      padding-bottom: 20px;
    }
  
    .traderinfo_middle {
      height: fit-content;
      background-color: var(--background-color);
      ;
      border-radius: 16px;
      margin: 20px 0;
    }
  
    .traderinfo_bottom {
      height: fit-content;
      background-color: var(--background-color);
      ;
      border-radius: 16px;
      margin: 20px 0;
    }
  }
  
  .label_black {
    color: #3E3F47 !important;
  }
  
  
  
  
.accountdetails{
    padding: 16px;
    background-color: var(--main-background-color);
    border-radius: 16px;
    min-height: 239px;
     
    .accountdetails_input_container{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    label{
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: #000;
      }
      input:focus{
        border: 1px solid #36d66b !important;
    }
    .account_details_input {
  
        width: 100%;
        height: 48px;
        padding: 20px;
        box-sizing: border-box;
    
        border-radius: 8px;
        border: 1px solid #EAF0F8;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 500;
        outline: none;
      }

      .input_container {
        position: relative;
      }
      
      .account_password_input {
        width: 100%;
        height: 48px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #EAF0F8;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 80px;
        outline: none;
      }
      
      .eye_icon {
        position: absolute;
        top: 60%;
        right: 20px;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .lock_icon{
        position: absolute;
        top: 60%;
        left: 20px;
        transform: translateY(-50%);
        cursor: pointer;
      }
}
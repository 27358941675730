.trading_stats {
    min-height: 80vh;
    padding: 20px;
    .trading_stats_box_container{
       display: grid;
       grid-template-columns: repeat(3 , 1fr);
       grid-gap: 20px;
       @media (max-width: 1200px) {
        grid-template-columns: repeat(2 , 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(1 , 1fr);
      }
    }

    .trading_stats_box {
        background-color: var(--background-color);
        width: 100%;
        border-radius: 16px;
        box-sizing: border-box;
        @media (max-width: 400px) {
           max-width: 300px;
          }


        .box_header {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 57px;
            padding: 16px;
            box-sizing: border-box;
            font-size: 18px;
            font-weight: 700;
            @media (max-width: 1200px) {
                font-size: 14px;
              }

            img {
                height: 21px;
                width: 21px;
            }
        }

        .box_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            height: 117px;
            border-bottom: 1px solid var(--primary-color);
            border-top: 1px solid var(--primary-color);
            padding: 16px;
            box-sizing: border-box;
            @media (max-width: 1200px) {
                font-size: 14px;
              }
             img{
                @media (max-width: 500px) {
                   height: 40px;
                   width: 40px;
                  }
             }
            .box_content_info {
                >div {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    span {
                        display: flex;
                        padding: 4px 8px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 4px;
                        background-color: rgba(200, 233, 197, 0.50);
                        color: var(--green-success-color);
                        img{
                            height: 13px;
                            width: 13px;
                        }
                    }
                }
            }

        }

        .box_bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 61px;
            padding: 16px;
            box-sizing: border-box;
            background-color: rgba(234, 240, 248, 0.5);
        }
    }
}
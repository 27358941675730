.verifynow{
   min-height: 360px;
   background-color: #fff;
   padding: 16px;
   border-radius: 16px;
   &_container{
    border: 1px solid #EAF0F8;
    border-radius: 8px;
}
   &_header{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EAF0F8;
    height: 81px;
    box-sizing: border-box;
 
    input[type="radio"] {
        height: 20px;
        width: 20px;  
        appearance: none;
        border-radius: 50%;
        outline: none;
        transition: border-color 0.3s;
        background-color: #fff;
        border: 4px solid #eee;
        &:checked {
            background-color: var(--main-background-color);;
            border: 4px solid #36d66b;
        }
    }
     p{
        font-size: 18px;
        font-weight: 700;
     }
    div{
        display: flex;
        align-items: center;
        gap: 20px;
    }
   }
   &_details{
     padding: 20px;
     display: flex;
     flex-direction: column;
     gap: 20px;
     h4{
        font-size: 16px;
        font-weight: 500;
        opacity: 0.5;
     }
     &_warning{
        ul {
            list-style-type:disc ;
            padding-left: 50px;
            font-size: 14px;
            font-weight: 500;
            font-style: italic;
            opacity: 0.5;
          li{
            margin-bottom: 5px;

          }
        li::marker{
            color: var(--secondary-color);

        }
    }
    button{
        padding: 10px 72px;
        border-radius: 23px;
        margin-left: 40px;
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;

    }
     }
     &_warning_header{
        display: flex;
        align-items: center;
        gap: 10px;
        opacity: 0.5;
     }
   }
}
table {
  .upgrade_button {
    background: var(--secondary-color);
    padding: 6px 10px;
    color: #fff;
    border-radius: 6px;
    text-wrap: nowrap;
  }
}
.economiccalender-container {
  border-radius: 16px;
  background-color: var(--main-background-color);
  padding: 24px;
  padding-bottom: 60px;
  .filter-header {
    padding: 16px 16px 0px 16px;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 20px;
    display: flex;
    .search {
    }
  }
}
.table_scroll {
  margin-top: 20px;
}

.sort_buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  flex: 1;
  align-items: flex-end;
  .react-select__control {
    height: 100%;
    min-width: 147px;
    border-radius: 10px;
    flex: 1;
  }
  div {
    button {
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
      border-radius: 10px;
      padding: 10px;
      flex: 1 1;
      margin: 0px;
      &.activeBtn {
        background-color: var(--secondary-color) !important;
        color: #fff !important;
      }
    }
  }
}

.reqpayout {
    padding: 22px 20px;
  
    .grid_container {
      display: grid;
      grid-template-columns: 1fr !important;
      gap: 20px;
      box-sizing: border-box;
  
      @media (min-width: 1024px) {
        grid-template-columns: 70% 30% !important;
        padding-right: 20px;
      }
  
      .col1,
      .col2 {
        border-radius: 16px;
      }
  
      .col1 {
        padding: 22px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: fit-content;
        background: var(--main-background-color);
  
        &_header {
          font-size: 16px;
          font-weight: 700;
          color: #a4a8ae;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          img {
            transform: rotate(90deg);
          }
        }
  
        &_lower_select_container {
          padding-top: 20px;
          p {
            font-size: 14px;
            font-weight: 500;
            color: #a4a8ae;
          }
        }
      }
  
      .col1_content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h4 {
          font-size: 20px;
          font-weight: 700;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: #a4a8ae;
        }
      }
    }
  
    .col2 {
      height: 676px;
      border-radius: 16px;
      background-image: url('../../../../assets/images/carousel-background.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      @media (max-width: 1024px) {
        height: 400px;
      }
  
      .col2_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 60px;
        h3 {
          position: absolute;
          bottom: 65px;
          right: 10px;
          color: #fff;
        }
      }
      button {
        background-color: #fff;
        color: #36d66b;
        width: 236px;
        height: 48px;
        border-radius: 8px;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
  .tradingaccount {
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  
    .radiobox {
      min-height: 200px;
      border: 1px solid #eaf0f8;
  
      &_header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eaf0f8;
        height: 81px;
        box-sizing: border-box;
  
        input[type='radio'] {
          height: 20px;
          width: 20px;
          appearance: none;
          border-radius: 50%;
          outline: none;
          transition: border-color 0.3s;
          background-color: var(--main-background-color);
          border: 4px solid #eee;
  
          &:checked {
            background-color: var(--main-background-color);
            border: 4px solid #36d66b;
          }
        }
  
        p {
          font-size: 18px;
          font-weight: 700;
        }
  
        div {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }
  
      &_details {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
  
        p {
          font-size: 16px;
          font-weight: 500;
        }
  
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          color: #368330;
        }
      }
    }
  }
  
  
  @media (min-width: 576px) {
    .reqpayout {
      .grid_container {
        padding-right: 40px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .reqpayout {
      .grid_container {
        grid-template-columns: 70% 30%;
        padding-right: 60px;
      }
    }
  }
  
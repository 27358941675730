.info_box {
  // width: 188px;
  width: 100%;
  // height: 127px;
  background-color: var(--main-background-color);
  border-radius: 16px;
  // border:1px solid #EAF0F8;
  .info_box_wrapper {
    padding: 1rem 0.8rem;
    border-radius: 16px;
    // border: 1px solid var(--Foundation-Neutral-White-L100---BG-color, #eaf0f8);
    background: var(--Foundation-Neutral-White-L50---White, #fff);
    h2 {
      color: var(--text-color) !important;
    }
  }
  .info_box_upper {
    .upper_title {
      color: var(--neutral-n-grey-250-outline, #a5b5bf);
      font-family: "TT Norms", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
    }
    .upper_value {
      text-align: center;
      font-family: "TT Norms", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
    }
  }

  .info_box_lower {
    display: flex;
    align-items: center;
    gap: 12px;
    // padding-top: 0.8rem;
    color: #a4a8ae;
    .arrow_wrapper {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .lower_value {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.info_box_variant2 {
  .info_value {
    display: flex;
    align-items: center;
    padding-bottom: 0.75rem;

    img {
      padding-right: 0.5em;
    }

    .info_title {
      flex-basis: 100%;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .info_content {
    color: #3e3f47;
    font-family: "Space Grotesk", sans-serif;
    font-size: 26px;
    font-weight: 500;
  }
}

.info_box_variant3 {
  .info_box_img {
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 40px;
    max-width: 120px;
  }
  .value_green {
    color: var(--green-success-color) !important;
  }
  .value_red {
    color: #df2935 !important;
  }
}

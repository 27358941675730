.stage1pass_table {
  border-radius: 16px;
  background-color: var(--main-background-color);
  padding: 16px;
  min-height: 68vh;
  table {
    td {
      vertical-align: middle;
      padding: 1em 1.5em;
      height: 28px;
      font-weight: 700;
    }
    th {
      vertical-align: middle;
      padding: 1em 1.5em;
      height: 28px;
      font-weight: 700;
    }
  }
  .selectInput {
    margin-bottom: 10px;
  }
  .selectInput {
    margin-bottom: 20px;
  }
  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 0px;
  }
  .select_wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  .calendar_wrapper {
    position: absolute;
    right: -36px;
    margin-top: -30px;
    z-index: 100;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    @media (max-width: 768px) {
      max-width: 500px;
    }
  }

  .table_wrapper {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
  }

  .table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    @media (max-width: 768px) {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
    }

    .search {
      // visibility: hidden;
      // pointer-events: none;
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      max-width: 500px;

      @media (max-width: 768px) {
        gap: 10px;
        width: 100%;
      }
      @media (max-width: 400px) {
        width: 100%;
        margin-left: 2px;
      }
    }

    .search_bar {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      width: 330px;
      background-color: var(--primary-color);
      border-radius: 63px;
      // margin-right: 20px;
      box-shadow: 0px 0px 2px 0px #1154ac;

      .search_input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 8px;
        border-radius: 63px;
        font-size: 14px;
        background-color: #eaf0f8;
        &::placeholder {
          color: #a4a8ae;
        }

        &:focus {
          outline: none !important;
          border: none !important;
        }
      }

      .search_icon {
        margin-left: 10px;
      }
    }

    .filter_buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 500px;
      gap: 10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      button {
        background-color: #eaf0f8;
        padding: 10px 20px;
        border-radius: 10px;
        color: #a4a8ae;
      }

      .active_btn {
        background-color: #36d66b;
        color: white;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  table {
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;

    th {
      color: #909195;
      font-weight: 500;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
    }

    td {
      font-weight: 700;
      border-bottom: 1px solid #eaf0f8;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
    .column_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @media (max-width: 1024px) {
    td,
    th {
      font-size: 12px !important;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }

  .name_initial {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9c80e;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
  }
  .column_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.passed_tag {
  background-color: rgba(200, 233, 197, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #4cb944;
  border-radius: 4px;
  text-transform: capitalize;
}

.metrics_tag {
  background-color: #fdeeb4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #b18e0a;
  border-radius: 4px;
  text-transform: capitalize;
}

.failed_tag {
  background-color: #f5bdc0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #9e1d26;
  border-radius: 4px;
  text-transform: capitalize;
}

.support_inputs {
  width: 100%;
  height: 48px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #eaf0f8;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: black;
  background-color: var(--main-background-color);
  resize: none;
  &:focus {
    outline: var(--foundation-blue-blue-150-main);
  }
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.modal_inputs {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal {
  .modal_content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    position: relative;
  }
  .create_btn {
    background-color: #36d66b;
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    width: 100%;
  }
  .modal_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.notification_table {
  border-radius: 16px;
  background-color: var(--main-background-color);
  padding: 16px;
  .select_wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  .calendar_wrapper {
    position: absolute;
    right: -36px;
    margin-top: -30px;
    z-index: 100;
  }
  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    @media (max-width: 768px) {
    }
  }

  .table_wrapper {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    gap: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 0;
    }

    .search {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 60%;

      @media (max-width: 768px) {
        width: 100%; // Adjust width for smaller screens
      }
    }

    .search_bar {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      background-color: #eaf0f8;
      border-radius: 63px;
      // margin-right: 20px;
      width: 90%;

      @media (max-width: 768px) {
        margin-right: 0; // Remove margin for smaller screens
        width: 100%; // Adjust width for smaller screens
      }

      .search_input {
        flex: 1;
        height: 90%;
        border: none;
        outline: none;
        padding-left: 8px;
        border-radius: 63px;
        font-size: 14px;
        background-color: #eaf0f8;
        &::placeholder {
          color: #a4a8ae;
        }

        &:focus {
          outline: none !important;
          border: none !important;
        }
      }

      .search_icon {
        margin-left: 10px;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  table {
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;

    th {
      color: #909195;
      font-weight: 500;
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
    }

    td {
      font-weight: 700;
      border-bottom: 1px solid #eaf0f8;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
    .column_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .created {
    background: rgba(183, 213, 251);
    color: #36d66b !important;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0 10px;
    border-radius: 30px;
  }

  @media (max-width: 1024px) {
    td,
    th {
      font-size: 12px !important;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }

  .filter_buttons {
    display: flex;
    align-items: center;

    button {
      background-color: #eaf0f8;
      margin: 0 10px;
      padding: 10px 20px;
      border-radius: 10px;
      color: #a4a8ae;
    }

    .active_btn {
      background-color: #36d66b;
      // color: white;
    }
  }
}

.accepted_tag {
  background-color: rgba(200, 233, 197, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #4cb944;
  border-radius: 4px;
  text-transform: capitalize;
}

.rejected_tag {
  background-color: #f5bdc0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #9e1d26;
  border-radius: 4px;
  text-transform: capitalize;
}

.upcoming_ads {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .ad_details_box_wrapper {
    width: calc(33.33% - 20px); 
    margin-bottom: 20px;

    @media (max-width: 1200px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 768px) {
      width: 100%; 
    }
  }
}

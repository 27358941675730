// .loader_overlay {
//   display: flex !important;
//   // height: 79vh !important;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   left: 0px;
//   top: 0px;
//   border-radius: 30px;
//   background-color: rgba(88, 88, 88, 0.103);
//   z-index: 20;
//   -webkit-backdrop-filter: blur(2px);
//   backdrop-filter: blur(2px);
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   .container {
//     transform: translate(-50%, -50%);
//     position: absolute;
//     z-index: 100;
//     left: 50%;
//     top: 45%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 40px;
//     h1 {
//       color: grey;
//       font-size: 26px;
//       z-index: 100;
//     }

//     .loader {
//       border: 2px solid var(--white-color);
//       border-radius: 50%;
//       border-top: 2px solid var(--secondary-color);
//       width: 12px;
//       height: 12px;
//       animation: spin 1s linear infinite;
//       z-index: 100;

//       scale: 2;
//     }
//   }
// }

// @-webkit-keyframes spin {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

.loader_overlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  background-color: #7c7c7c4a;
  z-index: 99;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
opacity: 0.7; 
  position: absolute;
  width: 100%;
  height: 100%;
}
.container {
  // transform: translate(-50%, -50%);
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  h1 {
    color: var(--secondary-color);
    font-size: 26px;
    z-index: 100;
  }

  .loader {
    border: 2px solid var(--white-color);
    border-radius: 50%;
    border-top: 2px solid var(--secondary-color);
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    z-index: 100;

    scale: 2;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 800px) {
  .loader_overlay {
    background-color: #7c7c7c4a;
    z-index: 99;
    position: absolute;
    
  }
  .container {
    left: 50%;
  }
}
@media (max-width: 600px) {
  .loader_overlay {
    background-color: #7c7c7c4a;
    z-index: 99;
    position: absolute;

  }
  .container {
    left: 40%;
  }
}
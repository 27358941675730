.profilecard_details_upper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: start;
  // width: 332px;
  // padding-top: 20px;
  position: relative;
  padding: 1em;
  color: black;
  .profilecard_edit_img_wrapper {
    margin-right: 20px;
  }
  @media (max-width: 400px) {
    max-width: 300px;
  }

  .profilecard_details_upper_content {
    cursor: pointer;
    h4 {
      font-size: 18px;
      font-weight: 700;
      font-family: "DM Sans", sans-serif;
      @media (max-width: 1160px) {
        font-size: 14px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      padding-left: 2px;
      color: #3e3f47;
    }
    span {
      font-weight: 800;
    }
  }

  .profilecard_img_wrapper {
    img {
      width: 40px;
      margin-right: 20px;
    }
  }
}

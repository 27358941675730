.edit_address_modal_container {
  background-color: var(--main-background-color);
  width: 444px;
  // height:534px;
  border-radius: 16px;
  padding: 20px;
  .add_address_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    // border-left: 1px solid #A4A8AE;
    padding: 0 10px;
    input {
      outline: none;
      border: none;
      width: 90%;
      font-size: 16px;
      font-weight: 500;
    }
    .react_select_container {
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      .css-1fdsijx-ValueContainer {
        padding: 0 !important;
      }
    }
  }
  .btn_container {
    padding-top: 20px;
    .save_info_btn {
      width: 100%;
      border-radius: 62px;
    }
  }
}

.edit_address_info_container {
  p {
    font-size: 14px;
    font-weight: 500;
  }
  .billing_info_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 12px;
  }

  .address_info_form_input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    border: 1px solid #a4a8ae;
    border-radius: 8px;
  }

  .input_icon {
    height: 24px;
    width: 24px;
    padding: 0 20px;
  }

  label {
    color: #a4a8ae;
    font-size: 12px;
    font-weight: 500;
  }
}

.billing_info_form_container {
  padding-top: 20px;
}

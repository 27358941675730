.leaderboard_table {
  .table_wrapper {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .calendar_wrapper {
    position: absolute;
    right: -36px;
    margin-top: -30px;
    z-index: 100;
  }
  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 0px;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    @media (max-width: 768px) {
      max-width: 500px;
    }
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    @media (max-width: 500px) {
      padding-right: 0;
    }
    p {
      font-size: 18px;
      font-weight: 700;
    }

    .select_wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .select_icon {
        margin-top: 4px;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  table {
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;

    th {
      color: #909195;
      font-weight: 500;
      position: relative;
   
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
      vertical-align: middle;
    padding: 1em 1.5em;
    height: 28px;
    font-weight: 700;
    }

    td {
      font-weight: 500;
      border-bottom: 1px solid var(--primary-color);
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
  }
  
  .view_details_btn {
    background: #ecf4fe;
    height: 28px;
    width: 104px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    border-radius: 4px;
    color: var(--secondary-color);

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }
    
  }

  .tag {
    background: #ecf4fe;
    height: 28px;
    width: 104px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    border-radius: 4px;
    color: var(--secondary-color);
    cursor: default

 
    
  }

  .column_one_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .sno_wrapper {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--secondary-color);
      color: #fff;
    }

    .bg_green {
      background: var(--green-success-color);
    }

    .bg_yellow {
      background: var(--yellow-warning-color);
    }

    .bg_orange {
      background: var(--orange-color);
    }
  }

  .column_header {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;.view_details_btn

    img {
      margin-top: 2px;
    }
  }

  .calendar-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    p {
      margin: 0 12px 0 8px;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }
    .arrow_down {
      margin-left: 16px;
    }

    .column_one_header {
      margin-left: 10px;
    }

    .challenge_dropdown {
      height: fit-content;
      width: 215px;
      background: var(--background-color);
      position: absolute;
      border-radius: 16px;
      box-shadow: 0 0px 6px #b0c6e3;
      padding: 0;
      left: 0;
      top: calc(100% + 5px);

      ul {
        height: 100%;
        padding: 0;
        margin: 0;
      }

      li {
        height: 40px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #0e0f19;

        &:not(:last-child) {
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }

    .challenge_dropdown::before {
      content: "";
      position: absolute;
      top: -10px;
      left: 65px;
      border-bottom: 10px solid var(--background-color);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    @media (max-width: 1024px) {
      td,
      th {
        font-size: 12px !important;
      }
    }
  }

  @media (max-width: 500px) {
    td,
    th {
      font-size: 12px !important;
    }

    .table_wrapper {
      max-width: 300px;
      overflow-x: auto;
    }

    .leaderboard_table {
      margin-top: 20px;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }
}

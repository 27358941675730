.pushleads{
    @media (max-width: 1024px) {
        padding-bottom: 30px;
 
       }
       @media (max-width: 500px) {
        padding-bottom: 70px;
 
       }
    .chart_wrapper{
        max-height: 350px;
    }
    .chart_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        @media (max-width: 500px) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;;
     
           }
        
        >div{
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}

.chart_title{
    font-size: 14px;
    font-weight: 500;
}

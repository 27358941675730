.add_address_modal_container {
  background-color: var(--main-background-color);
  width: 444px;
  max-height: 75vh;
  overflow-y: scroll;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;

  input[type="text"][disabled] {
    color: rgb(85, 85, 85);
  }
  .add_address_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    border-left: 1px solid #a4a8ae;
    padding: 0 10px;
    input {
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      background: #fff;
    }
    .react_select_container {
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      .css-1fdsijx-ValueContainer {
        padding: 0 !important;
      }
    }
  }
  .btn_container {
    padding: 20px 0;
    .save_info_btn {
      width: 100%;
      // height: 67px;
      font-size: 14px;
      margin-top: 20px;
      padding: 20px 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 16px;
      background: #36d66b;
      box-shadow: 2px 4px 4px 0px rgba(17, 84, 172, 0.2);
      color: #fff;
    }
  }
}

.add_address_info_container {
  padding-top: 26px;
  p {
    font-size: 14px;
    font-weight: 500;
  }
  .general_info_form,
  .billing_info_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 12px;
  }

  .address_info_form_input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    border: 1px solid #a4a8ae;
    border-radius: 8px;
  }

  .input_icon {
    height: 24px;
    width: 24px;
    padding: 0 20px;
  }

  label {
    color: #a4a8ae;
    font-size: 12px;
    font-weight: 500;
  }
}

.billing_info_form_container {
  padding-top: 20px;
}

.coupons_wrapper {
  position: relative;
  height: auto;
  & ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  // height: 80%;
  .coupons {
    border-radius: 24px;
    background-color: var(--primary-color);
    padding: 20px;
    /* height: inherit; */
    /* overflow: auto; */
    /* max-height: 608px; */
    height: 72vh;
    overflow: auto;
  }
}

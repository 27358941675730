.billinginfo-container {
  border: 1px solid #eaf0f8;
  border-radius: 8px;
  min-height: 157px;

  input[type="radio"] {
    height: 20px;
    width: 20px;
    appearance: none;
    border-radius: 50%;
    outline: none;
    transition: border-color 0.3s;
    background-color: var(--main-background-color);
    border: 4px solid #eee;

    &:checked {
      background-color: #fff;
      border: 4px solid #36d66b;
    }
  }

  .billinginfo-container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaf0f8;
    padding: 20px;

    .billinginfo-container_header_left {
      display: flex;
      align-items: center;
      gap: 20px;

      p {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .billinginfo-container_details {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 560px;
    padding-left: 58px;
    padding-top: 23px;
    padding-bottom: 20px;
    box-sizing: border-box;

    @media (max-width: 600px) {
      display: block;
      width: 100%;
      padding-left: 0;
      align-items: center;
      padding: 10px;
    }

    p {
      color: #0e0f19;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

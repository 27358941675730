.affiliate_codes {
  border-radius: 16px;
  // background-color: var(--background-color, #111512);
  padding: 16px;
  .react-select-container {
    width: 100%;
  }
}
.affiliate_table2 {
  border-radius: 16px;
  // background-color: var(--primary-color);
  // padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.get_a_link_header {
  display: flex;
  flex-direction: column;
  // border: 1px solid #242529;
  border-radius: 16px;
  padding: 20px;
  gap: 10px;
  // width: 96%;
  flex: 1;
  background: var(-backround-color);
  background-color: var(--main-background-color);
}
.get_a_link_conatiner2 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.coupon_code {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  border-radius: 16px;
  // border: 1px solid var(--Border, #242529);
  padding: 20px;
  background-color: var(--main-background-color);
}
.comission_rate {
  width: 30%;
  border-radius: 16px;
  // border: 1px solid var(--Border, #242529);
  padding: 20px;
  background-color: var(--main-background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.input_container {
  display: flex;
  width: 95%;
  padding: 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #fff !important;
  border: 1px solid var(--Border, gray);
}
.copy_text {
  display: flex;
  width: 90%;
  padding: 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--Border, gray);
  background: var(--secondary-color);
  color: #fff;
  font-size: 16px;
}

.input_div {
  width: 100%;
  display: flex;
  position: relative;
  img {
    filter: invert(1);
    top: 13px;
    position: absolute;
    /* left: -35px; */
    right: 6px;
  }
}
.copylink {
  filter: none !important;
}
.common_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  input {
    width: 95%;
  }
}
.rate {
  display: flex;
  justify-content: space-around;
  border: 1px solid var(--Border, gray);
  padding: 14px;
  border-radius: 6px;
  filter: hue-rotate(75deg);
}
.rate img {
  cursor: pointer;
}
.input_div img {
  filter: invert(1);
  top: 13px;
  position: absolute;
  /* left: -35px; */
  right: 6px;
}
.genrate_button {
  background-color: var(--secondary-color);
  width: 100%;
  color: white;
  display: flex;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--Border, #242529);
  //   background-color: #111512;
  cursor: pointer;
}
@media (max-width: 800px) {
  .get_a_link_conatiner2 {
    flex-direction: column;
    gap: 20px;
  }
  .get_a_link_header {
    width: auto;
  }
  .comission_rate {
    width: fit-content;
    display: flex;
    gap: 20px;
  }
  .coupon_code {
    width: fit-content;
  }
  // .affiliate_codes {
  //   padding: 0px;
  // }
  .affiliate_table {
    padding: 8px;
  }
}

.account_details_upper {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
  width: 332px;
  // padding-top: 20px;
  position: relative;
  padding: 10px 20px 0 20px;

  .account_details_upper_content {
    h4 {
      font-size: 20px;
      font-weight: 700;
      font-family: "DM Sans", sans-serif;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 36px;
      padding-left: 2px;
      color: #3e3f47;
    }
  }
}

.account_img_wrapper img {
  width: 80px;
}

.account_edit_img_wrapper {
  width: 20px;
  height: 20px;
  margin-top: -40px;
}
.changePassword_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.account_details_lower {
  padding: 10px 20px;

  label {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #8b8e93;
  }
  input:focus {
    border: 1px solid #36d66b;
  }

  .account_input_container_upper {
    display: grid !important;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)) !important;
  }
  .account_input_container_lower {
    margin-top: 20px;
  }
  .account_details_input {
    width: 100%;
    height: 48px;
    padding: 20px;
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid #eaf0f8;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
  }
}

hr {
  border: 0.5px solid #eaf0f8;
}

.account_input {
  width: 100%;
  max-width: 318px;
}

.account_details_upper_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

@media (max-width: 768px) {
  .account_input_container_upper,
  .account_input_container_lower {
    flex-wrap: wrap;
  }
}

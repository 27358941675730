.signinform-container {
  width: 100%;
  text-align: center;

  .signinform-subcontainer {
    width: 404px;
    margin: auto;
    @media screen and (max-width: 600px) {
     width: 100%;
    }
    .signinform-title {
      font-size: 32px;
      font-weight: 700;
    }

    .signinform-subtitle {
      margin: 0.75em auto;
      color: var(--subtext-color);
      font-size: 16px;
      font-weight: 500;
    }

    .signinform-innercontainer {
      display: flex;
      justify-content: center;
      margin: 1em auto;

      .signinform-container_toggle {
        display: flex;
        justify-content: center;
        padding: 0.4em;
        background-color: var(--primary-color);
        border-radius: 50px;
      }

      .signinform-toggle {
        padding: 0.5em 2.5em;
        font-size: 16px;
        font-weight: 700;
        border-radius: 50px;
        cursor: pointer;
      }

      .signinform-toggle:first-child {
        background-color: var(--background-color);
      }
      .signinform-toggle:last-child {
        color: var(--subtext-color);
      }
    }

    .signinform-container_form {
      display: block;
      margin: 2em auto;

      .signinform-subcontainer_form {
        position: relative;
        margin: 16px auto;
        border-radius: 8px;
        // filter: drop-shadow(0 0 2px 0 var(--secondary-color));

        .signinform-innercontainer_form {
          display: flex;
          height: 60px;
          background: var(--background-color);
          border: 1.5px solid var(--gray-color);
          border-radius: 8px;
          // box-shadow: 0px 0px 2px 0px var(--secondary-color);

          &:focus-within {
            // border: 1.5px solid var(--border-color);
            border-color: transparent;
            outline: 1.5px solid var(--border-color);
            box-shadow: 0 0px 6px var(--secondary-color);
          }

          .signinform-container_icon {
            display: inline-flex;
            margin: auto;
            .signinform-icon {
              width: 20px;
              margin: 1em;
            }
          }

          .signinform-container_input {
            display: flex;
            position: relative;
            flex-direction: column;
            text-align: start;
            flex-basis: 100%;
            margin: auto;
            padding-left: 0.5em;

            .signinform-label {
              margin-left: 0.2em;
              color: var(--subtext-color);
              font-size: 12px;
            }

            .signinform-input {
              width: 98%;
              border: none !important;
              outline: none !important;
              font-size: 16px;
              font-weight: 500;
            }
          }

          .signinform-container_input::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 2px;
            background-color: var(--subtext-color);
            transform: translateX(-50%);
            margin-left: -10px;
          }
        }

        .input-error {
          border-color: var(--red-error-color);
        }
      }

      .signinform-container_remember-me {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1em auto 1.5em auto;
        color: var(--subtext-color);
        font-size: 14px;
        border: none;

        .signinform_subcontainer_wrapper {
          display: flex;
          align-items: center;
        }

        .forgot_password {
          cursor: pointer;
        }

        .signinform-label_checkbox {
          margin-left: 0.5em;
        }
      }

      .signinform-error {
        text-align: end;
        color: var(--text-error-color);
        font-size: 12px;
        font-weight: 500;
      }
    }

    .signinform-container_icon {
      display: flex;

      .signinform-subcontainer_icon {
        display: flex;
        margin: 0.5em 0.75em;
        width: 59px;
        height: 59px;
        background-color: var(--icon-background-color);
        border-radius: 50%;
        cursor: pointer;
      }

      .signinform-icon {
        margin: auto;
      }
    }

    .signinform-continue,
    .signinform-link {
      color: var(--subtext-color);
      font-size: 16px;
      font-weight: 500;
    }

    .signinform-link_bold {
      color: var(--text-link-color);
    }
  }
  .signinform-container_button {
    display: flex;

    .signinform-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 8px;
      background-color: var(--secondary-color);
      color: #ffffff;

      .signinform-icon {
        padding: 0 0.5em;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .signinform-subcontainer {
    width: 80%;
    margin: 0 auto;

    .signinform-title {
      font-size: 24px;
    }

    .signinform-subtitle {
      font-size: 14px;
      margin: 0.5em auto;
    }

    .signinform-toggle {
      padding: 0.4em 1.5em;
      font-size: 14px;
      border-radius: 30px;
    }

    .signinform-subcontainer_form {
      margin: 8px auto;

      .signinform-container_icon {
        .signinform-icon {
          width: 18px;
        }
      }

      .signinform-label {
        font-size: 10px;
      }

      .signinform-input {
        font-size: 14px;
        &:focus {
          background-color: transparent;
        }
      }
    }


    .signinform-container_remember-me {
      margin: 0.5em auto 1em auto;
      font-size: 12px;

      .signinform-label_checkbox {
        margin-left: 0.3em;
      }
    }

    .signinform-button {
      font-size: 14px;
    }

    .signinform-icon {
      padding: 0 0.3em;
    }
  }
}

.signinform-input:-webkit-autofill {
  background-color: transparent !important;
}


.signinform-input:focus {
  background-color: transparent !important;
}

.signinform-input:not(:focus) {
  background-color: initial !important;
}

.warning{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px;
    border-radius: 16px;
    background-color: #FDEEB4;
    color:#695406;
    font-size: 14px;
    font-weight: 500;
    p{
        max-width: 592px;
    }
}
.previous {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  padding-bottom: 120px;
}
.sidemenuPrevious {
  width: 174px !important;
  height: 103px !important;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 6px#b0c6e3;
  border: 1px solid #ccc;
  padding: 10px !important;
  z-index: 9999;
  position: absolute;
  left: 2rem;
  margin-top: 40px;

  .sidemenu_list_container {
    font-size: 14px;
    font-weight: 500;

    h4 {
      color: #909195;
    }

    .sidemenu_list {
      padding-left: 20px;

      h4 {
        color: #000;
        padding-top: 20px;

        &.active {
          color: #36d66b;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 30%;
    right: 100%;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff;
  }
}

@media (max-width: 600px) {
  .sidebar {
    display: none;
  }

  .mobile-menu {
    display: flex !important;
  }
}

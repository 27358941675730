.create_plan {
  padding: 20px 20px;
  background: var(--primary-color);
  border-radius: 24px;

  input {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .grid_container {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 20px;
    padding-right: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: 60% 40% !important;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr !important;
    }

    .col1 {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &_top {
        align-items: center;
        gap: 20px;
        width: 100%;
      }

      .plan_input_wrapper {
        display: flex;
        padding: 20px;
        border-radius: 16px;
        font-size: 14px;
        //   height: 547px;
        flex-direction: column;
        // margin-top: 20px;

        background-color: var(--main-background-color);
        .create_header {
          p {
            font-size: 16px;
            font-weight: 600;
            text-wrap: nowrap;
          }
          margin-bottom: 20px;
        }
        .plan_selector {
          input:focus {
            border: none !important;
          }
        }

        label {
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 10px;
          text-transform: capitalize;
        }
        button {
          width: 100%;
          height: 48px;

          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-size: 14px;
          font-weight: 500;
          color: inherit;
          background-color: var(--main-background-color);

          .container {
            display: flex;
            justify-content: inherit;
            align-items: center;
            margin-left: 20px;
          }
        }
        input,
        textarea {
          width: 100%;
          height: 48px;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          resize: none;
          &:focus {
            outline: var(--foundation-blue-blue-150-main);
          }
        }
        textarea {
          height: 100%;
        }
      }
      &_middle {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        padding: 20px;
        border-radius: 16px;
        font-size: 14px;
        /* flex-direction: column; */
        justify-content: space-between;

        background-color: var(--main-background-color);
        gap: 20px;
        @media (max-width: 500px) {
          // max-width: 250px;
          grid-template-columns: repeat(1, 1fr);
        }
        label {
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 10px;
        }

        &_left {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;

          .input_wrapper {
            display: flex;
            flex-direction: column;
          }
          .product_id {
            width: 100%;
            height: 48px;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #eaf0f8;
            margin-top: 0px;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: black;
            background-color: var(--main-background-color);
            resize: none;
            &:focus {
              outline: var(--foundation-blue-blue-150-main);
            }
          }
        }
        &_right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;

          .plus_minus_input_wrapper {
            display: flex;
            flex-direction: column;
            .plus_minus_input {
              input {
                all: unset;
              }

              .input_wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 48px;
                /* padding: 20px; */
                box-sizing: border-box;
                border-radius: 8px;
                border: 1px solid #eaf0f8;
                margin-top: 0px;
                font-family: DM Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: black;
                background-color: var(--main-background-color);
                resize: none;
                input {
                  all: unset;
                  text-align: center;
                }
                img {
                  width: 20px;
                  margin: 0px 20px;
                }
                &:focus {
                  outline: var(--foundation-blue-blue-150-main);
                }
              }
            }
          }
        }
      }
      &_bottom {
        &_1 {
          display: flex;
          padding: 20px;
          border-radius: 16px;
          font-size: 14px;
          /* flex-direction: column; */
          justify-content: space-between;

          background-color: var(--main-background-color);
          gap: 20px;

          @media (max-width: 1024px) {
            flex-wrap: wrap;
          }
          &_left,
          &_right {
            flex-direction: column;
            width: 100%;
            display: flex;
            gap: 20px;

            .toggle_input_wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              height: 48px;
              padding: 10px;
              box-sizing: border-box;
              border-radius: 8px;
              border: 1px solid #eaf0f8;
              margin-top: 0px;
              font-family: DM Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              color: black;
              background-color: var(--main-background-color);
              align-items: center;

              @media (max-width: 500px) {
                text-wrap: wrap;
                max-width: 300px;
              }
              @media (max-width: 400px) {
                text-wrap: wrap;
                max-width: 250px;
              }

              p {
                width: 100%;
              }
              .switch_wrapper {
                display: flex;

                align-self: flex-end;
                label {
                  margin-bottom: 0px;
                }
                span {
                  padding: 4px;
                  padding-left: 20px;
                }
                /* The switch - the box around the slider */
                .switch {
                  position: relative;
                  display: inline-block;
                  width: 54px;
                  height: 26px;
                }

                /* Hide default HTML checkbox */
                .switch input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                /* The slider */
                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: #ccc;
                  -webkit-transition: 0.4s;
                  transition: 0.4s;
                }

                .slider:before {
                  position: absolute;
                  content: "";
                  height: 20px;
                  width: 20px;
                  left: 4px;
                  bottom: 3px;
                  background-color: white;
                  transition: 0.4s;
                }

                input:checked + .slider {
                  background-color: #36d66b;
                }

                input:focus + .slider {
                  box-shadow: 0 0 1px #36d66b;
                }

                input:checked + .slider:before {
                  -webkit-transform: translateX(26px);
                  -ms-transform: translateX(26px);
                  transform: translateX(26px);
                }

                /* Rounded sliders */
                .slider.round {
                  border-radius: 34px;
                }

                .slider.round:before {
                  border-radius: 50%;
                }
              }
            }
          }
        }
        &_2 {
          display: flex;
          padding: 20px;
          border-radius: 16px;
          font-size: 14px;
          /* flex-direction: column; */
          justify-content: space-between;

          background-color: var(--main-background-color);
          gap: 20px;
          .plus_minus_input_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            label {
              font-family: DM Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .col2 {
      .preview_wrapper {
        .preview {
          padding: 16px;
          border-radius: 16px;
          margin-top: 20px;
        }
      }
    }
  }
}

.plan_name {
  width: 100%;
  height: 48px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #eaf0f8;
  margin-top: 0px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: black;
  background-color: var(--main-background-color);
  resize: none;
  &:focus {
    outline: var(--foundation-blue-blue-150-main);
  }
}

.label_black {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.preview_wrapper_2 {
  background-color: none !important;
  height: fit-content;
  padding-bottom: 10px;
  .preview {
    padding-top: 20px;
  }
}

.create_plan_btn {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  gap: 10px;
  color: #fff !important;
  border-radius: 16px;
  background: #36d66b;
  box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
  background-color: var(--secondary-color);
  max-width: 200px;
}
input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid gray;
  border-radius: 3px;
  background-color: white;

  input[type="checkbox"] {
    position: relative;
    cursor: pointer;
  }
  input[type="checkbox"]:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #555555;
    border-radius: 3px;
    background-color: var(--secondary-color);
    background: var(--secondary-color);
  }
  input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
    background-color: var(--secondary-color);
    background: var(--secondary-color);
  }
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid var(--secondary-color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

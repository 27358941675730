.accountbalance{
    padding: 16px;
    background-color: var(--main-background-color);
    border-radius: 16px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .accountbalance_middle{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .accountbalance_lower{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    label{
        font-size: 14px;
        font-weight: 500;
    }
}
.support {
  background-color: var(--primary-color);
  border-radius: 16px;
  padding: 20px;
  // .card-container {
  //   color: black;
  // }
  .table_scroll {
    overflow-y: auto;
    margin-top: 0px;
  }
  .sort_buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .react-select__control {
    height: 38px;
    min-width: 115px;
    border-radius: 10px;
    flex: 1;
  }
  .column_header {
    svg {
      display: none;
    }
  }
  .economiccalender-container {
    border-radius: 16px;
    background-color: var(--main-background-color) !important;
    padding: 0px;
  }
}
.economiccalender-container {
  background: #eaf0f8 !important;
  min-height: calc(100vh - 240px) !important;
  padding: 20px 24px;
  .economiccalender-container-wrapper {
    border-radius: 16px;
    background-color: var(--main-background-color);
    padding: 16px;
    padding-bottom: 60px;
    .table-head {
      display: none;
      font-size: 14px;
      padding: 0px 0px 16px 0px;
    }
  }
}

.winners{
    .winners_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4{
            font-size: 18px;
            font-family: "DM Sans", sans-serif !important;
            }
        span{
            font-size: 12px;
            font-weight: 700;
            color: var(--light-gray-color);
        }
    }
    .winners_list{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        padding-top: 10px;
    }
    .winner-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        p{
            font-size: 12px;
            font-weight: 500;
        }
        img {
            border: 2px solid;
            border-radius: 50%;
        }
          .winner-3{
            border-color: var(--orange-color);
          }
      
            .winner-2 {
              border-color: #F9C80E;
            }
      
            .winner-1 {
              border-color: #4CB944;
            }
          
          
    .winner-2-bg{
        background-image: url('../../../assets/images/winner-2-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        width: 91px;
        min-height: 115px ;
        position: relative;
     

    }
    .winner-1-bg{
        background-image: url('../../../assets/images/winner-1-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        width: 91px;
        min-height: 129px ;
        position: relative;
        .winner-bg-content{
           
            h2 , p{
                color: var(--main-background-color);
            }
        }


    }
    .winner-3-bg{
        background-image: url('../../../assets/images/winner-3-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        width: 91px;
        min-height: 105px ;
        position: relative;
        .winner-bg-content{
           
            h2 , p{
                color: var(--main-background-color);
            }
        }

    }
    .winner-bg-content{
        position: absolute;
        bottom: 0;
        padding: 0 10px 10px 10px;
        h2{
            font-size: 26px;
            font-weight: 700;
        }
        p{
            font-size: 10px;
            font-weight: 500;
        }
    }
}
}
@media (max-width: 400px) {
    .winner-1-bg,
    .winner-2-bg,
    .winner-3-bg {
        width: 60px !important;
        border-radius: 8px;
    }
}
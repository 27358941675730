.tabination-container {
  .tabination-subcontainer {
    display: flex;
    flex-direction: row;
    // margin: 0.5em 0;
    overflow-x: auto;
    // margin-right: 40px;
    margin: 0.5em 40px 0 0px;
    li {
      display: flex;
      gap: 0.25em;
      align-items: center;
      margin: auto 1.25em;
      font-size: 16px;
      font-weight: 700;
      color: var(--subtext-color);
      cursor: pointer;

      label,
      input {
        cursor: pointer;
      }

      // input[type="radio"] {
      //   height: 15px;
      //   width: 15px;
      //   appearance: none;
      //   border-radius: 50%;
      //   outline: none;
      //   transition: border-color 0.3s;
      //   background-color: var(--main-background-color);
      //   border: 4px solid #eee;
      //   cursor: pointer;

      //   &:checked {
      //     background-color: #fff;
      //     border: 4px solid var(--secondary-color);
      //   }
      // }
    }

    // .active {
    //   color: var(--text-color);
    //   border-bottom: 2px solid var(--secondary-color);
    // }
    .tabination-toggle {
      color: var(--text-color);
      cursor: pointer;
    }
  }
}

.tabination-subcontainer::-webkit-scrollbar {
  display: none;
}
.tabination-container_variant2 {
  .tabination-subcontainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .tabination_div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .ant-picker {
        flex-wrap: nowrap;
      }
    }

    div {
      display: flex;
      flex-wrap: wrap;

      .tabination-container_toggle {
        display: flex;
        justify-content: center;
        background-color: var(--primary-color);
        border-radius: 50px;
        padding: 0.5em;

        @media (max-width: 500px) {
          flex-wrap: wrap;
        }

        li {
          margin: 0;
          padding: 0.5em 1em;
          font-size: 12px;
          font-weight: 700;
          color: var(--light-gray-color);
          cursor: pointer;
        }

        .tabination-toggle {
          padding: 0.5em 1em;
          color: var(--text-color);
          background-color: var(--main-background-color);
          border-radius: 50px;
          cursor: pointer;
        }
      }

      .tabination-container_chart_toggle {
        display: flex;
        justify-content: center;
        padding: 0 1em;

        li {
          width: 20px;
          display: flex;
          justify-content: center;
          margin: auto 0.75em;
          font-size: 12px;
          font-weight: 700;
          cursor: pointer;
        }

        .chartToggle_active {
          border-bottom: 2px solid var(--secondary-color);
        }
      }
    }

    .tabination-container_toolbar {
      display: flex;
      align-items: center;
      margin: 0 1.5em;

      div {
        align-items: center;
        margin: 0 1em;
        cursor: pointer;
        p {
          color: var(--light-gray-color);
          width: 100%;
          font-size: 12px;
        }
      }
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}

// .variant_3 {
//   margin-top: 20px;
//   .tabination_subcontainer_wrapper {
//     position: fixed;
//     background-color: var(--tab-color);
//     margin-top: -40px;
//     z-index: 1;
//     max-width: 95%;

//     @media (min-width: 500px) {
//       height: 40px;
//     }

//     ul {
//       display: flex;
//       flex-direction: row;
//       margin: 0.5em 0;
//     }
//     li {
//       display: flex;
//       gap: 0.25em;
//       align-items: center;
//       margin: auto 1.25em;
//       padding: 0 0 10px 0;
//       font-size: 16px;
//       font-weight: 700;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
// color: var(--light-gray-color);
//       cursor: pointer;

//       label,
//       input {
//         cursor: pointer;
//       }
//     }

//     .tabination-toggle {
//       color: var(--text-color) !important;
//       background-color: var(--main-background-color);
//       border-bottom: 2px solid var(--secondary-color);
//       cursor: pointer;
//     }

//     // .active {
//     //   color: var(--text-color);
//     //   border-bottom: 2px solid var(--secondary-color);
//     // }
//   }
// }

.variant_3 {
  .tabination_subcontainer_wrapper {
    margin-top: 20px;
    // position: fixed;
    background-color: var(--tab-color);
    // margin-top: -40px;
    // z-index: 1;
    max-width: 95%;
    @media screen and (max-width: 600px) {
      margin-top: 50px;
    }

    // @media (min-width: 500px) {
    //   height: 40px;
    // }

    ul {
      display: flex;
      flex-direction: row;
      margin: 0;
    }
    li {
      display: flex;
      gap: 0.25em;
      align-items: center;
      margin: auto 1.25em;
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--light-gray-color);
      cursor: pointer;

      span {
        display: inline-block;
        padding: 0 0 10px 0;
      }

      label,
      input {
        cursor: pointer;
      }
    }

    .tabination-toggle {
      color: var(--text-color) !important;
      background-color: var(--main-background-color);
      border-bottom: 2px solid var(--secondary-color);
      cursor: pointer;
    }
  }
}

.bg_white {
  background-color: var(--main-background-color);
  border-radius: 16px;
  padding: 16px;
}
.variant_4 {
  .tabination-subcontainer {
    display: flex;
    flex-direction: row;
    margin: 0.5em 0;

    li {
      display: flex;
      gap: 0.25em;
      align-items: center;
      margin: auto 1.25em;
      font-size: 16px;
      font-weight: 700;
      color: var(--light-gray-color);
      cursor: pointer;
      height: 40px;

      label,
      input {
        cursor: pointer;
      }

      input[type="radio"] {
        height: 15px;
        width: 15px;
        appearance: none;
        border-radius: 50%;
        outline: none;
        transition: border-color 0.3s;
        background-color: var(--main-background-color);
        border: 4px solid #eee;
        cursor: pointer;

        &:checked {
          background-color: #fff;
          border: 4px solid var(--secondary-color);
        }
      }
    }

    // .active {
    //   color: var(--text-color);
    //   border-bottom: 2px solid var(--secondary-color);
    // }
  }
}

.tabination-wrapper {
  margin-top: 20px;
}

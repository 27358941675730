.navpanel-container {
  position: relative;
  // padding: 10px 20px;

  .navpanel-subcontainer {
    display: flex;

    .navpanel-container_children {
      .navpanel-children {
        // height: 100%;
      }
      // flex-basis: 100%;
      width: 100%;
      // margin: 5em 0 1em 6em;
      margin: 0 1em 1em 10em;
      overflow: auto;
      // padding: 1.25rem;
      border-radius: 24px;
      // background-color: var(--primary-color);
      min-height: calc(100vh - 160px) !important;
      @media (max-width: 600px) {
        margin: 50px 20px !important ;
        border-radius: 0;
        min-height: calc(100vh - 90px) !important;
      }
      @media (max-width: 700px) {
        // margin: 5rem 0 1em 5em;
      }
    }

    .navpanel-container_children::-webkit-scrollbar {
      width: 0;
    }

    @media (max-width: 746px) {
      .navpanel-container_children {
        // margin: 0em 0 1em 6em;
      }
    }

    .zIndex {
      z-index: -1 !important;
    }

    .NoZIndex {
      z-index: 1 !important;
    }
  }
}

@media (min-width: 1600px) {
  .navpanel-container {
    width: 1420px !important;
    margin: 0em auto 0 auto !important;
  }
  header {
    max-width: 1420px;
    margin: auto;

    .header_right {
      padding-right: 0;
    }
  }
}
@media (min-width: 1780px) {
  .navpanel-container {
    width: 1600px !important;
    margin: 0em auto 0 auto !important;

    .navpanel-container_children {
      // margin: 2.5em 0 1em 6em !important;
    }
  }

  header {
    max-width: 1600px;
    margin: auto;

    .header_right {
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .navpanel-children {
    margin-top: 50px;
  }
}

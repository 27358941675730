.elevator_input {
  display: flex;
  flex-direction: column;
  input {
    all: unset;
  }

  label {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .elevator_input_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #eaf0f8;
    margin-top: 0px;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: black;
    background-color: var(--main-background-color);
    resize: none;
    &:focus {
      outline: var(--foundation-blue-blue-150-main);
    }

    input {
      padding: 0px 20px;
      width: 100%;
    }
    .buttons_wrapper {
      display: flex;

      flex-direction: column;
      padding: 0px 20px;
      img {
        width: 23px;
        height: 23px;
      }
      .arrow_up {
        transform: rotate(180deg);
      }
    }
  }
}

// height: 80%;
.support_wrapper {
  border-radius: 24px;
  background-color: var(--primary-color);
  //   padding: 20px;
  /* height: inherit; */
  /* overflow: auto; */
  /* max-height: 608px; */
  height: 72vh;
  overflow: auto;
}

.gen-tag {
  display: inline-flex;
  align-items: center;
  border-radius: 63px;
  width: fit-content;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;

  span {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
  }
}

.Created {
  background-color: rgba(200, 233, 197, 0.5);
  color: #4cb944;
}

.In.progress {
  background-color: #fdeeb4;
  color: #b18e0a;
}

.Error {
  background-color: #f5bdc0;
  color: #9e1d26;
}

.economiccalender-container {
  width: 100%;
  min-height: 256px;
  background: #fff;
  border-radius: 16px;
  // overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .table_wrapper {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 27px 20px 0 20px;
    gap: 50px;

    .select_wrapper {
      display: flex;
      align-items: center;
      position: relative;
    }

    .calendar_wrapper {
      position: absolute;
      right: -36px;
      margin-top: -30px;
      z-index: 100;
    }

    .search {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 60%;
    }

    .search_bar {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      background-color: #eaf0f8;
      border-radius: 63px;
      width: 90%;

      .search_input {
        flex: 1;
        height: 90%;
        border: none;
        outline: none;
        padding-left: 8px;
        border-radius: 63px;
        font-size: 14px;
        background-color: #eaf0f8;
        color: #0e0f19;

        &::placeholder {
          color: #a4a8ae;
        }

        &:focus {
          outline: none !important;
          border: none !important;
        }
      }

      .search_icon {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 746px) {
    .table_header {
      flex-wrap: wrap;
      gap: 15px;

      .search {
        width: 100%;

        .search_bar {
          width: auto;
        }
      }

      .filter_buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  table {
    height: 100%;
    // margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;

    th {
      color: #909195;
      font-weight: 500;
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
    }

    td {
      font-weight: 700;
      border-bottom: 1px solid #eaf0f8;
    }

    .column_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @media (max-width: 1024px) {

    td,
    th {
      font-size: 12px !important;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }

  .filter_buttons {
    display: flex;

    button {
      background-color: #eaf0f8;
      margin: 0 10px;
      padding: 10px 20px;
      border-radius: 10px;
      color: #a4a8ae;
    }

    .active_btn {
      background-color: #36d66b;
      color: white;
    }
  }

  .name_initial {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9c80e;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
  }

  .column_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .economiccalender-subcontainer {
    .economiccalender-innercontainer {
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 20px;

      .search {
        flex: 1;
      }

      h2 {
        font-size: 14px;
        padding: 16px;
      }
    }

    .economiccalender-container_table {
      width: 100%;

      .economiccalender-table_header {
        display: flex;
        justify-content: space-between;
        margin: 0 1.5em;

        h3 {
          flex-basis: 100%;
          font-family: "Space Grotesk", sans-serif;
          font-size: 20px;
          font-weight: 700;
        }

        div {
          display: flex;
          cursor: pointer;

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
          }
        }
      }

      table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;

        .economiccalender-row {
          border-top: 1px solid #eaf0f8;
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            background-color: rgba(234, 240, 248, 0.8);
            cursor: pointer;
          }
        }

        th,
        td {
          text-align: left;
          font-size: 14px;
        }

        th {
          padding: 1em 1.5em;
          color: #a4a8ae;
          font-weight: 500;
        }

        td {
          vertical-align: middle;
          padding: 1em 1.5em;
          height: 28px;
          font-weight: 700;

          span {
            display: flex;
            align-items: center;

            .economiccalender-flag {
              height: 30px;
              width: 30px;
            }
          }
        }
      }

      .economiccalender-pagination {
        display: flex;
        justify-content: center;

        button {
          margin: 0 1em;
          color: black;
          background: none;
          border: none;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .economiccalender-container_table {
      overflow-x: auto;
    }

    table {
      max-width: 800px;
    }
  }

  @media (max-width: 768px) {
    .economiccalender-table_header {
      flex-direction: column;
      align-items: center;

      div {
        margin-top: 1em;
      }
    }
  }
}

.economiccalender-rowdisplay {
  td {
    background-color: white;

    .economiccalender-container_display {
      margin: auto;
      width: 85%;

      h3 {
        font-family: "DM Sans", sans-serif;
        font-size: 18px;
        font-weight: 700;
      }

      .economiccalender-subcontainer_display {
        display: flex;
        gap: 2em;

        .economiccalender-innercontainer_display-left {
          width: 600px;

          p {
            margin: 1.5em 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 22.96px;
          }

          a {
            color: var(--secondary-color);
            text-decoration: underline;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
          }

          .economiccalender-container_databanner {
            display: flex;
            justify-content: center;
            margin: 1.5em auto;

            div {
              width: 188px;

              h5 {
                color: #8b8e93;
                font-size: 14px;
                font-weight: 500;
                line-height: 19.6px;
              }

              p {
                margin: 0;
                font-family: "Space Grotesk", sans-serif;
                font-size: 26px;
                font-weight: 500;
                line-height: 36.4px;
                color: #4cb944;
              }
            }
          }
        }

        .economiccalender-innercontainer_display-right {
          div {
            margin-bottom: 0.75em;

            h5 {
              color: #8b8e93;
              font-size: 14px;
              font-weight: 500;
              line-height: 19.6px;
            }

            p {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.economiccalender-row-active {
  background: #eaf0f880;
}

.importance-star {
  margin-right: 0.3em;
}

.td-main-div {
  display: flex;
  flex-direction: column;
}

.selected-row-header {
  display: flex;
  justify-content: space-between;

  p {
    color: var(--secondary-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;
    width: 12%;
    text-align: left;
  }
}

.create-case {
  margin: 16px 20px 0 0;
  cursor: pointer;
  height: 20px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.supportcases-type-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .supportcases-box {
    &:last-child {
      grid-column: span 3 !important;

    }
  }
}

.support-case-id {
  padding: 1rem;
  background-origin: padding-box;
  border-radius: 16px;
  border: 1px solid #eaf0f8;
}

.supportcases-box {
  display: flex;
  flex-direction: column;
  // width: 13.5%;
  flex: 1;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #eaf0f8;
  align-items: flex-start;
  gap: 8px;

  &.dates {
    flex-direction: row;
    justify-content: space-between;
    width: 27%;

    .report-dates {
      display: flex;
      flex-direction: column;
      gap: 24px;

      p {
        color: #a4a8ae;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      h4 {
        color: #0e0f19;
        font-weight: 700;
        font-family: "DM Sans";
        margin-top: 3px;
      }
    }
  }
}

.supportcases-box>p {
  color: #8b8e93;
  font-weight: 500;
  width: 100%;
}

.supportcases-box>h4 {
  font-size: 26px;
  margin-bottom: 7px;
  width: 100%;
}

.supportcases-box>img {
  max-height: 35px;
  width: 100%;
}

.red {
  color: #df2935;
}

.green {
  color: #4cb944;
}

.inline-tag {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  gap: 6px;
}

.success-ratio {
  display: inline-flex;
  align-items: center;
  color: #a4a8ae;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f75c03;
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}

.supportcases-comments {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.supportcases-comments>p {
  color: #a4a8ae;
  font-weight: 500;
  margin-bottom: 20px;
}

.supportcases-comments>h4 {
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  margin-bottom: 42px;
}

.supportcases-comments>a {
  color: #36d66b;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
}

.supportcases-category {
  display: flex;
  gap: 40px;
}

.category-details>p {
  color: #a4a8ae;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
}

.category-details>h5 {
  font-size: 14px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}

.progress-label {
  padding: 10px 16px;
  border-radius: 4px;
  background: #fdeeb4;
  color: #b18e0a;
  font-size: 12px !important;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.pass-label {
  padding: 10px 16px;
  border-radius: 4px;
  background: rgba(200, 233, 197, 0.5);
  color: #4cb944;
  font-size: 12px !important;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.status-container {
  display: inline-flex;
  // padding: 8px 16px;
  border-radius: 4px;
  align-items: flex-start;
  gap: 10px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.sort.up {
  .column_header {
    svg {
      transition: transform 0.3s ease-in-out;
      transform: rotate(-180deg);
    }
  }
}

.sort.down {
  .column_header {
    svg {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0deg);
    }
  }
}

.economiccalender-pagination {
  .btn-active {
    color: var(--text-link-color);
  }

  .non-active {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: #a4a8ae !important;
  }

  .disabled {
    color: var(--light-gray) !important;
    cursor: default;
  }
}
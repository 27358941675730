.create_coupon {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  background: var(--primary-color);
  border-radius: 24px;
  gap: 20px;

  label {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &_top {
    background-color: var(--main-background-color);
    width: 70%;
    border-radius: 16px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .coupon_wrapper {
      display: flex;
      padding: 20px;
      border-radius: 16px;
      font-size: 14px;
      gap: 20px;
      flex-direction: column;

      .coupon_input_wrapper {
        display: flex;
        flex-direction: column;

        .coupon_code_wrapper {
          position: relative;

          .coupon_code {
            font-weight: 700;
          }

          .copy_button {
            position: absolute;
            right: 4px;
            top: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            width: 40px;
            height: 40px;
            background-color: var(--secondary-color);
            transition: background-color 0.2s ease-in-out;

            &:active {
              background-color: #1466d1;
            }
          }
        }

        input {
          width: 100%;
          height: 48px !important;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          resize: none;

          &:focus {
            outline: var(--foundation-blue-blue-150-main);
          }
        }

        .note_wrapper {
          width: 80%;
          align-self: flex-start;
          margin-left: 20px;

          p {
            font-family: DM Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            color: var(--text-grey);
          }

          h3 {
            color: #df2935;
            font-family: Space Grotesk;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            /* 22.4px */
            margin-top: 10px;
          }
        }
      }

      .back_button_wrapper {
        button {
          margin: 0 1em;
          background: none;
          border: none;
          font-size: 16px;
          color: var(--text-grey);
        }
      }
    }
  }

  &_bottom {
    width: 70%;
    background-color: var(--main-background-color);
    border-radius: 16px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .coupon_opts_wrapper {
      padding: 20px;

      .upper {
        display: flex;
        border-radius: 16px;
        font-size: 14px;
        flex-direction: column;

        input:not(.Slider) {
          width: 100%;
          height: 48px;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          resize: none;

          &:focus {
            outline: var(--foundation-blue-blue-150-main);
          }
        }
      }

      .middle {
        display: flex;
        align-items: end;
        margin: 0;
        padding: 0;
        justify-content: center;
        gap: 10px;

        @media (max-width: 768px) {
          flex-wrap: wrap;
        }

        .wrapper {
          display: flex;
          width: 100%;
          flex-direction: column;
        }

        input {
          width: 100%;
          height: 48px;
          padding: 0px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          resize: none;

          &:focus {
            outline: var(--foundation-blue-blue-150-main);
          }
        }

        .coupon_value {
          input {
            all: unset;
          }

          .input_wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 48px;
            /* padding: 20px; */
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #eaf0f8;
            margin-top: 0px;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: black;
            background-color: var(--main-background-color);
            resize: none;

            input {
              all: unset;
              text-align: center;
            }

            img {
              width: 20px;
              margin: 0px 20px;
            }

            &:focus {
              outline: var(--foundation-blue-blue-150-main);
            }
          }
        }
      }
      .date_wrapper {
        display: flex;
        gap: 10px;
        align-items: center;

        @media (max-width: 768px) {
          flex-wrap: wrap;
        }

        button {
          width: 100%;
          height: 48px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-size: 14px;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .date-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 0px;
            width: 100%;
            gap: 0px;
            padding: 20px;
            img {
              // right: -20px;
              margin-right: 20px;
              position: relative;
              pointer-events: none;
              scale: 1.2;
            }
          }
        }
      }

      .lower {
        display: flex;
        border-radius: 16px;
        font-size: 14px;
        flex-direction: column;

        .date_wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          @media (max-width: 768px) {
            flex-wrap: wrap;
          }

          button {
            width: 100%;
            height: 48px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #eaf0f8;
            margin-top: 0px;
            font-size: 14px;
            font-weight: 500;
            color: black;
            background-color: var(--main-background-color);
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-left: 20px;
              width: 100%;
            }
          }
        }

        .toggle_switches_wrapper {
          display: flex;
          align-items: center;
          gap: 26px;
          justify-content: unset;

          .switch_wrapper {
            display: flex;
            width: 100%;

            label {
              margin-bottom: 0px;
            }

            span {
              padding: 4px;
              padding-left: 20px;
            }

            /* The switch - the box around the slider */
            .switch {
              position: relative;
              display: inline-block;
              width: 54px;
              height: 26px;
            }

            /* Hide default HTML checkbox */
            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            /* The slider */
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              left: 4px;
              bottom: 3px;
              background-color: white;
              transition: 0.4s;
            }

            input:checked + .slider {
              background-color: #36d66b;
            }

            input:focus + .slider {
              box-shadow: 0 0 1px #36d66b;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }

            /* Rounded sliders */
            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  &_button {
    width: 70%;

    button {
      display: flex;
      width: 40%;
      height: 67px;

      padding: 20px 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      flex-shrink: 0;
      border-radius: 16px;
      background: #36d66b;
      box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
    }
  }
}

.input_date {
  border: none;
  outline: none;
  box-shadow: none !important;
  flex-direction: row-reverse;
  gap: 10px;
}

// input[type="date"]::-webkit-calendar-picker-indicator {
//   display: none;
// }

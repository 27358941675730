.create_trading_account {
  padding: 16px;
  background-color: var(--main-background-color);
  border-radius: 16px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .view_details_btn {
    // margin: 0 auto;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    gap: 10px;
    color: #fff !important;
    border-radius: 16px;
    background: #36d66b;
    box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
    background-color: var(--secondary-color);
    max-width: 200px;
    font-size: 18px;
    font-weight: 700;
    width: 300px;
    border-radius: 16px;
    box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
    background-color: var(--secondary-color);
  }
  .create_header {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 16px;
      font-weight: 600;
      text-wrap: nowrap;
    }
  }
  .create_trading_account_wrapper {
    .input_form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .input_wrapper {
        flex: 1;
        label {
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;

          margin-bottom: 10px;
          color: #000;
        }

        input {
          width: 100%;
          height: 48px;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-family: "DM Sans", sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          resize: none;
        }
      }
      .input_form-top {
        display: flex;
        gap: 10px;
      }
      .input_form-mid {
        display: flex;
      }
      .input_form-btm {
        display: flex;
        gap: 20px;
        @media screen and (max-width: 500px) {
          flex-direction: column;
        }
      }
    }
  }
}

.profiledetails-container {
  h3 {
    padding: 0 1em 1em 1em;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
  .profiledetails-subcontainer {
    display: flex;
    gap: 1em;

    .profiledetails-container_left {
      display: flex;
      flex-direction: column;
      flex: 70%;
      gap: 1em;
    }
    .profiledetails-container_right {
      flex: 30%;
      display: flex;
      flex-direction: column;
      gap: 1em;

      .profiledetails-container_infobox {
        display: flex;
        gap: 1em;
      }
    }
  }
}

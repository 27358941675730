.ad_list {
  color: #000;
  border-radius: 24px;
  background-color: var(--primary-color);
  padding: 20px 20px;
  overflow: auto;

  &_wrapper {
    height: 85vh;
    & ::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
}

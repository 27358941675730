.success_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .success_modal_container {
    background-color: #ffffff;
    width: 300px;
    height: 377px;
    border-radius: 16px;    flex-direction: column;
    display: flex;
  }
  .success_modal_container_top {
    height: 50%;
    background-image: url("../../../assets/images//success-modal.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    .tick_icon {
      position: absolute;
      bottom: 0;
      left: 40%;
    }
  }
  .success_modal_container_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    flex: 1;
    p {
      color: var(--light-gray-color);
      font-size: 16px;
      font-weight: 500;
      padding: 0 25px;
    }
    .success_modal_close_btn {
      background-color: #4cb944 !important;
      width: 80% !important;
      border-radius: 8px;
    }
  }
}

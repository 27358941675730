.account_password_input_container{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;

    label{
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: #8B8E93;
      }

      input:focus{
        border: 1px solid #36d66b;
    }

.input_container {
    position: relative;
  }
  
  .account_password_input {
    width: 100%;
    height: 48px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #EAF0F8;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .eye_icon {
    position: absolute;
    top: 60%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

}

@media (max-width: 768px) {
  .account_password_input_container{
    flex-wrap: wrap;
   }
   }
  
  
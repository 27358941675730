.payout {
  padding: 22px 20px;

  .grid_container {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 20px;
    box-sizing: border-box;
    padding-right: 20px;
  }

  .col1,
  .col2 {
    background: var(--main-background-color);
    width: 100%;
    border-radius: 16px;
    height: 281px;
  }

  .col1 {
    background-image: url('../../../assets/images/payout-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 40px;

    .col1_content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h4 {
        font-size: 20px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .col1_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;

      button {
        width: 400px;
        padding: 0;
        height: 48px;
        border-radius: 62px;
      }
    }
  }

  .col2 {
    height: 281px;
    border-radius: 16px;
    background-image: url('../../../assets/images/carousel-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .col2_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        position: absolute;
        bottom: 65px;
        right: 35px;
        color: #fff;
      }
    }

    button {
      background-color: #fff;
      color: #36d66b;
      width: 236px;
      height: 48px;
      border-radius: 8px;
      bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .payout_lower {
    background-color: #fff;
    border-radius: 16px;
  }

  .table_wrapper {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    .table_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 40px;

      .select_wrapper {
        display: flex;
        align-items: center;

        .select_icon {
          margin-top: 4px;
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }

    table {
      width: 100%;
      height: 100%;
      margin-top: 1.5rem;
      border-collapse: collapse;

      th {
        color: #909195;
        font-weight: 500;
      }

      tr {
        height: 64px;
      }

      th,
      td {
        text-align: left;
        font-size: 14px;
      }

      td {
        font-weight: 500;
        border-bottom: 1px solid #eaf0f8;
      }
    }

    .view_details_btn {
      background: #ecf4fe;
      height: 28px;
      width: 104px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding: 0;
      border-radius: 4px;
      color: #4692f5;

      &:hover {
        background-color: #4692f5;
        color: #fff;
      }
    }

    .column_one_wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      .sno_wrapper {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4cb944;
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    .grid_container {
      grid-template-columns: 1fr !important;
    }

    .col1,
    .col2 {
      height: fit-content;
    }

    .col1_btn button {
      width: 100%;
    }

    .payout_lower .table_wrapper {
      overflow-x: auto;
    }

    table {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .grid_container {
      grid-template-columns: 1fr;
    }

    .col1,
    .col2 {
      max-width: 100%;
      height: auto;
    }

    .col1_content {
      padding: 20px;
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }

    .col2_content h3 {
      font-size: 16px;
      bottom: 10px;
      right: 10px;
    }

    button {
      width: 100%;
      max-width: 180px;
      height: 40px;
      font-size: 14px;
    }
  }
}

.progress_tag {
  background-color: #fdeeb4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #b18e0a;
  border-radius: 4px;
}

.error_tag {
  background-color: #f5bdc0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #9e1d26;
  border-radius: 4px;
}

.column_header {
  display: flex;
  align-items: center;
}

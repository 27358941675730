.alltable {
  padding: 16px;
  background-color: var(--main-background-color);
  border-radius: 16px;

  .alltable_header {
    padding: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: 800px) {
      flex-direction: column !important;
    }
    .sort_buttons {
      justify-content: end;
      display: flex;
      width: 100%;
      gap: 10px;
      flex: 1;
      align-items: flex-end;
      .react-select__control {
        height: 100%;
        min-width: 115px;
        border-radius: 10px;
        flex: 1;
      }
      @media (max-width: 640px) {
        flex-wrap: wrap;
      }
      button {
        border: 1px solid var(--secondary-color);
        color: var(--secondary-color);
        border-radius: 10px;
        padding: 10px;
        flex: 1 1;
        margin: 0px;
        &.activeBtn {
          background-color: var(--secondary-color);
          color: #fff;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
    }
    .search_bar {
      // flex: 1;
      // visibility: hidden;
      // pointer-events: none;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      height: 40px;
      // width: 330px;
      background-color: #eaf0f8;
      border-radius: 63px;
      // margin-right: 0px !important;
      @media (max-width: 768px) {
        width: 100% !important;
      }
      .search_input {
        flex: 1;
        height: 90%;
        width: 100%;
        border: none;
        outline: none;
        padding-left: 8px;
        border-radius: 63px;
        font-size: 14px;
        background-color: #eaf0f8;

        &::placeholder {
          color: #a4a8ae;
        }
      }

      .search_icon {
        margin-left: 10px;
      }
    }

    .select_wrapper {
      display: flex;
      align-items: center;
      .select_icon {
        margin-top: 4px;
      }
    }
  }
  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    @media (max-width: 768px) {
    }
  }

  table {
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    .upgrade_button {
      background: var(--secondary-color);
      padding: 6px 10px;
      color: #fff;
      border-radius: 6px;
      text-wrap: nowrap;
    }

    th {
      color: #909195;
      font-weight: 500;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }

    tr {
      height: auto;
      &:hover {
        background-color: #eaf0f8;
      }
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
      padding: 10px;

      a {
        display: flex;
        height: 100% !important;
        text-align: center;
        // justify-content: center;
        align-items: center;
        width: 100% !important;
      }
    }

    td {
      font-weight: 500;
      border-bottom: 1px solid #eaf0f8;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
    .column_data {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .progress_tag {
    background-color: #fdeeb4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 500;
    color: #b18e0a;
    border-radius: 4px;
  }

  .column_header {
    display: flex;
    align-items: center;
  }
  .pagination {
    display: flex;
    justify-content: center;

    button {
      margin: 0 1em;
      color: black;
      background: none;
      border: none;
      font-size: 12px;
    }
  }
}

.search_container {
  max-width: 340px !important;
  background-color: #eaf0f8;
  border-radius: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  height: 40px;
  overflow: hidden !important;
  box-shadow: 0px 0px 2px 0px #1154ac;
}

.search_container input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  button {
    height: 40px !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    .search_icon_container {
      width: 30px !important;
      height: 30px !important;
      padding: 10px 10px 0 0 !important;
      background-color: red !important;
    }
  }
}

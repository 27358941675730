.createemail_tabs{


.email{
    display: grid;
    grid-template-columns: 68% 28%;
    grid-gap: 20px;
    overflow-y: auto;
    max-height: 80vh;
    @media (max-width: 1000px) {
          grid-template-columns: 100% !important; 
    }
    @media (max-width: 1200px) {
        grid-template-columns: 60% 36% ;
  }
}
}
// .profiledropdown {
.dropdown {
  position: absolute;
  // height: 450px;
  transform: translate(0, 10px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 6px#b0c6e3;
  width: auto;
  font-family: "DM Sans", sans-serif;
  color: #0e0f19;
  z-index: 1;
  .dropdown_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
  }
}

.dropdown_profile {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-width: 300px;
  right: 50px;
  .info_box_container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 10px;
  }
  .dropdown_profile_content {
    padding: 10px 0;
    width: 100%;
  }
  .profile_details {
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: start;
    width: 100%;
    padding-top: 20px;

    .profile_details_content {
      h4 {
        padding: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
        padding-left: 0px;
        color: #3e3f47;
      }
    }
    .profile_img_wrapper {
      display: flex;
    }
    .profile_img_wrapper img {
      width: 60px;
    }

    .edit_img_wrapper {
      width: 20px;
      height: 20px;
    }
  }

  .accounts_type_wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    ul {
      font-weight: 600;
      font-size: 12px;
      padding-top: 20px;
      :first-child {
        font-weight: 700;
        font-size: 14px;
      }
      li {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        color: #0e0f19;
      }
      .blue_tick_icon {
        margin-left: 10px;
      }
    }
  }
  .dropdown_footer {
    //  position: absolute;
    //  bottom: 0;
    //  padding-bottom: 1.25rem;
    width: 100%;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      color: #a4a8ae;
      font-size: 14px;
      font-weight: 500;
    }
    .value {
      font-size: 14px;
      font-weight: 700;
    }
  }
}
.dropdown_profile::before {
  position: absolute;
  z-index: -1;
  content: "";
  right: 30px;
  top: 19px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
  // box-shadow: 0 0px 6px #b0c6e3 ;
}
// }

.dropdown {
  position: absolute;
  // height: 450px;
  transform: translate(0, 10px);

  border-radius: 16px;
  border: 1px solid var(--secondary-color);
  background-color: var(--main-background-color);
  // box-shadow: 0px 0px 2px #b0c6e3;
  width: auto;
  font-family: "DM Sans", sans-serif;
  color: var(--text-color);

  .dropdown_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    width: 90%;
    padding: 10px ;
  }
}

.dropdown_profile::before {
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 1px solid #b0c6e3;
  border-right: 0px solid #b0c6e3;
  border-bottom: 0px solid #b0c6e3;
  border-left: 1px solid #b0c6e3;
  top: 19px;
  right: 38px;
  margin-left: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: var(--background-color);
}

.dropdown_profile {
  min-width: 335px;
  right: 60px;
  // border: 1px solid #b0c6e3;
  z-index: 999 !important;

  .dropdown_upper_wrapper {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    // border-bottom: 1px solid #242529;
  }
  .info_box_container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding:  0px;
    // border-bottom: 1px solid #242529;
  }

  .dropdown_profile_content {
    padding: 10px 0;
    width: 100%;
  }

  .profile_details {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: start;
    // width: 100%;
    padding: 0px;
    .profile_div {
      display: flex;
     gap: 10px;
    }

    .profile_details_content {
      h4 {
        padding: 0;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 24px;
        font-family: "DM Sans", sans-serif;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
        // padding-left: 1em;
        color: #3e3f47;
      }
    }

    .profile_img_wrapper img {
      width: 60px;
    }

    .edit_img_wrapper {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }

  .accounts_type_wrapper {
    display: flex;
    // gap: 20%;
    justify-content: space-between;
    width: 100%;

    overflow-y: scroll;
    max-height: 25vh;
    scrollbar-width: none;
    box-sizing: border-box;
    border-radius: 16px;
    // border: 1px solid var(--Border, #242529);
    // background: var(--neutral-n-grey-250-b-gbox, #111512);
    padding: 0px;
    color: var();

    ul {
      font-weight: 600;
      font-size: 12px;
      // padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      font-weight: 500;
      padding: 20px;
      :first-child {
        font-weight: 700;
        font-size: 14px;
      }

      li {
        // margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--text-color);
        cursor: pointer;
      }

      .blue_tick_icon {
        // margin-left: 10px;
      }
    }
  }
  .imgActive {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  .dropdown_footer {
    display: flex;
    gap: 40px;
    // padding: 50px;
    justify-content: space-between;
    .first_box_1 {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: space-between;
      .mainDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .title {
      color: var(--neutral-n-grey-250-outline, #a5b5bf);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
    }

    .value {
      color: var(--secondary-color);
      text-align: center;
      // font-family: TT Norms;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
    }
  }
}

.active_profile {
  border: 1px solid var(--secondary-color);
  border-radius: 100%;
  width: 40px;
  width: calc(40px + 2px); /* Adjust width to account for border */
  height: calc(40px + 2px); /* Adjust height to account for border */
}
.dropdown_bell::before {
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 1px solid #b0c6e3;
  border-right: 0px solid #b0c6e3;
  border-bottom: 0px solid #b0c6e3;
  border-left: 1px solid #b0c6e3;
  top: 19px;
  right: calc(18.5%);
  margin-left: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: var(--background-color);
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown_bell {
  width: 509px;
  right: 135px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: 1px solid #b0c6e3;

  .dropdown_header {
    padding: 1rem 1.5rem;
    width: 90%;
  }

  .notifications_tabs {
    width: 100%;

    border-bottom: 1px solid var(--primary-color);
    height: 50px;
    margin-left: -20px;

    div {
      color: #a4a8ae;
      display: inline-block;
      height: 40px;
      padding: 0 2px;
      margin-left: 1.5rem;
    }

    .active {
      font-weight: 700;
      color: var(--text-color);
      border-bottom: 2px solid #36d66b;
    }
  }

  .notifications_wrapper {
    padding: 1.5rem;
    max-height: 60vh;
    overflow-y: auto;
  }

  .notification {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 1rem 0;

    .notification_icon {
      margin-top: 6px;
    }

    .notification_content_upper {
      padding-bottom: 10px;
      max-width: 80%;

      span {
        font-weight: 800;
      }
    }
    .date {
      font-size: 12px;
      font-weight: 500;
    }
    .time {
      font-size: 12px;
      font-weight: 500;
    }
    .notification_content_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 90%;
      color: #a4a8ae;
    }
  }

  .dropdown_footer {
    // position: absolute;
    //  bottom: 0;
    padding: 0 1.25rem 1.25rem 1.25rem;
    width: 90%;

    .dropdown_footer_bell {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dropdown_footer_left {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 12px;
        font-weight: 700;
        color: #36d66b;
      }

      .dropdown_footer_right {
        .view_all_btn {
          height: 28px;
          padding: 16px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

.overview{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .overview_top{
        display: grid;
        grid-template-columns: 60% 40%;
        // padding-right: 20px;
        grid-gap: 10px;
        
        @media (max-width: 1200px) {
            display: grid;
            grid-template-columns: 50% 50%;
        }

        @media (max-width: 1330px) {
            display: grid;
            grid-template-columns: 100%;
        }

        div{
            border-radius: 16px;
        } 
    }
    .overview_infobox{
        display: grid;
        grid-template-columns: repeat(4 , 1fr);
        grid-gap: 20px;
        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: repeat(2 , 1fr);       
         }

    }
    .overview_bottom{
        min-height: 430px;
        background-color: var(--background-color);
        border-radius: 16px;
        padding: 20px;
        > div {
        border-radius: 16px;
        }
        .overview_bottom_header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            font-size: 16px;
            >.overview_bottom_header_tabs{
                @media (min-width: 1300px) {
                    min-width: 850px;
                 }
            }
        }
    }
}
.stagestats {
    border-radius: 16px;
    .chart_wrapper {
      max-height: 350px;
      padding-bottom: 20px;
  
      @media only screen and (max-width: 1000px) {
        overflow: auto;
        .chartBox {
          min-width: 100vw;
        }
      }
    }
    .chart_header {
      float: right;
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .apexcharts-canvas .apexcharts-element-hidden,
    .apexcharts-datalabel.apexcharts-element-hidden,
    .apexcharts-hide .apexcharts-series-points {
      @media only screen and (max-width: 800px) {
        opacity: 1 !important;
      }
    }
    .apexcharts-toolbar {
      gap: 5px;
      margin-top: 2px;
    }
    .apexcharts-element-hidden {
      display: flex;
      svg {
        width: 19px !important;
        height: 19px !important;
        fill: #6e8192 !important;
      }
    }
  }
  .stagestats_wrapper {
    margin: 16px;
  }
  .chart_title {
    font-size: 14px;
  }
  .stagestats {
    .stagestats_wrapper {
      .chart_wrapper {
        .chartBox {
          .apexcharts-toolbar {
            z-index: 0 !important;
          }
        }
      }
    }
  }
  
  .dark-theme {
    .stagestats {
      background-color: transparent !important;
      .stagestats_wrapper {
        .apexcharts-xaxis-title,
        .apexcharts-yaxis-title {
          .apexcharts-text {
            fill: #fff !important;
          }
        }
      }
    }
  }
  
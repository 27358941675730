.accountoverview-container {
  display: grid;
  grid-template-columns: 67% 31%;
  gap: 1em;

  .infobox_wrapper {
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    display: flex;
    gap: 15px;
  }

  .col1,
  .col2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .col2 {
    .dropdown {
      // transform: translateY(100px);
      right: -35px;
      top: 15px;
      scale: 0.9;
      @media (max-width: 850px) {
        right: -45px;
      }
      @media (max-width: 465px) {
        scale: 0.7;
        right: -80px;
        top: -55px;
      }
    }
  }

  .accountoverview-gain {
    color: #4cb944;
  }
  .accountoverview-loss {
    color: #df2935;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .infobox_wrapper {
      grid-template-columns: repeat(2, 1fr);
    }

    .col1,
    .col2 {
      gap: 10px;
    }
  }
}

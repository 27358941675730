@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Inter&family=Space+Grotesk&family=The+Nautigal&display=swap");

.statistic-container {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 7;
  background: var(--background-color);
  border-radius: 16px;
  // color: white !important;
  .profit {
    color: var(--secondary-color);

  }
  .loss {
    color: var(--text-error-color);
  }

  .statistic-subcontainer {
    padding: 0.65em;
    .average_profit {
      color: var(--highlight-color, #80e0c4);
    }

    .average_loss_active {
      color: var(--loss-color, #f25f5c);
    }
    h4 {
      margin: 0 0 1em 0;
    }

    div {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 1em;

      div {
        display: flex;
        flex-direction: column;
        row-gap: 0;
        font-size: 14px;
        line-height: 19.6px;

        h5 {
          color: var(--subtext-color);
          font-weight: 500;
          font-family: "Inter";
          font-size: 12px;
        }

        p {
          font-weight: 500;
          font-family: "Inter";
        }
      }
    }
  }

  .statistic-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0;
    gap: 20px;
    border-top: 1px solid var(--primary-color);
    // background: var(
    //   --Gradietn,
    //   linear-gradient(
    //     90deg,
    //     rgba(17, 21, 18, 0.2) 8.26%,
    //     rgba(128, 224, 196, 0.2) 44.09%,
    //     rgba(17, 21, 18, 0.2) 89.17%
    //   )
    // );
    p {
      margin: 0;
      color: var(--light-gray-color);
      font-size: 14px;
      font-family: "Inter";

      span {
        color: var(--text-color);
        font-weight: 700;
        font-family: "Inter";
        color: var(--secondary-color);
      }
    }
    .last_update {
      // color: #fff;
    }
  }

  @media (max-width: 768px) {
    .statistic-subcontainer {
      div {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    order: 1px solid;
    // margin: 1rem;
    // background: #111512;
    padding: 22px;
    border-radius: 20px;
  }

  @media (max-width: 500px) {
    .statistic-subcontainer {
      div {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

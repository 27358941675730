.competitionList_header {
  display: flex;
  //   justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  height: 42px;
  .search_bar {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    height: 40px;
    width: 330px;
    background-color: #fff;
    border-radius: 63px;
    // margin-right: 20px;
    position: relative;

    svg {
      position: absolute;
      left: 0;
    }
    &:hover {
      box-shadow: 0px 0px 2px 0px #1154ac;
    }

    .search_input {
      flex: 1;
      height: 90%;
      border: none;
      outline: none;
      padding-left: 40px;
      border-radius: 63px;
      font-size: 14px;
      background-color: #fff;
      font-family: "DM Sans", sans-serif;
      color: black;
      &::placeholder {
        color: #a4a8ae;
      }
    }

    .search_icon {
      margin-left: 10px;
    }
  }

  .select_wrapper {
    display: flex;
    align-items: center;
    .select_icon {
      //   margin-top: 4px;
    }
  }
}

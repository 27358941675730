.cf {
  background-color: var(--main-background-color);
  // height: 90px;
  border-radius: 12px;
  // box-shadow: 0px 0px 2px 0px #1154ac;
  display: flex;
  flex-direction: column;
  // margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #1154ac00;
  transition: all 0.2s ease;
  // padding: 0px 16px;
  &.active {
    border: 1px solid #1154ac;
    // box-shadow: 0px 0px 2px 0px #1154ac;
  }
  // border: 1px solid #1154ac;
  .cf_upper {
    border-bottom: 1px solid #eaf0f8;
    font-size: 16px;
    font-weight: 600;
  }

  .cf_lower {
    color: #a4a8ae;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
  }
}


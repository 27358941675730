.plans_table {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 16px;
  width: 100%;
  text-align: left;
  height: 100%;
  margin-top: 1.5rem;
  border-collapse: collapse;
  width: 100%;
  padding-left: 20px;

  th {
    color: #909195;
    font-weight: 500;
    position: relative;
  }

  tr {
    height: 64px;
  }

  th,
  td {
    text-align: left;
    font-size: 14px;
    padding-left: 10px;
  }

  td {
    font-weight: 500;
    border-bottom: 1px solid var(--primary-color);
    white-space: nowrap;

    @media (max-width: 768px) {
      padding-right: 20px;
    }
  }
}

.table_scroll_wrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: 0px;
}

.table_scroll {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  @media (max-width: 768px) {
    max-width: 500px;
  }
}

.plan_input {
  width: 100%;
  height: 48px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #eaf0f8;
  margin-top: 0px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: black;
  background-color: var(--main-background-color);
  resize: none;
  &:focus {
    outline: var(--foundation-blue-blue-150-main);
  }
}

.modal_inputs {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal {
  .modal_content {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    position: relative;
  }
  .modal_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.symboldata{
    padding: 16px;
    .chart_title{
        font-size: 14px;
        font-weight: 500;

    }
    .chart_wrapper{
        height: 100%;
        min-height: 200px;
    }

}
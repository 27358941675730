.competition_details_box {
  min-width: 294px;
  max-width: 301px;
  flex: 1;
  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  

  &:hover {
    & .clock_wrapper {
      h4 {
        color: white;
      }
      h5 {
        color: white;
      }
    }
    & .grey_text {
      color: white;
    }
    box-shadow: 0px 0px 2px 0px #1154ac;
    .competition_details_box_lower {
      background-color: var(--secondary-color);
      color: #fff;

      .progress_bar {
        background-color: #1154ac;

        .progress {
          background-color: #f9c80e;
        }
      }
    }
  }

  &_upper {
    border-bottom: 1px solid #eaf0f8;

    &_wrapper {
      padding: 20px;
      height: 100%;

      .bg_img_container {
        background-size: cover;
        border-radius: 8px;
        height: 190px;
        background-repeat: no-repeat;
        position: relative;

        &_header {
          cursor: pointer;
          position: absolute;
          top: 5px;
          right: 5px;
          display: flex;
          width: 30px;
          height: 30px;
          /* padding: 3px 4px 4px 3px; */
          justify-content: center;
          align-items: center;
          border-radius: 91px;
          background: var(--foundation-blue-blue-150-main, #36d66b);
          flex-shrink: 0;
          .menu {
            position: absolute;
          }
        }

        &_btn {
          position: absolute;
          bottom: 20px;
          left: 20px;

          button {
            padding: 0;
            height: 34px;
            width: 218px;
            border-radius: 8px;
            font-size: 14px;
            font-family: 700;
          }
        }

        .sidemenu {
          padding: 0px;
          display: flex;
          align-items: center;
          width: max-content;
          height: max-content;
          left: -8.5rem;
          top: 6rem;
          z-index: 99;
          img {
            width: 16px;
            height: 16px;
          }
          &::before {
            content: "";
            position: absolute;
            top: -6%;
            right: 20%;
            margin-top: -5px;
            rotate: 90deg;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #ffffff;
          }

          &_list_container {
            font-size: 16px;
            font-weight: 500;

            .sidemenu_list {
              padding-left: 0px;
              display: flex;
              flex-direction: column;
              .option_container {
                display: flex;
                align-items: center;
                gap: 10px;
                height: 35px;
                width: 130px;
                padding: 5px;
                padding-left: 20px;

                padding-right: 0px;
                margin-right: 0px;
                border-bottom: 1px solid var(--text-grey);
                h5 {
                  font-family: "DM Sans";
                  font-weight: 500;
                }
                svg path {
                  fill: rgb(0, 0, 0);
                }
                &:hover {
                  h5 {
                    color: var(--secondary-color);
                  }
                  svg path {
                    fill: var(--secondary-color);
                  }
                }
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }

    .competiton_details_container {
      padding-top: 10px;

      h2 {
        font-size: 18px;
        font-weight: 700;
      }

      div {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding-top: 20px;
        span {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &_middle {
    height: 144px;
    &_wrapper {
      padding: 10px 20px 0 20px;
      
      .winner_details_container {
        min-height: 110px;
        p {
          color: #a4a8ae;
          font-size: 12px;
          font-weight: 700;
        }
        ul {
          font-size: 14px !important;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 0px 14px;
          li {
            display: flex;
            align-items: center;
            img {
              margin-right: 12px;
              height: 20px;
              width: 20px;
            }
            &:first-child {
              span {
                color: #4cb944;
              }
            }
            &:nth-child(2) {
              span {
                color: #36d66b;
              }
            }
            &:last-child {
              span {
                color: #f75c03;
              }
            }
          }
        }
      }
    }
  }

  &_lower {
    flex-grow: 1;
    padding: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    &_wrapper {
      padding: 16px 20px;
    }

    .clock_wrapper {
      display: flex;
      justify-content: space-between;
    }

    .time_container {
      .colon {
        margin-bottom: 20px;
        font-size: large;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      h4 {
        font-size: 18px;
        font-weight: 700;
      }
      h5 {
        font-family: "DM Sans";
      }
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .progress_bar {
      width: 100%;
      background-color: #eaf0f8;
      height: 6px;
      border-radius: 10px;
      overflow: hidden;
      margin: 4px 0px;
    }

    .progress {
      width: 50%;
      height: 100%;
      background-color: #f9c80e;
    }

    .contestants_details_container {
      padding-top: 7px;
      font-size: 10px;
      font-weight: 500;
      color: var(--text-grey);
      p {
        color: var(--text-grey);
      }
      .contestants_participated {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    a {
      all: unset;
      display: flex;
    }
  .view_details_btn {
    color: #FFF;
    background: var(--secondary-color);
    height: 28px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    border-radius: 6px;
    margin: 0px;
    margin-top: 10px;
    border: 1px solid #FFF;
   

    &:hover {
      background-color: #FFF;
      color: var(--secondary-color);
    }
    
  }
  }

  @media (max-width: 1100px) {
    width: 400px;
  }

  @media (max-width: 1024px) {
    width: 45%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

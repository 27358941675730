.funding_evaluation {
  padding: 22px 20px;
  background-color: var(--primary-color);
  border-radius: 16px;
  position: relative;
  min-height: calc(100vh - 240px);

  .add_address_modal_container {
    position: absolute;
    z-index: 10;
    margin: 0 auto;
    border: 1px solid var(--secondary-color);
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);

    .save_info_btn {
      color: #fff;
      background: var(--secondary-color);
    }
  }

  .grid_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    padding-right: 20px;

    .col1 {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding-right: 0px;
    }

    .col2 {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .create_plan {
        padding: 0px !important;

        .grid_container {
          grid-template-columns: 1fr;

          .col2 {
            display: none;
          }

          .col1_middle {
            .inputBoxDiv {
              justify-self: baseline;
              display: flex;
              flex-direction: row-reverse;
              gap: 5px;
              align-items: center;
              padding-top: 10px;

              .label_black {
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      button {
        font-size: 18px;
        font-weight: 700;
        width: 300px;
        border-radius: 16px;
        // padding: 20px 40px;
        box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
        background-color: var(--secondary-color);
        color: rgb(119, 88, 88);
      }
    }
  }
}

.viewCertificateCon {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 16px;

  .viewCertificate-Head {
    font-weight: 700;
    margin-bottom: 10px;
  }
}

.noPad {
  padding: 0px !important;
}

.spacerT30p {
  margin-top: 30px;
}

.CertificateContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  @media screen and (max-width: 1350px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1190px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 770px) {
    grid-template-columns: 100%;
  }

  .CertificateItem {
    // width: 26%;
    background-color: #F8F8FF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #f5f5f500;
    transition: all 0.2s ease;

    cursor: pointer;

    .Twrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .tcon {
      display: flex;
      align-items: center;

      // justify-content: center;
      p {

        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: #505050;
      }

      span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        margin-left: 5px;
        // margin-bottom: 10px;
      }
    }

    &:hover {
      box-shadow: 0px 0px 10px 0px #F5F5F5;
    }

    img {
      width: 100%;
      // height: 100%;
    }
  }
}
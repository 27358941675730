.paymenthistory_table {
  background-color: var(--main-background-color);
  border-radius: 16px;
  min-height: 60vh;
  color: black;
  .table_scroll_wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .table_scroll {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    @media (max-width: 768px) {
      max-width: 500px;
    }
  }

  .table_wrapper {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    .select_wrapper {
      display: flex;
      align-items: center;

      .select_icon {
        margin-top: 4px;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 700;
  }

  table {
    height: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;
    width: 100%;

    th {
      color: #909195;
      font-weight: 500;
      border-bottom: 1px solid var(--primary-color);
    }

    tr {
      height: 64px;
    }

    th,
    td {
      text-align: left;
      font-size: 14px;
      padding: 10px;
    }

    td {
      font-weight: 500;
      border-bottom: 1px solid var(--primary-color);
      white-space: nowrap;
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
  }

  .view_details_btn {
    background: #ecf4fe;
    height: 28px;
    width: 104px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    border-radius: 4px;
    color: #4692f5;

    &:hover {
      background-color: #4692f5;
      color: #fff;
    }
  }

  .column_one_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .sno_wrapper {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }

  .column_header {
    display: flex;
    align-items: center;
    position: relative;
    text-wrap: nowrap;
    cursor: pointer;

    img {
      margin-top: 2px;
    }
  }

  .table_dropdown {
    height: fit-content;
    width: 215px;
    background: #fff;
    position: absolute;
    border-radius: 16px;
    box-shadow: 0 0px 6px #b0c6e3;
    padding: 0;

    ul {
      height: 100%;
      padding: 0;
      margin: 0;
    }

    li {
      height: 40px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #0e0f19;

      &:not(:last-child) {
        border-bottom: 1px solid var(--primary-color);
      }
    }
  }

  .balance_dropdown {
    margin-left: 86px;
  }

  .funding_dropdown {
    margin-left: 100px;
  }

  .table_dropdown::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 30px;
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }

  @media (max-width: 1024px) {
    td,
    th {
      font-size: 12px !important;
    }
  }

  .progress_tag {
    background-color: #fdeeb4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 500;
    color: #b18e0a;
    border-radius: 4px;
  }

  .error_tag {
    background-color: #f5bdc0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 500;
    color: #9e1d26;
    border-radius: 4px;
  }

  @media (max-width: 600px) {
    .table_header {
      width: 100%;
    }

    .pagination {
      justify-content: flex-start;
    }
  }
}

.calendar-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  p {
    margin: 0 12px 0 8px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
  }
}

.calendar_wrapper {
  position: absolute;
  right: 10px;
  margin-top: -10px;
  z-index: 100;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0 10px 0;

  @media (max-width: 600px) {
    justify-content: flex-start;
  }

  button {
    margin: 0 0.5em;
    color: black;
    background: none;
    border: none;
    font-size: 12px;
  }
}

button {
  margin: 0 0.5em;
  color: black;
  background: none;
  border: none;
  font-size: 12px;
}

.swing_background {
  background-color: #af4102;
}

.aggressive_background {
  background-color: #204e1d;
}

.standard_background {
  background-color: #9e1d26;
}

.standard_background {
  background-color: #9e1d26;
}

.status_wrapper {
  &.Pending_tag {
    color: #b18e0a;
    background: #fdeeb4;
    padding: 4px 10px;
  }
  &.Filtered_tag {
    color: #635c00;
    background: #fed7b4;
    padding: 4px 10px;
  }
}

.ad_details_box {
  // min-height: 590px;
  background-color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  &:hover {
    box-shadow: 0px 0px 2px 0px #1154ac;
    .ad_details_box_lower {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }

  &_upper {
    border-bottom: 1px solid #eaf0f8;
    /* display: flex; */
    width: 100%;
    .header {
      display: flex;
      font-size: 12px;
      justify-content: s;
      align-items: center;
      gap: 15px;
      width: 75%;
      h2 {
        font-size: 16px;
      }
      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 24px;
        border: 1px solid lightgray;
      }
    }

    &_wrapper {
      padding: 15px 20px;
      height: 100%;
      position: relative;

      .bg_img_container {
        background-size: cover;
        border-radius: 8px;
        height: 190px;
        background-repeat: no-repeat;
        position: relative;

        &_header {
          position: absolute;
          top: 20px;
          right: 20px;
          display: flex;
          width: 30px;
          height: 30px;
          justify-content: center;
          align-items: center;
          border-radius: 91px;
          background: var(--foundation-blue-blue-150-main, #36d66b);
          flex-shrink: 0;

          h2 {
            font-family: "Space Grotesk";
          }
          .menu {
            position: absolute;
          }

          .sidemenu {
            padding: 0px;
            display: flex;
            align-items: center;
            width: max-content;
            height: max-content;
            left: -2.5rem;
            top: 4.2rem;
            z-index: 99;
            border-radius: 8px;

            img {
              width: 23px;
              height: 23px;
            }
            &::before {
              content: "";
              position: absolute;
              top: 0%;
              right: 20%;
              margin-top: -15px;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #ffffff;
              rotate: 90deg;
            }

            &_list_container {
              font-size: 16px;
              font-weight: 500;

              .sidemenu_list {
                padding-left: 0px;
                display: flex;
                flex-direction: column;
              }
            }
            .option_container {
              display: flex;
              align-items: center;
              gap: 10px;
              height: 35px;
              width: 130px;
              padding: 5px;
              padding-left: 20px;
              padding-right: 0px;
              margin-right: 0px;
              border-bottom: 1px solid var(--text-grey);
              h5 {
                font-family: "DM Sans";
                font-weight: 500;
              }
              svg path {
                fill: rgb(0, 0, 0);
              }
              &:hover {
                h5 {
                  color: var(--secondary-color);
                }
                svg path {
                  fill: var(--secondary-color);
                }
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }

          img {
            width: 23px;
            height: 23px;
          }
        }

        &_btn {
          position: absolute;
          bottom: 20px;
          left: 20px;

          button {
            padding: 0;
            height: 34px;
            width: 218px;
            border-radius: 8px;
            font-size: 14px;
            font-family: 700;
          }
        }
      }
    }

    .competiton_details_container {
      padding-top: 10px;

      h2 {
        font-size: 18px;
        font-weight: 700;
      }
      div {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding-top: 20px;
        span {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &_middle {
    border-bottom: 1px solid #eaf0f8;
    &_wrapper {
      padding: 15px 20px 0 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .date {
        margin-right: auto;
        h4 {
          font-family: "DM Sans";
          font-weight: 600;
          font-size: 12px;
        }
      }

      .display_wrapper {
        height: 320px;
        width: 100%;
        margin: 15px 0;
        padding: 0px;
        .display1 {
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          max-height: 320px;
          width: 100%;
          .bg_img {
            position: absolute;
            height: 100%;
            width: 100%;
          }
          .main_img {
            position: relative;
            top: -1rem;
            left: 0rem;
          }
          .heading {
            position: relative;
            top: -3rem;
            font-size: 20px;
            color: white;
            text-wrap: wrap;
            max-width: 202px;
            left: 8rem;
            line-height: 1.3;
           
              @media (max-width: 1000px) {
                left: 4rem;
              }
              @media (max-width: 768px) {
                left: 8rem;
              }
              @media (max-width: 500px) {
                left: 2rem;
              }
          }
          button {
            position: relative;
            top: -1.5rem;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            width: 80%;
            height: 48px;
            justify-content: center;
            align-items: center;
            color: var(--secondary-color);
            border-radius: 8px;
            background: var(--foundation-neutral-white-l-50-white, #fff);
          }
        }
        .display2 {
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          max-height: 320px;
          .bg_img {
            position: absolute;
            height: 100%;
          }
          .main_img {
            position: relative;
            top: -10rem;
            left: 0rem;
            scale: 1.4;
          }
          .heading {
            position: relative;
            top: -7rem;
            text-align: center;
            font-size: 27px;
            font-weight: 600;
            color: white;
            text-wrap: wrap;
            line-height: 1.5;
          }
          button {
            position: relative;
            top: -5.5rem;
            font-size: 22px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            width: 100%;
            height: 80px;
            justify-content: center;
            align-items: center;
            color: var(--secondary-color);
            background: var(--foundation-neutral-white-l-50-white, #fff);
          }
        }
      }
      .winner_details_container {
        min-height: 124px;
        p {
          color: #a4a8ae;
          font-size: 12px;
          font-weight: 700;
        }
        ul {
          font-size: 14px !important;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          gap: 20px;
          li {
            display: flex;
            align-items: center;
            img {
              margin-right: 12px;
              height: 20px;
              width: 20px;
            }
            &:first-child {
              span {
                color: #4cb944;
              }
            }
            &:nth-child(2) {
              span {
                color: #36d66b;
              }
            }
            &:last-child {
              span {
                color: #f75c03;
              }
            }
          }
        }
      }
    }
  }

  &_bottom {
    flex-grow: 1;
    padding: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 130px;
    &_wrapper {
      padding: 16px 20px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        span {
          display: flex;
          gap: 5px;
          padding: 0px;
        }
      }
      .display_selector {
        position: relative;
        .display_wrapper {
          display: flex;
          gap: 80px;
          scale: 0.2;
          position: absolute;
          top: -7em;
          left: -29em;
        }
        .display1 {
          border-radius: 40px;
          position: relative;
          overflow: hidden;
          min-height: 320px;
          min-width: 340px;
          .bg_img {
            position: absolute;
            height: 100%;
            width: 100%;
          }
          .main_img {
            position: relative;
            top: -1rem;
            left: 0rem;
          }
          .heading {
            position: relative;
            top: -3rem;
            font-size: 20px;
            color: white;
            text-wrap: wrap;
            max-width: 202px;
            left: 10rem;
            line-height: 1.3;
          }
          button {
            position: relative;
            top: -1.5rem;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            width: 236px;
            height: 48px;
            justify-content: center;
            align-items: center;
            color: var(--secondary-color);
            border-radius: 8px;
            background: var(--foundation-neutral-white-l-50-white, #fff);
          }
        }
        .display2 {
          border-radius: 40px;
          position: relative;
          overflow: hidden;
          height: 320px;
          min-height: 320px;
          min-width: 340px;
          .bg_img {
            position: absolute;
            height: 100%;
          }
          .main_img {
            position: relative;
            top: -10rem;
            left: 0rem;
            scale: 1.4;
          }
          .heading {
            position: relative;
            top: -7rem;
            text-align: center;
            font-size: 27px;
            font-weight: 600;
            color: white;
            text-wrap: wrap;
            line-height: 1.5;
          }
          button {
            position: relative;
            top: -5.5rem;
            font-size: 22px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            width: 100%;
            height: 80px;
            justify-content: center;
            align-items: center;
            color: var(--secondary-color);
            background: var(--foundation-neutral-white-l-50-white, #fff);
          }
        }
      }
    }
  }

 
}

.create_competition {
  .date-container {
    display: flex;
  }
  padding: 22px 20px;
  background: var(--primary-color);
  border-radius: 24px;
  position: relative;
  .date_to {
    img {
      // display: none;
    }
  }
  .fieldError {
    position: absolute;
    top: 100%;
    margin-left: -20px;
    // margin-left:;
    width: 100%;
    display: block;
    text-align: end;
    color: #f75c03;
  }
  .alert {
    top: 100%;
    margin-left: -20px;
    // margin-left:;
    width: 100%;
    display: block;
    text-align: end;
    color: #f75c03;
  }
  .add_address_modal_container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .grid_container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    padding-right: 20px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr !important;
    }
  }

  .col2 {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_top {
      align-items: center;
      gap: 20px;

      .image_selector_wrapper {
        display: flex;
        padding: 20px;
        border-radius: 16px;
        font-size: 14px;
        max-height: 200px;
        gap: 20px;
        margin: 14px 0px;
        /* margin-bottom: 20px; */
        background-color: var(--main-background-color);

        @media (max-width: 500px) {
          flex-wrap: wrap;
          max-height: fit-content;
        }

        .image_input_wrapper {
          input {
            display: none;
          }

          h4 {
            span {
              color: #36d66b;
              text-decoration: underline;
              text-underline-offset: 3px;
            }
          }

          font-size: 16px;
          text-align: center;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 2px dashed #a4a8ae;
          cursor: pointer;
          border-radius: 10px;
        }

        .previous_images {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          gap: 17px;
          width: 300px;
          max-height: 170px;
          flex-wrap: wrap;

          @media (max-width: 768px) {
            width: 100%;
            max-height: fit-content;
          }

          @media (max-width: 500px) {
            flex-direction: row;
          }

          :first-child {
            height: 100%;
            max-width: 190px;
            border-radius: 10px;
            object-fit: cover;
            @media (max-width: 500px) {
              height: 50px;
              width: 50px;
            }
          }

          :nth-child(2) {
            height: 40%;
            @media (max-width: 500px) {
              height: 50px;
              width: 50px;
            }
          }

          :nth-child(3) {
            height: 50%;
            @media (max-width: 500px) {
              height: 50px;
              width: 50px;
            }
          }
        }
      }
    }

    &_bottom {
      .competition_rules_wrapper {
        button {
          display: flex;
          width: 100%;
          height: 67px;
          margin-top: 20px;
          padding: 20px 40px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 16px;
          background: #36d66b;
          box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
          color: #fff;
        }
      }

      .rules_wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 16px;
        font-size: 14px;
        /* height: 485px; */
        gap: 25px;
        margin: 14px 0px;
        background-color: var(--main-background-color);

        .rules_toggle {
          display: flex;
          align-items: center;
          justify-content: space-between;

          /* The switch - the box around the slider */
          .switch {
            position: relative;
            display: inline-block;
            width: 54px;
            height: 26px;
          }

          /* Hide default HTML checkbox */
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          /* The slider */
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            transition: 0.4s;
          }

          input:checked + .slider {
            background-color: #36d66b;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #36d66b;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }

        .rule_inputs {
          flex-direction: column;
          display: flex;
          gap: 25px;
          button {
            all: unset;
          }
          .ck-toolbar {
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
          }
          .ck-editor__editable_inline {
            min-height: 235px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          // input {
          //   height: 48px;
          //   align-items: center;
          //   padding: 20px;
          //   box-sizing: border-box;
          //   border-radius: 8px;
          //   border: 1px solid #eaf0f8;
          //   margin-top: 0px;
          //   font-size: 14px;
          //   font-weight: 500;
          //   color: black;
          //   background-color: var(--main-background-color);
          //   display: flex;
          //   width: 100%;
          //   position: relative;
          //   gap: 10px;
          // }

          // .add_rule_button {
          //   height: 48px;
          //   align-items: center;
          //   padding: 20px;
          //   box-sizing: border-box;
          //   border-radius: 8px;
          //   border: 1px solid #eaf0f8;
          //   margin-top: 0px;
          //   font-size: 14px;
          //   font-weight: 500;
          //   color: black;
          //   background-color: var(--main-background-color);
          //   display: flex;
          //   width: 100%;
          //   position: relative;
          //   gap: 10px;
          // }
        }
      }
    }
  }

  .col1 {
    display: flex;
    flex-direction: column;
    gap: 0px;

    &_top {
      align-items: center;
      gap: 20px;
      width: 100%;
    }

    .event_input_wrapper {
      display: flex;
      padding: 20px;
      border-radius: 16px;
      font-size: 14px;

      flex-direction: column;
      margin: 14px 0px;
      // margin-bottom: 20px;
      background-color: var(--main-background-color);

      label {
        margin: 0px;
        margin-bottom: 10px;
        padding: 50p;
        text-wrap: nowrap;
        font-weight: 700;
      }

      button {
        width: 100%;
        height: 75px;
        max-width: 45%;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #eaf0f8;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 500;
        color: black;
        background-color: var(--main-background-color);
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 0px;
          width: 100%;
          gap: 0px;
          img {
            right: -20px;
            /* margin-right: 1px; */
            position: relative;
            pointer-events: none;
            scale: 1.2;
          }
        }
      }

      input {
        width: 100%;
        height: 48px;
        padding: 0px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #eaf0f8;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 500;
        color: black;
        background-color: var(--main-background-color);
      }

      .date_wrapper {
        button {
        }
        .date_from,
        .date_to {
          flex-direction: column;
          // padding: 0px;
        }
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          // display: none;
        }
        @media (max-width: 500px) {
          flex-wrap: wrap;
        }
      }
    }

    &_middle {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;

      .constestants_wrapper {
        width: 100%;
      }

      .const_input_wrapper {
        padding: 20px;
        border-radius: 16px;
        font-size: 14px;

        margin: 14px 0px;

        background-color: var(--main-background-color);

        label {
          margin: 10px 0px;
          padding: 50p;
          text-wrap: nowrap;
          font-weight: 700;
        }

        .input_wrapper {
          height: 48px;
          align-items: center;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-size: 14px;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          display: flex;
          width: 100%;
          position: relative;

          .box {
            position: absolute;
            right: 0px;
            width: 30%;

            input {
              width: 100%;
              height: 48px;
              padding: 20px;
              box-sizing: border-box;
              border-radius: 8px;
              border: 1px solid #eaf0f8;
              margin-top: 0px;
              font-size: 14px;
              font-weight: 500;
              color: black;
              background-color: var(--main-background-color);
            }
          }

          .css-1brt2n-control {
            display: flex;
            gap: 0px;
            margin-bottom: 5px;
            font-size: 16px;
            width: 175px;
            align-content: center;
          }

          .slider {
            margin-top: 20px;
          }
        }
      }

      &_left,
      &_right {
        background-color: var(--main-background-color);
        height: 280px;
        width: 100%;
        border-radius: 16px;
      }
    }

    &_bottom {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .winners_wrapper {
      width: 100%;
    }

    .winners_input_wrapper {
      display: flex;
      padding: 20px;
      border-radius: 16px;
      font-size: 14px;
      flex-direction: column;
      margin: 14px 0px;

      background-color: var(--main-background-color);

      .prize_details_wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 252px;
        overflow-y: scroll;

        .input_wrapper {
          height: 48px;
          align-items: center;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eaf0f8;
          margin-top: 0px;
          font-size: 14px;
          font-weight: 500;
          color: black;
          background-color: var(--main-background-color);
          display: block;
          width: 100%;
          position: relative;
          gap: 10px;
          padding-top: 5px;
          margin-bottom: 10px;

          .innerdiv {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .addbutton {
            cursor: pointer;
            padding: 8px;
            background: #36d66b;
            color: #fff;
            width: 100px;
            border-radius: 10px;
            box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
            p {
              text-align: center;
            }
          }
          input {
            width: inherit;
            padding: 20px;
            box-sizing: none;
            border-radius: 0px;
            border-top: 1px solid #eaf0f8;
            border-bottom: 1px solid #eaf0f8;
            border-left: none;
            border-right: none;
          }

          .prize_header1 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              color: #4cb944;
            }
          }

          .prize_header2 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              color: #36d66b;
            }
          }

          .prize_header3 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              color: #f75c03;
            }
          }

          .prize_header4 {
            width: 150px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              color: #313131;
            }
          }
        }

        img {
          margin-right: 12px;
          height: 24px;
          width: 24px;
        }

        .input_wrapper {
        }
      }

      input {
        width: 100%;
        height: 48px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #eaf0f8;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 500;
        color: black;
        background-color: var(--main-background-color);
      }

      label {
        margin: 10px 0px;
        padding: 50p;
        text-wrap: nowrap;
        font-weight: 700;
      }
    }
  }
}

.input_date {
  border: none !important;
  outline: none !important;
  height: 40px !important;
}

.tooltip {
  position: absolute;
  width: 250px;
  min-width: fit-content;
  padding: 1em;
  background-color: var(--icon-background-color);
  color: #fff;
  border-radius: 4px;
  z-index: 1;
  top: 40px;
  left: 98%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 14px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  cursor: default;
}

.tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  // transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: var(--icon-background-color) transparent transparent transparent;
}

.tooltip-variant {
  width: 150px;
  text-align: center;
}

.tooltip-variant:before {
  top: -40%;
  transform: translateX(-50%);
  border-color: transparent transparent var(--icon-background-color) transparent;
}

.active {
  pointer-events: all;
  opacity: 0.8;
}

.funding_evaluation {
  padding: 22px 20px;
  background-color: var(--primary-color);
  border-radius: 16px;
  position: relative;
  min-height: calc(100vh - 240px);
  .add_address_modal_container {
    position: absolute;
    z-index: 10;
    margin: 0 auto;
    border: 1px solid var(--secondary-color);
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
    .save_info_btn {
      color: #fff;
      background: var(--secondary-color);
    }
  }
  .grid_container {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 20px;
    box-sizing: border-box;
    padding-right: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding-right: 0px;
    }

    .col2 {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .create_plan {
        padding: 0px;
        .grid_container {
          grid-template-columns: 1fr;
          .col2 {
            display: none;
          }
          .col1_middle {
            .inputBoxDiv {
              justify-self: baseline;
              display: flex;
              flex-direction: row-reverse;
              gap: 5px;
              align-items: center;
              padding-top: 10px;
              .label_black {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
      button {
        font-size: 18px;
        font-weight: 700;
        width: 300px;
        border-radius: 16px;
        // padding: 20px 40px;
        box-shadow: 4px 7px 12px 0px rgba(17, 84, 172, 0.4);
        background-color: var(--secondary-color);
        color: white;
      }
    }
  }
}

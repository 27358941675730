:root {
  font-family: "DM Sans", sans-serif;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  --primary-color: #eaf0f8;
  --secondary-color: #36d66b;
  --main-background-color: #ffffff;
  --tab-color: #ffffff;
  --text-grey: #a4a8ae;
  --primary-color: #eaf0f8;
  --secondary-color: #36d66b;
  --tab-color: #ffffff;

  --border-color: #1154ac;
  --hover-color: #7cabe9;
  --active-color: #4687dc;

  --subtext-color: #a4a8ae;
  --text-link-color: #0e0f19;
  --text-error-color: #f25f5c;
  --text-error-bg-color: #f5bdc0;
  --green-success-color: #7ae582;
  --green-success-bg-color: #c8e9c580;
  /* --red-error-color: #df2935; */
  --red-error-color: #f25f5c;
  --red-error-bg-color: #f5bdc0;
  --red-loss-color: #df2935;
  --yellow-progress-color: #b18e0a;
  --yellow-progress-bg-color: #fdeeb4;
  --yellow-warning-color: #f9c80e;
  --icon-background-color: #081924;

  --black-color: #000000;
  --white-color: #ffffff;
  --orange-color: #f75c03;
  --blue-color: #36d66b;
  --gray-color: #808080;
  --light-gray-color: #a4a8ae;
  --light-black-color: #3e3f47;
}

.light-theme {
  --text-color: #000000;
  --background-color: #ffffff;
  --tab-color: #ffffff;
}

.dark-theme {
  --text-color: #ffffff;
  --background-color: #242424;
  --tab-color: #242424;
}

button {
  color: white;
  background-color: var(--secondary-color);
}
body {
  color: #000000;
}

:root {
  background-color: #ffffff;
}
button {
  color: white;
  background-color: var(--secondary-color);
}

body {
  color: #000000;
  /* background: var(--main-background-color); */
}

/* header {
    background-color: #ffffff;
  } */

/* h2 {
    color: #ffffff;
  }
   */

body {
  margin: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

button {
  padding: 1em 0;
  border: none;
  outline: none;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background-color: var(--secondary-color);
  cursor: pointer;
}

ul {
  list-style: none;
  padding-left: 0;
  cursor: default;
}

/* Hide Arrows for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

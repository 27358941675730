.financialperformance-container {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 6;
  padding: 1em;
  background: var(--main-background-color);
  border-radius: 16px;

  .highestBalanceAndEquity {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      padding-left: 2px;
      color: #3e3f47;
      span {
        font-weight: 700;
      }
    }
  }
  .chart_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .account_status {
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 6px 16px;
      font-size: 12px;
      font-weight: 500;

      border-radius: 4px;
      text-transform: capitalize;
      text-wrap: nowrap;
      max-width: 100px;
      &.active_passed {
        background-color: rgba(144, 238, 144, 0.5); // Light green for success
        color: #2e7d32; // Darker green for better contrast
      }

      &.active_progress {
        background-color: rgba(255, 245, 157, 0.8); // Light yellow for progress
        color: #ffab00; // Amber for better visibility
      }

      &.active_failed {
        background-color: rgba(255, 204, 204, 0.7); // Light red for failure
        color: #d32f2f; // Dark red for better contrast
      }
    }
  }

  .financialperformance-subcontainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 500px) {
      overflow-x: auto;
    }
    @media (max-width: 400px) {
      max-width: 300px;
    }

    .financialperformance-innercontainer {
      flex: 1;

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      .apexcharts-canvas {
        height: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0.5em;
  }
}

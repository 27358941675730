.accountdetails-container {
  // margin: 0 0.25em;
  box-sizing: border-box;
  padding: 1em;

  .accountdetails-subcontainer {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 1.65em;
    // color: #fff;

    .accountdetails-image_dp {
      height: 80px;
      width: 80px;
    }

    .dropdown {
      transform: translateY(100px);
      top: 0;
      right: auto;
      left: 0;
    }

    .accountdetails-innercontainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      margin-left: 2em;

      .accountdetails-container_header {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        cursor: pointer;

        h4 {
          margin: 0;
          font-size: 20px;
          font-weight: 700;
        }

        p {
          color: #3e3f47;
          font-size: 14px;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  .accountdetails-container_details {
    // display: flex;
    // align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;

    div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h5 {
        margin: 0.25em 0;
        color: #a4a8ae;
        font-size: 14px;
        font-weight: 500;
      }

      p {
        display: flex;
        align-items: center;
        font-weight: 700;

        img {
          margin: 0 0.75em;
          cursor: pointer;
        }
      }
    }
  }

  //   @media (min-width: 1024px) {
  @media (width<1024px) {
    max-width: 678px;
    width: 100%;
    // margin: 0 auto;
  }
}

.accountdetails-profit {
  color: #4cb944;
}

@media (max-width: 768px) {
  .accountdetails-container {
    height: auto;
    min-height: 100%;
    width: 100%;
  }

  .accountdetails-subcontainer {
    flex-direction: column;
  }

  .accountdetails-innercontainer {
    margin: 1em 0;
    flex-direction: column;

    div {
      margin-top: 1em;
    }
  }

  .accountdetails-container_details {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.trading_profit {
  padding: 20px;

  .alltable {
    padding: 16px;
    background-color: var(--main-background-color);
    border-radius: 16px;

    .alltable_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 99%;
      flex-direction: row;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .search_bar {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        height: 40px;
        width: 330px;
        background-color: #eaf0f8;
        border-radius: 63px;
        // margin-right: 20px;
        @media (max-width: 768px) {
          width: 90%;
        }
        .search_input {
          flex: 1;
          height: 90%;
          border: none;
          outline: none;
          padding-left: 8px;
          border-radius: 63px;
          font-size: 14px;
          background-color: #eaf0f8;

          &::placeholder {
            color: #a4a8ae;
          }
        }

        .search_icon {
          margin-left: 10px;
        }
      }

      .select_wrapper {
        display: flex;
        align-items: center;
        .select_icon {
          margin-top: 4px;
        }
      }
    }
    .table_scroll_wrapper {
      width: 100%;
      overflow-x: auto;
      margin-top: 20px;
    }

    .table_scroll {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      @media (max-width: 768px) {
      }
    }

    table {
      width: 100%;
      height: 100%;
      margin-top: 1.5rem;
      border-collapse: collapse;

      th {
        color: #909195;
        font-weight: 500;
        white-space: nowrap;
        @media (max-width: 768px) {
          padding-right: 20px;
        }
      }

      tr {
        height: 64px;
      }

      th,
      td {
        text-align: left;
        font-size: 14px;
      }

      td {
        font-weight: 500;
        border-bottom: 1px solid #eaf0f8;
        white-space: nowrap;
        @media (max-width: 768px) {
          padding-right: 20px;
        }
      }
      .column_data {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .progress_tag {
      background-color: #fdeeb4;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 6px 16px;
      font-size: 12px;
      font-weight: 500;
      color: #b18e0a;
      border-radius: 4px;
    }

    .column_one_wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      .sno_wrapper {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4cb944;
        color: #fff;
      }
    }
    .column_header {
      display: flex;
      align-items: center;
    }
    .pagination {
      display: flex;
      justify-content: center;

      button {
        margin: 0 1em;
        color: black;
        background: none;
        border: none;
        font-size: 12px;
      }
    }
  }

  .view_details_btn {
    background: #ecf4fe;
    height: 28px;
    width: 104px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    border-radius: 4px;
    color: var(--secondary-color);
  }
}

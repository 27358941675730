.advancedStats_wrapper {
  border-radius: 24px;
  background-color: var(--primary-color);
  height: 72vh;
  overflow: auto;
  padding: 20px;
  .withdrawalStatus_wrapper {
    border-radius: 16px;
    background-color: var(--main-background-color) !important;
    padding: 0px;
  }
  .table_header {
    display: flex;
    padding: 1px;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0px 16px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    .search {
      display: flex;
      align-items: center;
      flex: 1;
      .search_bar {
        flex: 1;
      }
    }
    .filter_buttons {
      justify-content: end;
      display: flex;
      width: 100%;
      gap: 10px;
      flex: 1 1;
      align-items: flex-end;
      button {
        border: 1px solid var(--secondary-color);
        color: var(--secondary-color);
        border-radius: 10px;
        padding: 10px;
        flex: 1 1;
        margin: 0px;
        &.active_btn {
          background-color: var(--secondary-color);
          color: #fff;
        }
      }
    }
  }
  table {
    border-collapse: collapse;
    tr {
      height: 64px;
    }
    th {
      padding: 0px 1.5em;
      color: #a4a8ae;
      font-weight: 500;
      text-align: left;
      font-size: 14px;
    }
    tbody {
      tr {
        border-top: 1px solid #eaf0f8;
        transition: all 0.3s ease;
        cursor: pointer;
        td {
          vertical-align: middle;
          padding: 1em 1.5em;
          height: 28px;
          font-weight: 700;
          text-align: left;
          font-size: 14px;
          .column_one_wrapper {
            display: flex;
            align-items: center;
            gap: 12px;
          }
        }
      }
    }
  }
}

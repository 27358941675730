.calendar {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(40px);
 &:before{
    content: '';
    position: absolute;
    top: -6px;
    left: 240px;
    border-bottom: 10px solid var(--background-color);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
  .react-calendar {
    box-sizing: content-box;
    padding: 1em;
    width: 263px;
    height: fit-content;
    max-width: 100%;
    border: 1px solid #B7D5FB;
    border-radius: 16px;
    box-shadow: 0 0px 6px #b0c6e3;
   
  }
  //   .react-calendar__navigation button {
  //     color: #6f48eb;
  //     min-width: 44px;
  //     background: none;
  //     font-size: 16px;
  //     margin-top: 8px;
  //   }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: transparent
    }
  //   .react-calendar__navigation button[disabled] {
  //     background-color: #f0f0f0;
  //   }
  //   abbr[title] {
  //     text-decoration: none;
  //   }
  button {
    font-size: 12px;
  }
  .react-calendar__navigation {
    button {
      color: black;
    }
    .react-calendar__navigation__arrow {
      border-radius: 50%;
    }
  }
  .react-calendar__tile {
    color: #000000;
  }
  .react-calendar__tile--hasActive {
    color: #ffffff;
  }
  .react-calendar__tile--active {
    background: var(--secondary-color);
    color: white;
  }
  .react-calendar__tile--range {
    position: relative;
    background: #b7d5fb;
    border-radius: 0% !important;
    z-index: 1;
  }
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background: var(--secondary-color);
  }
  .react-calendar__tile--rangeStart {
    // border-radius: 50% 0 0 50% !important;
    border-radius: 50% !important;
  }
  // .react-calendar__tile--rangeStart::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 50%;
  //   width: 36px;
  //   height: 36px;
  //   background-color: red;
  //   transform: translateX(-50%);
  //   z-index: -1;
  //   border-radius: 50% 0 0 50%;
  // }
  .react-calendar__tile--rangeEnd {
    // border-radius: 0 50% 50% 0 !important;
    border-radius: 50% !important;
  }
  .react-calendar__tile--now {
    color: var(--secondary-color);
    background: #b7d5fb;
    // color: #000000;
    // background: none;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: #757575;
    abbr {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__days__day {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  // .react-calendar__month-view__days__day--weekend {
  //   color: #d10000;
  // }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  //   .react-calendar__tile:enabled:hover,
  //   .react-calendar__tile:enabled:focus {
  //     background: #f8f8fa;
  //     color: #6f48eb;
  //     border-radius: 6px;
  //   }
  //   .react-calendar__tile--now {
  //     background: #6f48eb33;
  //     border-radius: 6px;
  //     font-weight: bold;
  //     color: #6f48eb;
  //   }
  //   .react-calendar__tile--now:enabled:hover,
  //   .react-calendar__tile--now:enabled:focus {
  //     background: #6f48eb33;
  //     border-radius: 6px;
  //     font-weight: bold;
  //     color: #6f48eb;
  //   }
  //   .react-calendar__tile--hasActive:enabled:hover,
  //   .react-calendar__tile--hasActive:enabled:focus {
  //     background: #f8f8fa;
  //   }
  //   .react-calendar__tile--active {
  //     background: #6f48eb;
  //     border-radius: 6px;
  //     font-weight: bold;
  //     color: white;
  //   }
  //   .react-calendar__tile--active:enabled:hover,
  //   .react-calendar__tile--active:enabled:focus {
  //     background: #6f48eb;
  //     color: white;
  //   }
  //   .react-calendar--selectRange .react-calendar__tile--hover {
  //     background-color: #f8f8fa;
  //   }
  //   .react-calendar__tile--range {
  //     background: #f8f8fa;
  //     color: #6f48eb;
  //     border-radius: 0;
  //   }
  //   .react-calendar__tile--rangeStart {
  //     border-top-right-radius: 0;
  //     border-bottom-right-radius: 0;
  //     border-top-left-radius: 6px;
  //     border-bottom-left-radius: 6px;
  //     background: #6f48eb;
  //     color: white;
  //   }
  //   .react-calendar__tile--rangeEnd {
  //     border-top-left-radius: 0;
  //     border-bottom-left-radius: 0;
  //     border-top-right-radius: 6px;
  //     border-bottom-right-radius: 6px;
  //     background: #6f48eb;
  //     color: white;
  //   }
}


.react-calendar__tile--range{
  background: #b7d5fb;
  border-radius: 50% !important;
}

.react-calendar__tile--rangeStart{
  border-radius: 0 !important;
}

.react-calendar__navigation__prev2-button{
  display: none !important;
}

.react-calendar__navigation__next2-button{
  display: none !important;
}
.react-calendar__month-view__weekdays__weekday{
  text-transform: capitalize;
}

 .react-calendar__navigation__prev-button{
margin-left: 200px !important;
margin-top: 2px !important;
position: absolute;

 }

.react-calendar__month-view__days__day{
 
  font-size: 12px;
  font-weight: 500;
}
.signin-container {
  display: flex;
  // justify-content: center;
  justify-content: space-between;
  gap: 44px;
  align-items: center;
  padding: 16px;
  max-height: 850px;
  .authframe-container {
    filter: hue-rotate(-48deg);
  }
}

.signin-right {
  flex: 30%;
}

@media screen and (max-width: 1400px) {
  .signin-left {
    flex: 60%;
  }
  .sigin-right {
    flex: 40%;
  }
}

@media screen and (max-width: 900px) {
  .signin-left {
    display: none;
  }
  .sigin-right {
    flex: 40%;
  }
}
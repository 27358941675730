.confirmationModal_wrapper {
  background-color: var(--main-background-color);
  min-width: 300px;
  max-height: 75vh;
  min-height: 150px;
  overflow-y: scroll;
  border-radius: 16px;
  padding: 20px;
  position: fixed;
  z-index: 10;
  margin: 0 auto;
  border: 2px solid var(--secondary-color);
  left: 50%;
  right: 50%;
  top: 30vh;
  transform: translate(-50%, -50%);
  overflow: hidden;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  .input_container {
    padding: 10px;
    background-color: #eaf0f8 !important;
    border: none;
    outline: none;
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .buttons_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      padding: 10px 20px;
      border-radius: 10px;
      flex: 1;
      color: #fff;
      &.confirm {
        background-color: var(--secondary-color);
      }
      &.cancel {
        background-color: var(--red-error-color);
      }
    }
  }
}

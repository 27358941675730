.adminoverview {
  padding: 22px 20px;
  background: var(--primary-color);
  border-radius: 24px;
  min-height: calc(100vh - 240px);

  &_grid_container {
    display: grid;
    // grid-template-columns: 68% 30.5%;
    grid-gap: 20px;
    // padding-right: 10px;
  }

  .papercon {
    padding: 20px;
    background-color: var(--main-background-color);
    border-radius: 16px;

    .HeadT {
      font-size: 14px;
      font-weight: 500;
      color: rgb(119, 119, 119);
    }

    .TextB {
      margin-top: 4px;
      font-size: 28px;
      font-weight: 600;
      color: rgb(0, 0, 0);
    }
  }

  .col2 {
    // min-height: 800px;
    background-color: var(--main-background-color);
    border-radius: 16px;
    height: min-content;

    h1 {
      font-size: 18px;
      font-weight: 500;
      padding: 20px 0 0 20px;
      margin: 0px;
      font-family: "Space Grotesk", sans-serif;
    }

    .donutChart {
      display: flex;
      padding: 20px;

      .donutCharts_containers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;

        h1 {
          font-size: 18px;
          font-family: sans-serif;
        }
      }
    }

    .total_transaction {
      h1 {
        font-size: 18px;
        font-weight: 500;
        padding: 20px 0 10px 20px;
        margin: 0px;
        font-family: "Space Grotesk", sans-serif;
      }

      .inner_div {
        padding: 3px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        span {
          text-transform: capitalize;
          font-size: 18px;
        }
      }
    }
  }

  .col1 {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_top {
      display: flex;
      align-items: center;
      gap: 20px;

      &_left,
      &_right {
        background-color: var(--main-background-color);
        height: fit-content;
        width: 100%;
        border-radius: 16px;
      }
    }

    &_middle {
      display: flex;
      align-items: center;
      gap: 20px;

      &_left,
      &_right {
        background-color: var(--main-background-color);
        height: 280px;
        width: 100%;
        border-radius: 16px;
      }
    }

    &_bottom {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &_upper,
      &_lower {
        background-color: var(--main-background-color);
        height: fit-content;
        padding-bottom: 20px;
        width: 100%;
        border-radius: 16px;
      }
    }
  }
}

.MuiPaper-root {
  .MuiDialogContent-root {
    .MuiPickersLayout-root {
      .MuiPickersToolbar-root {
        .MuiPickersToolbar-content {
          h4 {
            color: black;
          }
        }
      }
    }
  }
}

@media (max-width: 1260px) {
  .adminoverview {
    // margin-right: 0px;

    // &_grid_container {
    //   grid-template-columns: 50% 50% !important;
    // }
  }

  .col2 {
    .donutChart {
      &.free_trial {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns with equal width */
      }

      &.pro {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .donutChart.free_trial div {
      grid-column: span 1;
      /* Each div spans one column */
    }
  }

  .col1 {
    &_top {
      flex-direction: column;
    }

    .col1_middle {
      flex-direction: column;
    }
  }
}

@media (max-width: 850px) {
  .adminoverview {
    &_grid_container {
      display: flex;
      flex-direction: column;
    }
  }

  .col1 {
    &_top {
      flex-direction: column;
    }
  }

  .col2 {
    .donutChart {
      &.free_trial {
        display: grid;
        grid-template-columns: 100%;
        /* Two columns with equal width */
      }

      &.pro {
        display: grid;
        grid-template-columns: 100%;
      }
    }

    .donutChart.free_trial div {
      grid-column: span 1;
      /* Each div spans one column */
    }
  }
}

@media (max-width: 500px) {
  .adminoverview {
    padding: 10px;
    margin-right: 0;

    &_grid_container {
      padding-right: 0;
    }

    .col1 {
      &_top,
      &_middle,
      &_bottom {
        gap: 10px;
      }
    }
  }
}

.overview_section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .rowOne {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  .rowTwo {
    background: white !important;
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
    padding: 20px;

    .fundedDataBox {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      background-color: var(--main-background-color);
      padding: 20px;
      border-radius: 16px;
      gap: 10px;

      .funded_section {
        .TextB {
          text-wrap: nowrap;
        }
      }
    }
  }
}
.approvedPaymentList {
  h4 {
    font-size: 20px;
  }
  .traderslist {
    padding: 0px;
    background-color: #fff !important;
  }
}
.support_wrapper .support {
  .traderslist {
    padding: 22px 20px;
    /* background: var(--primary-color); */
    background: white !important;
    border-radius: 24px;
  }
}
.col1_bottom_lower {
  .search_input {
    background-color: var(--main-background-color) !important;
    outline: none !important;
    border: 1px solid #b3b3b3 !important;
    padding: 10px !important;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
  }
  .filterbydate {
    .MuiStack-root {
      .MuiFormControl-root {
        .MuiInputBase-root {
          .MuiInputBase-input {
            padding: 7px !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .datepicker-date {
      width: 100% !important;
    }
  }
}

.dark-theme {
  .support_wrapper {
    .overview_section {
      .rowOne {
        .HeadT {
          color: #b8b8b8;
        }
        .funded_section {
        }
      }
      .rowTwo {
        background-color: var(--main-background-color) !important;
        background: var(--main-background-color) !important;
        .traderslist {
          background-color: transparent !important;
          background: transparent !important;
          .countrywiseTraderWrapper {
          }
        }
      }
    }
  }
}

.countrywiseTableWrapper {
  background: white !important;
}

.dark-theme {
  .support_wrapper {
    .papercon {
      .col1_bottom_lower {
        .stagestats {
          .stagestats_wrapper {
            .chart_wrapper {
              .chartBox {
                .apexcharts-canvas {
                  .apexcharts-legend {
                    .apexcharts-legend-series {
                      .apexcharts-legend-text {
                        color: #b8b8b8 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .filterbydate {
          .MuiStack-root {
            .MuiFormControl-root {
              .MuiInputBase-root {
                .MuiOutlinedInput-notchedOutline {
                  border-color: #b8b8b8 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.reset_btn {
  background-color: #36d66b !important;
  color: #fff;
  text-wrap: nowrap;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.react-select-country {
  max-width: 300px !important;
}
.papercon.funded.rowOne.payouts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px !important;
  background-color: var(--main-background-color);
  border-radius: 16px;
}

.overview_section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .papercon.funded {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(217px, 1fr));
    gap: 20px;
    padding: 20px !important;
    background-color: var(--main-background-color);
    border-radius: 16px;
    justify-content: space-between;
  }
  .papercon {
    padding: 20px;
    background-color: var(--main-background-color);
    border-radius: 16px;
    @media screen and (max-width: "700px") {
      padding: 0 !important;
    }
  }
}

.overview_pagination {
  @media screen and (max-width: "700px") {
    display: flex !important;
    flex-direction: column;
  }
}

.papercon.funded.datepicker {
  padding: 20px !important;
  background-color: var(--main-background-color);
  border-radius: 16px;
  display: flex;
  justify-content: end !important;
}

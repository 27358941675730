.evaluation {
  padding: 20px;
  background-color: var(--main-background-color);
  border-radius: 16px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  // position: relative;
  gap: 20px;

  .create_header {
    display: flex;
    justify-content: space-between;
    .create_wrapper {
      cursor: pointer;
      display: flex;
      gap: 10px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 600;
    text-wrap: nowrap;
  }

  .evaluation_wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 20px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .evaluation_box {
    // width: 183px;
    flex: 1;
    height: 130px;
    min-width: 180px;
    border: 2px solid #eaf0f8;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    // gap: 30px;
    justify-content: space-between;

    &.checked {
      border-color: var(--secondary-color);
    }

    .box_header {
      display: flex;
      justify-content: space-between;
      align-items: start;

      h2 {
        font-size: 24px;
        font-weight: 700;
        max-width: 60%;

        &::after {
          content: "";
          display: block;
          position: relative;
          width: 50%;
          border: 1px solid var(--secondary-color);
          margin-top: 8px;
        }
      }

      input[type="radio"] {
        height: 20px;
        width: 20px;
        appearance: none;
        border-radius: 50%;
        outline: none;
        transition: border-color 0.3s;
        background-color: var(--main-background-color);
        border: 4px solid #eee;

        &:checked {
          background-color: #fff;
          border: 4px solid #36d66b;
        }
      }
    }

    .box_content {
      display: flex;
      justify-content: space-between;
      align-items: end;
      h2 {
        font-size: 16px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #a4a8ae;
        width: 120px;
      }
    }
  }
}
